import React, { useState } from "react";
import { Button, Card, CardBody, Form, Modal } from "reactstrap";
import { useEffect } from "react";
import { isParsable, parseJsonRecursively } from "../../../../helperFunctions";

const PgWebhookModal = ({ isViewsendDataModal, onCloseModal, data }) => {
  const [parsedData1, setParsedData1] = useState(null);
  const [parsedData3, setParsedData3] = useState(null);

  useEffect(() => {
    let parsedRequestBody = null;
    try {
      parsedRequestBody = isParsable(data?.request_body)
        ? parseJsonRecursively(JSON.parse?.(data?.request_body))
        : data?.request_body;
    } catch (error) {
      console.error("Error parsing request_body:", error);
    }
    setParsedData1(parsedRequestBody);

    try {
      const parsedResponseBody = isParsable(data?.pgRes)
        ? parseJsonRecursively(JSON.parse(data?.pgRes))
        : data?.pgRes;
      setParsedData3(parsedResponseBody);
    } catch (error) {
      console.error("Error parsing responseBody:", error);
    }
  }, [data]);

  return (
    <Form>
      <Modal
        isOpen={!!isViewsendDataModal}
        toggle={() => onCloseModal()}
        className="modal-width"
        centered={true}
      >
        <div className="modal-header">
          <h3 className="modal-title mt-0">PG Webhook</h3>
          <i
            onClick={() => {
              onCloseModal();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body w-100 json-style">
          <div className="row">

            <div className="col-lg-12">
              <Card>
                <CardBody>

                  <h5>Response Body</h5>
                  <div className="webevent-sec mb-1 req_text_wrap requestContainer">
                    <pre>{parsedData3 ? JSON.stringify(parsedData3, null, 2) : "No response body"}</pre>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onCloseModal()}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </Form>
  );
};

export default PgWebhookModal;
