import AddMerchant from "../../../components/Custom/Modals/AddMerchantModal/AddMerchant";
import BankConfig from "../../../components/Custom/Modals/BankConfig/BankConfig";
import { Button, Card, CardHeader, Form } from "reactstrap";
import { Table, TableRow } from "../../../components/Table";
import React, { useEffect, useState } from "react";
import {
  GetMerchant,
  GetMerchantList,
  Merchant_Table_Central_Bank_Add,
  merchant_Status_Update,
  setMerchant,
  setMerchantCentralBank,
  setMerchantCentralBankForList,
  setMerchantList,
  useMerchantData,
  useMerchantList,
} from "../../../redux/slices/merchantSlice";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import {
  TRA_FILTER,
  MERCHANT_LOCAL,
  TRA_PAGINATION_OPTIONS,
  MERCHANT_LIMIT,
} from "../../../constant";
import { useDispatch } from "react-redux";
import "./Merchant.scss";
import Avatar from "../../../components/Custom/Elements/Avatar/Avatar";
import {
  getLocalData,
  setLocalData,
  isCentralBankSeen,
  responseToaster,
} from "../../../helperFunctions";
import ToggleSwitch from "../../../components/Custom/Forms/ToggleSwitch/ToggleSwitch";
import AddBankModal from "./AddBankModal";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import { useForm } from "react-hook-form";
import { CiStar } from "react-icons/ci";
import AddCentralBank from "./addCentralBankModal";
import { FaStar } from "react-icons/fa";
import MerchantMinLimit from "./MerchantMinLimit";
import MerchantMaxLimit from "./MerchantMaxLimit";
import { useRole } from "../../../redux/slices/authSlice";

const initialFilter = {
  ...TRA_FILTER,
  limit: MERCHANT_LIMIT,
};

const statusOption = [
  { value: 1, label: "Active", activeClassName: "green" },
  { value: 0, label: "Deactive", activeClassName: "red" },
];
const addMerchantOption = [
  { value: 1, label: "Added", activeClassName: "green" },
  { value: 0, label: "Removed", activeClassName: "red" },
];

const Merchant = () => {
  const data = useMerchantList();
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(initialFilter);
  const [isFilterOpen, setIsFilterOpen] = useState(
    getLocalData(MERCHANT_LOCAL, false)
  );
  const [addMerchantModal, setAddMerchantModal] = useState(false);
  const [bankCofingModal, setbankCofingModal] = useState(false);
  const [isCentralBankLoading, setCentralBankLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isAddBankOpen, setIsAddBankOpen] = useState(false);
  const [isAddCentralBankModal, setIsAddCentralBankModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateDetails, setUpdateDetails] = useState({});

  const onCloseModal = () => {
    setAddMerchantModal(false);
  };

  const onCloseCentralBankModal = (val) => {
    setIsAddCentralBankModal(val);
  };

  const onCloseConfig = () => {
    setbankCofingModal(false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useRole("Merchant");

  const onUpdateStatus = async (payload) => {
    try {
      setStatusLoading(payload?.merchantId);
      await dispatch(merchant_Status_Update(payload)).unwrap();
      const index = data?.data?.findIndex(
        (item) => item?.merchantId === payload?.merchantId
      );
      if (index > -1) {
        const newData = [...data.data];
        newData[index] = { ...newData[index], isActive: payload?.isActive };
        dispatch(setMerchantList({ ...data, data: newData }));
      }
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  };

  const onAddTableCentralBank = async (payload) => {
    try {
      setCentralBankLoading(payload?.merchantId);
      const res = await dispatch(
        Merchant_Table_Central_Bank_Add(payload)
      ).unwrap();
      const index = data?.data?.findIndex(
        (item) => item?.merchantId === payload?.merchantId
      );
      if (index > -1) {
        const newData = [...data.data];
        newData[index] = { ...newData[index], isCentral: payload?.isCentral };
        dispatch(setMerchantCentralBankForList({ ...data, data: newData }));
      }
      responseToaster(res);

      setCentralBankLoading(false);
    } catch (err) {
      setCentralBankLoading(false);
    }
  };

  const columns = [
    {
      title: "",
      name: "",
      Cell: (data) => {
        return (
          <NavLink
            className="d-block h-100"
            to={`/merchant/${data?.merchantId}`}
          >
            <div className="d-flex gap-1 cursor-pointer align-items-center avtarstyle">
              {data?.isCentralBankMerchant == 1 ? (
                <FaStar
                  color="orange"
                  size={20}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!role.includes("add-central-bank")) {
                      return;
                    }
                    onCloseCentralBankModal(data);
                  }}
                />
              ) : (
                <CiStar
                  size={20}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!role.includes("add-central-bank")) {
                      return;
                    }
                    onCloseCentralBankModal(data);
                  }}
                />
              )}
            </div>
          </NavLink>
        );
      },
    },
    {
      title: "MID",
      name: "merchantName",
      sortableKey: "total",
      sortable: true,
      Cell: (data) => {
        return (
          <NavLink
            className="d-block h-100"
            to={`/merchant/${data?.merchantId}`}
          >
            <div className="d-flex gap-1 cursor-pointer align-items-center avtarstyle">
              <Avatar
                name={data?.merchantName}
                sx={{ width: 30, height: 30, fontSize: 14 }}
              />
              <div className="cell-order">
                <div className="order-singal-div">
                  <p>
                    {data?.merchantName} ({data?.merchantId})
                  </p>
                </div>
              </div>
            </div>
          </NavLink>
        );
      },
    },
    {
      title: "ACTIVE",
      name: "active",
      Cell: (data) => {
        return (
          <NavLink
            className="d-block h-100"
            to={`/merchant/${data?.merchantId}`}
          >
            <div>
              <ToggleSwitch
                isLoading={statusLoading === data?.merchantId}
                disabled={
                  data?.isCentralBankMerchant === 1 ||
                  statusLoading ||
                  isCentralBankLoading ||
                  !role.includes("merchant-status-update")
                }
                value={data?.isActive ? 1 : 0}
                options={statusOption}
                onChange={async (value, e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  try {
                    const payload = {
                      isActive: value,
                      merchantId: data?.merchantId,
                    };
                    await onUpdateStatus(payload);
                  } catch (err) {}
                }}
              />
            </div>
          </NavLink>
        );
      },
    },
    {
      title: "STATUS",
      name: "accountSts",
      Cell: (data) => {
        return (
          <>
            <NavLink
              className="d-block h-100"
              to={`/merchant/${data?.merchantId}`}
            >
              <div className="cell-order">
                <div className="order-singal-div">
                  <p>{data?.accountSts}</p>
                </div>
              </div>
            </NavLink>
          </>
        );
      },
    },
    {
      title: "MIN LIMIT",
      Cell: (data) => {
        return (
          <>
            <NavLink
              className="d-block h-100"
              to={`/merchant/${data?.merchantId}`}
            >
              <MerchantMinLimit
                updateDetails={updateDetails}
                modalText="This will update merchant min limit"
                setUpdateDetails={setUpdateDetails}
                onGet={() => GetMerchantData()}
                data={data}
                setIsLoading={setIsLoading}
              />
            </NavLink>
          </>
        );
      },
    },
    {
      title: "MAX LIMIT",
      Cell: (data) => {
        return (
          <>
            <NavLink
              className="d-block h-100"
              to={`/merchant/${data?.merchantId}`}
            >
              <MerchantMaxLimit
                updateDetails={updateDetails}
                modalText="This will update merchant max limit"
                setUpdateDetails={setUpdateDetails}
                onGet={() => GetMerchantData()}
                data={data}
                setIsLoading={setIsLoading}
              />
            </NavLink>
          </>
        );
      },
    },
  ];

  if (isCentralBankSeen()) {
    columns.push({
      title: "CENTRAL BANK",
      name: "actions",
      Cell: (item) => {
        return (
          <>
            <NavLink
              className="d-block h-100"
              to={`/merchant/${item?.merchantId}`}
            >
              <ToggleSwitch
                isLoading={isCentralBankLoading === item?.merchantId}
                value={item?.isCentral ? 1 : 0}
                disabled={
                  item?.isCentralBankMerchant === 1 ||
                  isCentralBankLoading ||
                  statusLoading ||
                  !role.includes("add-central-merchant")
                }
                options={addMerchantOption}
                onChange={async (value, e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  try {
                    const payload = {
                      merchantId: item?.merchantId,
                      isCentral: value,
                    };
                    await onAddTableCentralBank(payload);
                  } catch (err) {}
                }}
              />
            </NavLink>
          </>
        );
      },
    });
  }

  const GetMerchantData = async () => {
    try {
      const {
        isFilter,
        page_no,
        limit,
        filter: dropdown,
        search,
        ...rest
      } = filter;
      setIsLoading(true);
      const payload = isFilter
        ? {
            filter_data: { [dropdown]: search, ...rest },
            page_no,
            limit,
          }
        : {
            page_no,
            limit,
          };
      const res = await dispatch(GetMerchantList(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("merchant-list")) {
      GetMerchantData();
    }
  }, [filter]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const searchDataOptions = [{ value: "merchantId", label: "Merchant Id" }];

  const initialValues = {
    filter: searchDataOptions?.[0]?.value,
    search: "",
  };

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...initialFilter,
      isFilter: true,
    });
  };

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  useEffect(() => {
    setTableData(data?.data?.length ? [...data.data] : []);
  }, [data]);

  const handleClick = (item, event) => {
    if (event.ctrlKey) {
      window.open(`/merchant/${item?.merchantId}`);
    } else {
      if (item?.merchantId) {
        navigate(`/merchant/${item?.merchantId}`);
      }
    }
  };

  const prepareData = (data) => {
    return (data ? [...data] : []).sort((a, b) => {
      if (a.isActive > b.isActive) {
        return -1;
      } else if (a.isActive < b.isActive) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const toggleIsFilterOpen = () => {
    setIsFilterOpen(!isFilterOpen);
    setLocalData(MERCHANT_LOCAL, !isFilterOpen);
  };

  if (!role.includes("merchant-list")) {
    return;
  }

  return (
    <div className="merchant-main-div">
      <Card>
        <CardHeader className="flex-column align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>Merchant</b>
            </h4>
            <div
              className="d-flex align-items-center justify-content-around"
              style={{ gap: "10px" }}
            >
              {role.includes("merchant-add") ? (
                <>
                  <Button
                    title="Add Merchant"
                    color="primary"
                    onClick={() => setAddMerchantModal(true)}
                  >
                    Add Merchant
                  </Button>
                </>
              ) : null}
              {role.includes("merchant-bank-config") ? (
                <>
                  <Button
                    title="Bank Config"
                    color="primary"
                    onClick={() => setbankCofingModal(true)}
                  >
                    Bank Config
                  </Button>
                </>
              ) : null}
              <Button
                title="Apply Filter"
                className="filterbtn"
                onClick={() => {
                  toggleIsFilterOpen();
                }}
              >
                <i className="fa-solid fa-filter"></i> Apply Filter
              </Button>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {isFilterOpen && (
              <div
                className="d-flex my-xl-auto right-content align-items-end button-space"
                style={{ flexWrap: "wrap", gap: "10px" }}
              >
                <ControlledDropDown
                  name="filter"
                  label="Filter"
                  isDefaultOption={false}
                  options={searchDataOptions}
                  control={control}
                />
                <ControlledInput
                  name="search"
                  label="Search"
                  placeholder="Enter Search Value"
                  control={control}
                />

                <Button type="submit" color="primary" className="mt-2">
                  Apply
                </Button>
                <Button
                  type="button"
                  className="mt-2"
                  color="danger"
                  onClick={() => {
                    reset();
                    setFilter({ ...initialFilter });
                  }}
                >
                  Clear
                </Button>
              </div>
            )}
          </Form>
          <AddMerchant
            addMerchantModal={addMerchantModal}
            onCloseModal={onCloseModal}
            GetMerchantData={GetMerchantData}
            data={data}
          />
          <BankConfig
            bankCofingModal={bankCofingModal}
            onCloseConfig={onCloseConfig}
          />
        </CardHeader>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isLoading}
          data={tableData || []}
          setData={setTableData}
          isData={!!data?.data?.length}
          count={data?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
          paginationOptions={TRA_PAGINATION_OPTIONS}
        >
          {prepareData(tableData || {})?.map((val) => {
            return (
              <>
                <TableRow
                  columns={columns}
                  filterColumns={filterColumns}
                  isExpandable={false}
                  item={val}
                  onClick={(item, e) => {
                    e.preventDefault();
                    handleClick(item, e);
                  }}
                  className={`${
                    val?.recent_transactions_count >= 1 ? "active_row" : ""
                  }`}
                />
              </>
            );
          })}
        </Table>
      </Card>
      <AddBankModal
        isOpen={isAddBankOpen}
        onHide={() => setIsAddBankOpen(!isAddBankOpen)}
      />
      <AddCentralBank
        isOpen={isAddCentralBankModal}
        onCloseCentralBankModal={() =>
          setIsAddCentralBankModal(!isAddCentralBankModal)
        }
        GetMerchantData={GetMerchantData}
      />
    </div>
  );
};

export default Merchant;
