import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Modal } from "reactstrap";
import { isParsable, parseJsonRecursively } from "../../../../helperFunctions";

const RequestResponseModal = ({ isViewsendDataModal, onCloseModal, data }) => {
  const [parsedData1, setParsedData1] = useState(null);
  const [parsedData3, setParsedData3] = useState(null);

 
  useEffect(() => {
    let parsedRequestBody = null;
    try {
      parsedRequestBody = isParsable(data?.request_body)
        ? parseJsonRecursively(JSON.parse?.(data?.request_body))
        : data?.request_body;
    } catch (error) {
      console.error("Error parsing request_body:", error);
    }
    setParsedData1(parsedRequestBody);

    try {
      const parsedResponseBody = isParsable(data?.response_body)
        ? parseJsonRecursively(JSON.parse(data?.response_body))
        : data?.response_body;
      setParsedData3(parsedResponseBody);
    } catch (error) {
      console.error("Error parsing response_body:", error);
    }
  }, [data]);

  return (
    <Modal
      isOpen={!!isViewsendDataModal}
      toggle={() => onCloseModal()}
      className="modal-xl"
      centered={true}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">Request Response Data</h3>
        <i
          onClick={() => {
            onCloseModal();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body w-100 json-style">
        <div className="row">
          <div className="col-lg-6">
            <Card>
              <CardBody>
                <h5>URL</h5>
                <p>
                  <span className="badge badge-success">
                    {data?.request_method}
                  </span>
                  : {data?.request_url}
                </p>
                <h5>Request Body</h5>
                <div className="webevent-sec mb-1 req_text_wrap requestContainer">
                  <pre>{parsedData1 ? JSON.stringify(parsedData1, null, 2) : "No request body"}</pre>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-6">
            <Card>
              <CardBody>
                <h5>Status Code </h5>
                <p className="badge badge-success">
                  {data?.response_http_code}
                </p>
                <h5>Response Body</h5>
                <div className="webevent-sec mb-1 req_text_wrap requestContainer">
                  <pre>{parsedData3 ? JSON.stringify(parsedData3, null, 2) : "No response body"}</pre>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={() => onCloseModal()}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RequestResponseModal;
