import { useForm } from "react-hook-form";

import { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../helperFunctions";
import DropDown from "../../../components/Custom/Forms/DropDown/DropDown";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import { nickNameBankAdd } from "../../../redux/slices/smsSlice";

const BankAsignModal = (props) => {
  const { isOpen, data, onClose, unUsedBankData, getUserData } = props;
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();

  const {
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    setValue(
      "bank",
      data?.sms_user_bank_details?.map((item) => item?.id) ?? []
    );
  }, [data]);

  const prepareBankOptions = () => {
    let options =
      data?.sms_user_bank_details?.map((val) => {
        return {
          value: val?.id,
          label: `${val?.bank_name} - ${val?.account_number}`,
        };
      }) || [];
    let options1 =
      unUsedBankData?.map((val) => {
        return {
          value: val?.id,
          label: `${val?.bank_name} - ${val?.account_number}`,
        };
      }) || [];
    options = [...options, ...options1];

    return [...(options?.length ? options : [])];
  };

  const addNickName = async (e) => {
    try {
      e.preventDefault()
      setLoading(true)
      const payload = {
        nickNameId: data?.id,
        userId: data?.user_id,
        bankId: watch("bank"),
      };

      const res = await dispatch(nickNameBankAdd(payload)).unwrap();
      responseToaster(res);
      onClose();
      setLoading(false)
      getUserData();

    } catch (err) {
      setLoading(false)

    }
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      centered={true}
      className={`modal-md`}
    >
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div className="modal-header">
          <h3 className="modal-title mt-0">Bank Assign</h3>
          <i
            onClick={() => {
              reset()
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>

        <div className="modal-body">
          <form onSubmit={addNickName}>
            <div className="flex gap-2 mb-2">
              <DropDown
                name={`banks`}
                className="w-full"
                label="Banks"
                isMulti
                value={watch("bank")}
                isClearable={true}
                isDefaultOption={false}
                options={prepareBankOptions()}
                onChange={(e) => {
                  setValue(
                    "bank",
                    e?.map((item, index) => item?.value)
                  );
                }}
              />
            </div>
            <div className="d-flex justify-content-end">
              <CustomeButton isButtonLoading={loading} type="submit" className="submitbutton" >
                Apply
              </CustomeButton>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default BankAsignModal;
