import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { addBank, addSms, addUpdateNickName, addUser, blockUserDevice, deleteNickName, deleteUser, getMerchantList, getSms, getSmsUsers, getUserDetails, getUserList, nickNameAddBank, resetPassword, sendNotification, updateApk } from "../services/smsServices";

const initialState = {
    data: {},
    smsUsers: {},
    usersList: {},
    merchantList: {},
};

export const smsGet = createAsyncThunk("/smsGet", async (payload) => {
    return await getSms(payload);
});
export const smsAdd = createAsyncThunk("/smsAdd", async (payload) => {
    return await addSms(payload);
});
export const usersGet = createAsyncThunk("/usersGet", async (payload) => {
    return await getSmsUsers(payload);
});
export const usersGetList = createAsyncThunk("/usersGetList", async (payload) => {
    return await getUserList(payload);
});
export const userAdd = createAsyncThunk("/userAdd", async (payload) => {
    return await addUser(payload);
});
export const userDelete = createAsyncThunk("/userDelete", async (payload) => {
    return await deleteUser(payload);
});
export const merchantList = createAsyncThunk("/merchantList", async (payload) => {
    return await getMerchantList(payload);
});
export const bankAdd = createAsyncThunk("/bankAdd", async (payload) => {
    return await addBank(payload);
});
export const notificationSend = createAsyncThunk("/notificationSend", async (payload) => {
    return await sendNotification(payload);
});
export const passwordReset = createAsyncThunk("/passwordReset", async (payload) => {
    return await resetPassword(payload);
});
export const deviceIdReset = createAsyncThunk("/deviceIdReset", async (payload) => {
    return await resetDeviceId(payload);
});
export const nickNameBankAdd = createAsyncThunk("/nickNameBankAdd", async (payload) => {
    return await nickNameAddBank(payload);
});
export const userDetailsGet = createAsyncThunk("/userDetailsGet", async (payload) => {
    return await getUserDetails(payload);
});
export const updateNickNameAdd = createAsyncThunk("/updateNickNameAdd", async (payload) => {
    return await addUpdateNickName(payload);
});
export const nickNameDelete = createAsyncThunk("/nickNameDelete", async (payload) => {
    return await deleteNickName(payload);
});
export const userDeviceBlock = createAsyncThunk("/userDeviceBlock", async (payload) => {
    return await blockUserDevice(payload);
});
export const apkUpdate = createAsyncThunk("/apkUpdate", async (payload) => {
    return await updateApk(payload);
});

const smsSlice = createSlice({
    name: "smsSlice",
    initialState,
    reducers: {
        clearSms: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(smsGet.fulfilled, (state, action) => {
            state.data = action.payload;
        });
        builder.addCase(usersGet.fulfilled, (state, action) => {
            state.smsUsers = action.payload;
        });
        builder.addCase(usersGetList.fulfilled, (state, action) => {
            state.usersList = action.payload;
        });
        builder.addCase(merchantList.fulfilled, (state, action) => {
            state.merchantList = action.payload;
        });
        builder.addCase(userDetailsGet.fulfilled, (state, action) => {
            state.userDetailsData = action.payload;
        });

    },
});
export default smsSlice.reducer;
export const { clearSms } = smsSlice.actions;

export const selectSms = (state) => {
    return state.sms.data;
};
export const useSms = () => {
    const data = useSelector(selectSms);
    return useMemo(() => data, [data]);
};

export const selectUserList = (state) => state.sms.smsUsers;
export const useUserList = () => {
    const userList = useSelector(selectUserList);
    return useMemo(() => userList, [userList]);
};

export const selectUsersList = (state) => state.sms.usersList;
export const useUsersList = () => {
    const userList = useSelector(selectUsersList);
    return useMemo(() => userList, [userList]);
};

export const selectMerchantList = (state) => state.sms.usersList;
export const useMerchantsData = () => {
    const userList = useSelector(selectMerchantList);
    return useMemo(() => userList, [userList]);
};

export const selectUserDetail = (state) => state.sms.userDetailsData;
export const useUserDetailsData = () => {
    const userList = useSelector(selectUserDetail);
    return useMemo(() => userList, [userList]);
};
