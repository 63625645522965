import Box from "@mui/material/Box";
import HorizontalNavItems from "./HorizontalNavItems";
import LogoutConfirmModal from "../../../Custom/Modals/LogoutConfirmModal/LogoutConfirmModal";
import { useState } from "react";
import LogoutIcon from "../../../../assets/images/pages/logout-icon.svg";
import DropDown from "../../../Custom/Forms/DropDown/DropDown";
import { useDispatch } from "react-redux";
import {
  setAutoRefreshTime,
  useAutoRefreshTime,
} from "../../../../redux/slices/authSlice";
import { autoRefereshOptions } from "../../../../constant";

const Navigation = (props) => {
  const [isOpenLogoutModal, setIsOpenLogoutModal] = useState(false);
  const dispatch = useDispatch();
  const autoRefreshTime = useAutoRefreshTime();
  const onToggleModal = () => {
    setIsOpenLogoutModal(!isOpenLogoutModal);
  };

  return (
    <>
      <Box
        className="menu-content"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          fontSize: 14,
          "& > *": {
            "&:not(:last-child)": { mr: 1 },
          },
        }}
      >
        <HorizontalNavItems {...props} />
      </Box>
      <div className="d-flex align-items-center">
        <div style={{width:"96px"}}>
          <DropDown
            placeholder="Select Time"
            isDefaultOption={false}
            options={autoRefereshOptions}
            value={autoRefreshTime}
            onChange={(e) => {
              dispatch(setAutoRefreshTime(e.value));
            }}
          />
        </div>
        <div className="logoutsec">
          <div
            onClick={onToggleModal}
            className="cursor-pointer text-nowrap d-flex align-items-center gap"
          >
            <a>LOGOUT</a>
            <img src={LogoutIcon} alt="" className="img-fluid" />
            {/* <i class="fa-solid fa-angle-right"></i> */}
          </div>
        </div>
      </div>

      <LogoutConfirmModal
        isOpenLogoutModal={isOpenLogoutModal}
        onCloseModal={onToggleModal}
      />
    </>
  );
};

export default Navigation;
