import { baseUrl } from "./baseUrl";

export const LOGIN = `${baseUrl}api/login`;
export const VERIFYOTP = `/api/2fa-verify`;
export const FACTORENABLE = `/api/2fa-enable`;
export const ISAUTH = `/api/get-2fa-status`;
export const LOGIN_AGAIN = '/api/user-login'
export const PAYOUT = "/api/payout";
export const LOGOUT = "/api/logout";
export const LOGOUTUSER = "/api/token-expire-logout";

// TRANSACTION SLICE
export const TRANSACTION = "/api/transaction";
export const TRANSACTION_SUMMARY = "/api/transaction/summary";
export const TRANSACTION_RESEND_WEBHOOK = "/api/transaction-resend-webhook";
export const BLOCK_CUSTOMER = "/api/block-customer";
export const TRANSACTION_REFUND = "/api/transaction-refund";
export const TRANSACTION_REMOVE_FEES = "/api/transaction-removefees";
export const TRANSACTION_DELETE = "/api/transaction-delete-manual";
export const TRANSACTION_UPDATE_TEMPUTR = "/api/transaction-update-tempUtr";
export const TRANSACTION_MODEL = "/api/transactionbyUtr";
export const GET_PAYMENT_META_LABEL_LIST =
  "/api/payment-gateway/GetPaymentMetaLabelList";

export const MARK_AS_FAILED_DEPOSITE = "/api/transaction/failed";

// SSTRANSACTION SLICE
export const SSTRANSACTION = "/api/ss-transaction";
export const CURRENT_SSTRANSACTION = "/api/ss-transaction/view";
export const UPDATE_SSTRANSACTION = "/api/ss-transaction/action";

//STATE SUMMARY
export const STATESUMMARY = "/api/stateSummary";

// DASHBOARD SLICE
export const AVAILABLE_BALANCE = "/api/availableBalance";
export const DASHBOARD_DEPOSIT_CHART = "/api/chart/Deposit";
export const DASHBOARD_DEPOSIT_CHART_LAST4 = "/api/chart/DepositByFourHour";
export const DASHBOARD_WITHDRAWAL_CHART = "/api/chart/Withdrawal";
export const DASHBOARD_WITHDRAWAL_CHART_LAST4 =
  "/api/chart/WithdrawalByFourHour";
export const GET_TRANSACTION_SUMMARY = "/api/GetMMTransactionSummary";
export const GET_PAYOUT_SUMMARY = "/api/GetMMPayoutSummary";
export const GET_PG_SUMMARY = "/api/dashboard/GetPgSummary";
export const DASHBOARD_DEPOSIT_CHART_BANK = "/api/get/chart/Deposit/Bank";
export const DASHBOARD_WITHDRAWAL_CHART_BANK = "/api/get/chart/Withdrawal/Bank";
// export const GET_PG_SUMMARY= "/api/pg-management-dashboard/GetSummary";
export const PAYOUT_CR_DATA = "/api/dashboard/payoutCRData";
export const GET_MM_DASHBOARD_SUMMARY = "/api/GetMMDashboardSummary";
export const GET_MM_DASHBOARD_DATA = "/api/GetMMDashboardData";
export const PAYOUT_SUMMARY = "api/payout/summery";
export const GET_BANK_SYNC = "/api/support/GetBankSync";
export const GET_MOBILE_SYNC = "/api/support/GetMobileSync";
export const RESEND_WEBHOOK = "/api/ResendWebhook";
export const CANCELLED_INITIALIZED = "/api/CancelledInitializedPayout";
export const RESET_INITIALIZED_PAYOUT = "/api/payout/ResetInitializedPayout";
export const PAYMENT_DATA = "/api/paymentData";

//SMS
export const GET_SMS = "/api/getSms";
export const ADD_SMS = "/api/addSms";
export const GET_SMS_USERS = "/api/getUserList";
export const GET_USERLIST = "/api/getUser";
export const ADD_USER = "/api/addUser";
export const DELETE_USER = "/api/deleteUser";
export const GET_MERCHANT_LIST = "/api/getMerchantList";
export const ADD_BANK = "/api/addBank";
export const SEND_NOTIFICATION = "/api/sendNotification";
export const RESET_PWD = "/api/resetPassword";
export const RESET_DEVICE = "/api/resetDeviceId";
export const ADD_NICK_NAME = "/api/nickNameAddBank";
export const GET_USER_DETAIL = "/api/getUserDetails";
export const UPDATE_NICK_NAME = "/api/addUpdateNickName";
export const DELETE_NICK_NAME = "/api/deleteNickName";
export const BLOCK_USER_DEVICE = "/api/blockUserDevice";
export const UPDATE_APK = "/api/updateApk";


// PAYIN_PAYOUT
export const GETCUSTOMERLEVELDATA = "/api/Payout/GetCustomerLevelData";
export const REFUND = "/api/refund";
export const RECONCILIATIONPAYMENT = "/api/ReconciliationPayment";
export const RECONCILIATIONPAYMENTACTION =
  "/api/payment/ReconciliationPaymentAction";
export const TRANSACTION_FILTER_DATA = "/api/transaction-filter-data";
export const GETGENERATEDREPORT = "/api/GeneratePayoutReport";
export const MANUAL_PAYOUT = "/api/get/manual-payout/list";
export const MANUAL_PAYOUT_BANKLIST = "/api/manual-payout-bank-list";
export const PAYOUT_STATUS_UPDATE = "/api/payoutStatusUpdate";
export const DOWNLOAD_BATCH_FILE = "/api/download/batch/file";
export const MARK_AS_USED_MANUAL = "/api/mark-as-used/manual-payout";
export const ACCOUNT_BANK_LOAD = "/api/get/payout/account/load";
export const GENERATE_FILE = "/api/add/manual-payout";
export const PAYOUT_AMOUNT_TOTAL = "/api/payout/inti/total";
export const PAYOUT_AMOUNT_TOTAL_MERCHANT = "/api/payout/init/total/merchant";
export const UPLOAD_STATUS_FILE = "/api/upload/status/file";
export const GET_PAYOUT_CONFIGURATION = "/api/payout/GetPayoutConfiguration";
export const UPDATE_PAYOUT_CONFIGURATION = "/api/UpdatePayoutConfiguration";
export const RESET_LOWBAL_PAYOUT_TO_INITIALTIZE =
  "/api/ResetLowBalPayoutToInitialize";
export const DEPOSITE_STATUS_UPDATE = "/api/depositStatusUpdate";
export const GET_CUSTOMERS_DETAILS = "/api/transactionbyBrowserId";
export const BLOCK_CUSTOMER_BYHID = "/api/block-customer-byHId";
export const UPDATE_CHARGE_BACK = "/api/update-isChargeBack";
export const BANK_TICKET_SUMMMARY = "/api/get/bank/Ticket/summery";
export const TICKET_SUMMMARY = "/api/get/Ticket/summery";
export const PAYOUT_TICKET_SUMMMARY = "/api/get/payout/Ticket/summary";
export const MERCHANT_LIST = "/api/getMerchantList";
export const FED_TRANSCATION = "/api/fedTransaction";
export const UPDATE_FED_TRANSCATION = "/api/updateFedTransactionStatus";
export const UPI_BANK_SUCCESS = "/api/get-transaction-data";
export const UPDATE_IS_MANUAL_LEVEL = "/api/manual-payout/UpdateManualLevel";
export const RESET_RECHECK = "/api/payout/ResetRecheckPayoutToInitialize";
export const GET_PAYOUT_BANKLIMIT = "/api/getPayoutBankLimit";
export const GET_PAYMENT_BANKLIMIT = "/api/getPaymentBankLimit";
export const GET_PAYMENT_BANKLIMIT_DATA = "/api/getPaymentBankFilterData";
export const UPDATE_PAYMENT_BANKLIMIT = "/api/updatePaymentBankLimit";
export const UPDATE_PAYOUT_BANKLIMIT = "/api/updatePayoutBankLimit";
export const UPLOAD_EXCEL = "/api/upload-excel";
export const ACTIVE_MERCHNAT_PG = "/api/activeMerchantPg";
export const GET_RISK_MANAGEMENT = "/api/risk-management";
export const ACTIVE_UPI_IDS = "/api/active-upi-ids";
export const LAST_UPI_RATE = "/api/last-upi-rate";
export const GET_BANK_ANALYTICS = "/api/bank-analytics";
export const GET_BANK_ANALYTICS_DETAIL = "/api/bank-analytics/details";

// MERCHANT
export const MERCHANT = "/api/merchant";
export const MERCHANTT_LIST = "/api/merchant-list";
export const MERCHANT_CHECKOUT = "/api/getMerchantTicket";
export const MERCHANT_CHECKOUT_UPDATE = "/api/addMerchantTicket";
export const FETCH_BANK_STATUS = "/api/fetchBankStatus";
export const UPDATE_BANK_STATUS = "/api/updateBankStatus";
export const UPDATE_OLD_USERS_DAYS = "/api/merchant/action/UpdateOldUsersDays";
export const UPDATE_CHECKOUT_COLOR = "/api/merchant/action/UpdateCheckoutColor";
export const ADDMANUAL_PAYOUT = "/api/merchant/AddManualPayout";
export const ADDMANUAL_PAYIN = "/api/merchant/AddManualPayIn";
export const VIEW_STATEMENT = "/api/merchant/ViewStatement";
export const VIEW_DASHBOARD_LOGS = "/api/merchant/ViewDashboardLogs";
export const VIEW_MERCHANT_WHITE_LIST_IPS =
  "/api/merchant/ViewMerchantWhitelistIps";
export const UPDATE_MERCHANT_WHITE_LISTIPS_STATUS =
  "api/merchant/updateMerchantWhitelistIpsStatus";
export const RESET_MERCHANT_ACCOUNT_PASSWORD =
  "/api/merchant/action/ResetMerchantAccountPassword";
export const UPDATE_MERCHANT_DETAILS = "/api/merchant/action/Update";
export const UPDATE_MERCHANT_ISFLOW = "/api/merchant/action/Update/FlowV2";
export const UPDATE_LIVE_SETTLEMENT = "/api/merchant/action/Update/T1Settlement";
export const UPDATE_MERCHANT_ISDESKTOPENABLE = "/api/merchant/action/Update/DesktopEnable";
export const MERCHANT_ADD = "/api/merchant/add";
export const MERCHANT_UPDATEPERMISSION =
  "/api/merchant/action/updatePermission";
export const GET_MERCHANT_PERMISSION = "/api/GetMerchantPermission";
export const GET_PENDING_SETTLEMENT = "/api/merchant/GetPendingSettlement";
export const ADD_MERCHANT_SETTLEMENT = "/api/merchant/AddMerchantSettlement";
export const UPDATE_MERCHANT_STATUS = "/api/merchant/action/Update/status";
export const GET_MERCHANT_MODULE = "/api/get/module";
export const Add_MERCHNAT_WHITELISTIP = "/api/merchant/AddMerchantWhiteListIp";
export const ADD_MERCHANT_CENTRAL_BANK = "/api/meta/addCentralBank";
export const GET_MERCHANT_CENTRAL_BANK = "/api/meta/getCentralPayinBank";
export const ADD_MERCHANT_PAY_IN_PAY_OUT_CENTRAL_BANK =
  "/api/meta/addCentralBankToMerchant";
export const ADD_MERCHANT_TABLE_CENTRAL_BANK =
  "/api/merchant/addCentralMerchant";
export const UPDATE_PAYOUT_DAILY_LIMIT = "/api/merchant/UpdatePayoutDailyLimit";
export const ADD_CENTRAL_BANK_MERCHANT = "/api/merchant/addCentralBankMerchant";
export const UPDATE_MERCHANT_MIN_MAX_LIMIT =
  "/api/merchant/action/UpdateMinMax";
export const UPDATE_MERCHANT_MIN_AMOUNT = "/api/merchant/UpdatePayoutMinLimit";
export const UPDATE_MERCHANT_MAX_AMOUNT = "/api/merchant/UpdatePayoutMaxLimit";

// MERCHANT - PAY IN
export const ADD_MERCHANT_PAY_IN_META =
  "/api/meta/AddPayInMetaToMerchantCollection";
export const UPDATE_MERCHANT_PAY_IN_LEVEL =
  "/api/merchant/UpdatePayInMetaLevel";
export const UPDATE_MERCHANT_PAY_IN_BANK = "/api/meta/update/merchant/all";
export const UPDATE_MERCHANT_PAY_IN_STATUS =
  "/api/merchant/UpdatePayInMetaStatus";
export const DELETE_MERCHANT_PAY_IN = "/api/merchant/DeletePayInMeta";
export const GET_MERCHANT_PAY_IN = "/api/merchant/GetPayInMeta";
export const GET_MERCHANT_PAY_IN_AVAILABLE_PAYMENT =
  "/api/meta/GetAvailablePaymentMeta";
export const UPDATE_MERCHANT_PAY_IN_MIN_LIMIT =
  "/api/merchant/UpdatePayInMetaMinLimit";
export const UPDATE_MERCHANT_PAY_IN_MAX_LIMIT =
  "/api/merchant/UpdatePayInMetaMaxLimit";
export const UPDATE_MERCHANT_PAY_IN_DAILY_LIMIT =
  "/api/merchant/UpdatePayInMetaDailyLimit";
export const UPDATE_MERCHANT_PAY_IN_PER_LIMIT =
  "/api/merchant/UpdatePayInMetaPerLimit";
export const ADD_MERCHANT_PAY_IN_CENTRAL_BANK =
  "/api/meta/AddCentralBankPayInMetaToMerchant";
export const UPDATE_MERCHANT_PAY_IN_PAYMENT_METHODS =
  "/api/merchant/UpdatePayInMetaMethod";
export const UPDATE_MERCHANT_PAY_IN_PAYMENT_LEVEL =
  "/api/merchant/UpdatePayInMetaLevel";

// MERCHANT - PAY OUT
export const GET_MERCHANT_PAY_OUT = "/api/merchant/GetPayoutMeta";
export const GET_MERCHANT_PAY_OUT_AVAILABLE_PAYMENT =
  "/api/meta/GetAvailablePayoutMeta";
export const UPDATE_MERCHANT_PAY_OUT_STATUS =
  "/api/merchant/UpdatePayoutMetaStatus";
export const UPDATE_MERCHANT_PAY_OUT_INUSE_STATUS =
  "/api/merchant/UpdatePayoutMetaUseStatus";
export const DELETE_MERCHANT_PAY_OUT = "/api/merchant/DeletePayOutMeta";
export const ADD_MERCHANT_PAY_OUT_META =
  "/api/meta/AddPayoutMetaToMerchantWithdrawal";
export const ADD_MERCHANT_PAY_OUT_CENTRAL_BANK =
  "/api/meta/addCentralBankMetaToMerchant";

// BANK_TRANSACTION
export const BANK_TRANSACTION = "/api/bank-transaction";
export const AVAILABLE_BANK = "/api/get/available-bank";
export const MERGEUTR = "/api/bank-transaction/mergeUtr";
export const MARK_AS_USED = "/api/bank/MarkAsUsed";
export const UPDATE_PAYMENT = "/api/transaction-update-setUtrPaymentRef";
export const UPDATE_TRANSACTION =
  "/api/transaction-update-setUtrTransaction_id";
export const UPDATE_MOBILE_NUMBER = "/api/bank-transaction/UpdateMobileNumber";
export const BANK_TRANSACTION_DETAILS = "/api/transactionbyUtr";
export const ADD_BANK_TRANSACTION = "/api/add/bank-transaction";
export const REPORT = "/api/support/GetGeneratedReport";
export const REPORT_MERCHANT_LIST =
  "/api/merchant/GetMerchantDepositWithdarawalData";
export const BANK_UPLOAD = "/api/upload-statement";
export const BANK_BALSHEET = "/api/bank-transaction-bal-sheet";
export const UPDATE_BANK_TRANSACTION = "/api/update/bankTransaction";
export const UPDATE_BANK_TRANSACTION_MODE = "/api/update/bankTransaction/mode";
// PG
// export const GETFINCAREMETA = "/api/support/GetGeneratedReport";

// SUPPORT
export const SUPPORT = "/api/support/GetSupportLogs";
export const TRACKING = "/api/support/Tracking";
export const GETOPTION_TRACKING = "/api/Tracking";
export const GET_PENDINGPAYMENT_DATA = "/api/getPendingpaymentData";
export const MATCH_RESELLER = "/api/matchResellerPwd";
export const PAYOUT_REQ_RES_LOGS = "/api/payoutRequestResponseLogs";
export const PAYIN_REQ_RES_LOGS = "/api/payinRequestResponseLogs";
export const PG_WEB_HOOK = "/api/support/GetPgWebhooks";

//REQUEST RESPONSE LOGS
export const REQUEST_RESPONSE_LOGS = "/api/RequestResponseLogs";

// CUTOMER MANAGEMENT
export const CUSTOMER_MANAGEMENT = "/api/support/getCustomerList";

// WEBHOOK
export const WEBHOOK = "/api/support/GetWebhookEvents";

// PAYMENT METHOD
export const PAYMENTMETHOD = "/api/support/GetPaymentMethods";

// CFOSTOMER
export const CUSTOMER = "/api/support/GetCustomers";

// BANKSATEMENT
export const BANKSATEMENT = "/api/get-statement";
export const SHOW_ADD_UTR = "/api/show-addedUtr";
export const PAYOUT_ORDER_ID = "/api/GetPayoutById";

// USERS
export const GET_USERS = "/api/getSuperadminUser";
export const ADD_USERS = "/api/addSuperadminUser";
export const UPDATE_USERS = "/api/updateSuperadminUser";
export const DELETE_USERS = "/api/deleteSuperadminUser";
export const RESET_USER_PASSWORD = "/api/resetUserPassword";
export const UPDATE_USER_CRED = "/api/updateSuperadminUser/2FA";
export const UPDATE_SUB_USER_MULTI_LOGIN = "/api/updateSubUserMultiLogin";
export const LOG_OUT_SUB_USER = "/api/logout-superadmin-user";

// ROLE
export const GET_ROLE = "/api/get-roles";
export const ADD_ROLE = "/api/add-roles";
export const UPDATE_ROLE = "/api/update-roles";
export const ROLE_DETAIL = "/api/get-role-details";
export const ROLE_DELETE = "/api/delete-role";

// MERCHANT_GOOGLE_ANALYTIC
export const GET_MERCHANT_GOOGLE_ANALYTIC = "/api/dashboard/GetGoogleCodeList";
export const ADD_MERCHANT_GOOGLE_ANALYTIC = "/api/dashboard/AddGoogleCode";
export const UPDATE_MERCHANT_GOOGLE_ANALYTIC = "/api/dashboard/UpdateGoogleCode";
export const DELETE_MERCHANT_GOOGLE_ANALYTIC = "/api/dashboard/EditGoogleCodeStatus";


// UTR RECONCILIATION
export const UTR_RECONCILIATION = "/api/utrreconciliation";

// PG
export const PG = "/api/payment-gateway/GetPaymentMeta";
export const PG_UPDATE_STATUS = "/api/payment-gateway/UpdatePaymentMetaStatus";
export const PG_UPDATE_MIN_LIMIT =
  "/api/payment-gateway/UpdatePaymentMetaMinLimit";
export const PG_UPDATE_MAX_LIMIT =
  "/api/payment-gateway/UpdatePaymentMetaMaxLimit";
export const PG_UPDATE_PRODUCT_INFO =
  "/api/payment-gateway/UpdateMetaProductInfo";
export const PG_UPDATE_BALANCE =
  "/api/payment-gateway/UpdatePaymentMetaBalance";
export const PG_UPDATE_PAYMENT_METHOD =
  "/api/payment-gateway/UpdatePaymentMetaMethod";
export const PG_UPDATE_PROXY_INFO =
  "/api/payment-gateway/updateProxyInfo";
export const PG_UPDATE_SEAM_LESS =
  "/api/payment-gateway/updateSeamless";
export const PG_UPDATE_PAYMENT_TURNOVER =
  "/api/payment-gateway/UpdatePaymentMetaTurnOver";
export const PG_UPDATE_REMAIN_LIMIT =
  "/api/payment-gateway/updateLimit";
export const PG_UPDATE_PAYMENT_MAX_COUNT_LIMIT =
  "/api/payment-gateway/UpdatePaymentMetaMaxCountLimit";
export const PG_ADD = "/api/payment-gateway/AddPaymentMeta";
export const PG_TEST_PAYMENT = "/api/payment-gateway/TestPaymentAccount";
export const PG_UPDATE_UPI = "/api/payment-gateway/UpdatePaymentMetaUPIID";
export const PG_UPDATE_BANK = "/api/payment-gateway/updateBank";
export const PG_UPDATE_USER = "/api/updateBankCredential";
export const PG_UPDATE_AUTO_LOGIN =
  "/api/payment-gateway/UpdateMetaAutoLoginStatus";
export const PG_EMPTY_BANK = "/api/empty/bank";

// FIncare
export const FINCARE_WITHDRAWAL = "/api/GetPaymentMeta/FINCARE/payout";
export const IDFC_WITHDRAWAL = "/api/GetPaymentMeta/IDFC/payout";
export const INJECTFLOW_WITHDRAWAL = "/api/GetPaymentMeta/INJECTFLOW/payin";
export const INNOPAY_WITHDRAWAL = "/api/GetPaymentMeta/INNOPAY/payin";
// export const INTENTFLOW_WITHDRAWAL = "/api/GetPaymentMeta/INTENTFLOW/payin"
export const KOTAK_WITHDRAWAL = "/api/GetPaymentMeta/KOTAK/payout";
export const NuPay_WITHDRAWAL = "/api/GetPaymentMeta/NuPay/payout";
export const ONEPAY_WITHDRAWAL = "/api/GetPaymentMeta/ONEPAY/payin";
export const PINWALLET_PAYIN_WITHDRAWAL = "/api/GetPaymentMeta/PINWALLET/payin";
export const PINWALLET_PAYOUT_WITHDRAWAL =
  "/api/GetPaymentMeta/PINWALLET/payout";
export const UPIPAY_WITHDRAWAL = "/api/GetPaymentMeta/UPIPAY/payin";
export const YES_WITHDRAWAL = "/api/GetPaymentMeta/YES/payout";

// WHITELIST CLIENT
export const GET_PAYOUT_WHITELIST_CLIENT =
  "/api/dashboard/GetPayoutWhiteListClient";
export const UPDATE_PAYOUT_WHITELISTCLIENT_STATUS =
  "/api/dashboard/UpdatePayoutWhiteListClientStatus";
export const UPDATE_PAYOUT_WHITELISTCLIENT_STATUS_MANUAL =
  "/api/dashboard/UpdatePayoutWhiteListClientStatus/manual";
export const EDIT_PAYOUT_WHITELISTCLIENT_LIMIT =
  "/api/dashboard/editPayoutWhiteListClientLimit";
export const ADD_PAYOUT_WHITELIST_CLIENT =
  "/api/dashboard/AddPayoutWhiteListClient";

// DEVELOPER

// PG ROUTER
export const GET_PGROUTER = "/api/dashboard/GetPgRouters";
export const UPDATE_ISPAYIN_DOWN = "/api/dashboard/updateIsPayinDown";
// PROXY
export const GET_PROXY = "/api/dashboard/GetProxyList";
export const ADD_PROXY = "/api/dashboard/AddProxy";
export const UPDATE_PROXY = "/api/dashboard/updateProxy";
export const DELETE_PROXY = "/api/dashboard/deleteProxy";

// BANK PRPXY
export const GET_BANK_PROXY = "/api/dashboard/GetBankProxyList";
export const ADD_BANK_PROXY = "/api/dashboard/AddBankProxy";
export const UPDATE_BANK_PROXY_STATUS = "/api/dashboard/EditBankProxyStatus";
export const DELETE_BANK_PROXY = "/api/dashboard/DeleteByIdBankProxy";

//ADD PG
export const ADD_PG = "/api/payment-gateway/AddPaymentMeta";

// SMS LOGS
export const GET_SMS_LOGS = "/api/dashboard/getSMSLogs";


// PG CONFIG
export const GET_PG_CONFIG = "/api/dashboard/getPGConfig";
export const UPDATE_PG_CONFIG = "/api/dashboard/editPGConfigStatus";

// MAIL READER
export const GET_MAIL_READER = "/api/dashboard/GetMailReader";
export const UPDATE_MAILREADER_STATUS = "/api/dashboard/UpdateMailReaderStatus";

// IDFC MAIL WEBHOOK
export const GET_IDFC_MAIL_WEBHOOK = "/api/dashboard/getidfcwebhook";

// PAYOUT DOWN BANKS
export const GET_PAYOUT_DOWNBANKS = "/api/dashboard/GetPayoutDownBanks";
export const DELETE_PAYOUT_DOWNBANK = "/api/dashboard/DeletePayoutDownBank";

// BOUNCER
export const GET_BOUNCER_DATA = "/api/dashboard/GetBouncerData";

// GET PG PAYIN SUMMARY
export const GET_PGPAYIN_DEPOSIT_SUMMARY = "/api/dashboard/GetPgPayInSummary";
export const GET_PGPAYIN_WITHDRAWAL_SUMMARY =
  "/api/dashboard/GetPgPayOutSummary";

// BANK MONITORING
export const BANK_MONITORING = "/api/get-bank-monitoring";
export const BANK_MONITORING_PG = "/api/get-bank-pg-data";
export const BANK_MONITORING_BANKS = "/api/bank-monitoring";
export const BANK_MONITORING_DEPOSIT_CHART = "/api/chart/bankDeposit";
export const BANK_MONITORING_CHART = "/api/bank-monitoring-chart";

//REPORTANDCHART 
export const REPORT_CHART_DEPOSIT_CHART = "/api/reportAndChart/deposit";
export const REPORT_CHART_WITHDRAWAL_CHART = "/api/reportAndChart/withdrawal";

// RESELLER
export const GET_RESELLER = "/api/resellerList";
export const GET_RESELLER_DETAILS = "/api/resellerData";
export const GET_RESELLER_COMISSION = "/api/getResellerMerchantList";
export const GET_RESELLER_MERCHANT_LIST = "/api/mercahntListforreseller";
export const GET_RESELLER_SETTLEMENT = "/api/getResellerSettlement";
export const GET_RESELLER_SETTLEMENT_BALANCE = "/api/getSettlementList";
export const ADD_RESELLER = "/api/addReseller";
export const ADD_RESELLER_TO_MERCHANT = "/api/addResellerToMerchant";
export const UPDATE_RESELLER_COMMISSION = "/api/updateResellerCommission";
export const DELETE_RESELLER_COMMISSION = "/api/DeleteResellerMerchant";
export const ADD_RESELLER_SETTLEMENT = "/api/addResellerSettlement";
export const RESET_RESELLER_PASSWORD = "/api/ressetPassword";
export const DELET_RESELLER = "/api/DeleteReseller";

// BANK META
export const BANK_META = "/api/getBankMeta";

// Reconciliation
export const GET_RECONCILIATION = "/api/ReconciliationPayment";

// SCREESHOT SUPPORT
export const READ_SCREENSHOT = "/api/read-utr";
export const UPDATE_SCREENSHOT_STATUS = "/api/update-screenshot-status";

// SCREESHOT SUPPORT
export const READ_METRIWS = "/api/parsepg";
export const GET_GATEWAY_STATMENT = "/api/getGatewayFile";
export const GET_GATEWAY_PAYMENT_STATMENT = "/api/parsepg-available-bank";
export const GET_GATEWAY_PAYOUT_STATMENT = "/api/meta/GetAvailablePayoutMeta";

//WITHDRAWAL CREDIT
export const GET_WITHDRAWAL_CREDIT = "/api/get/PayoutCreditList";
export const EDIT_WITHDRAWAL_CREDIT = "/api/update/PayoutCreditList";
export const ADD_WITHDRAWAL_CREDIT = "/api/add/PayoutCreditList";
export const DELETE_WITHDRAWAL_CREDIT = "/api/delete/PayoutCreditList";

// FED CONFIG BANK

export const GET_FED_CONFIG_BANK = "/api/fedConfigBank";
export const UPDATE_FED_CONFIG_BANK_STATUS = "/api/updatefedConfigBankStatus";
export const UPDATE_FED_CONFIG_BANK_CLAIM_CHECK =
  "/api/updateFedConfigIsClaimCheck";


//SUPPORT USER MERCHANT
export const GET_MERCHANT_USER = "/api/get-merchant-user";
export const ADD_MERCHANT_USER = "/api/add-merchant-user";
export const UPDATE_MERCHANT_USER = "/api/update-merchant-user";
export const DELETE_MERCHANT_USER = "/api/delete-merchant-user";
export const RESET_PASSWORD_MERCHANT_USER = "/api/reset-merchant-user-password";

//SUPPORT USER ROLE
export const GET_MERCHANT_ROLE = "/api/get-merchant-roles";
export const ADD_MERCHANT_ROLE = "/api/add-merchant-roles";
export const UPDATE_MERCHANT_ROLE = "/api/update-merchant-roles";
export const DELETE_MERCHANT_ROLE = "/api/delete-merchant-roles";
export const GET_MERCHANT_PER = "/api/GetMerchantPermission";