import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { Button } from "reactstrap";
import { responseToaster } from "../../helperFunctions";
import { useLocation } from "react-router-dom";
import { pgUpdatePaymentMethod } from "../../redux/slices/pgSlice";
import DropDown from "../../components/Custom/Forms/DropDown/DropDown";
import { getPgIdFromPgName } from "./Pg";
import { useUser } from "../../redux/slices/authSlice";

const options = [
  { value: "IMPS", label: "IMPS" },
  { value: "RTGS", label: "RTGS" },
  { value: "UPI", label: "UPI" },
  { value: "QR", label: "QR" },
  { value: "UTR", label: "UTR" },
  { value: "INTENT", label: "INTENT" },
  { value: "UII", label: "UII" },
  { value: "UPIACCOUNT", label: "UPIACCOUNT" },
  { value: "UPIUTR", label: "UPIUTR" },
  { value: "INTENTUTR", label: "INTENTUTR" },
];

const PgAvailableMethod = (props) => {
  const { item, onGet, columns, setIsLoading } = props;

  const dispatch = useDispatch();
  const locations = useLocation()?.pathname?.split("/");

  const params = { pgName: locations?.at(-1), pgType: locations?.at(-2) };
  const isPayin = params?.pgType === "payin";
  const isUpiPay = (params?.pgName === "UPIPAY" && params?.pgType === "payin") || (params?.pgName === "BANKPAY" && params?.pgType === "payin");
  const { user } = useUser();

  const [isInput, setIsInput] = useState(false);
  const [value, setValue] = useState("");

  const onUpdate = async () => {
    try {
      setIsLoading(true);
      const payload = {
        payload: { pk: item?.account_id, value: value?.join(","), pg_id: getPgIdFromPgName(user?.SiderBar?.PG, params?.pgName) },
        params,
      };
      const resMin = await dispatch(pgUpdatePaymentMethod(payload)).unwrap();
      responseToaster(resMin);
      onGet();
    } catch (err) {
      setIsLoading(false);
    }
  };

  const words = item?.available_method?.split(",");
  return (
    <>
      <div
        className={`${item?.isOpen ? "cell-order-white" : "cell-order"}`}
        onClick={(e) => e.stopPropagation()}
      >
        {isUpiPay || isPayin ? (
          <div className="order-singal-div">
            <div>
              {isInput ? (
                <div className="gap-1">
                  <DropDown
                    options={options}
                    value={value}
                    isDefaultOption={false}
                    isMulti
                    className="fixed-dropdown"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      const items = e?.map((item) => item?.value);
                      setValue(items);
                    }}
                  />
                  <div className="d-flex gap-1 justify-content-end" style={{ marginTop: "10px" }}>

                    <Button color="primary" onClick={onUpdate}>
                      <i className="fa-solid fa-check"></i>
                    </Button>
                    <Button
                      color="danger"

                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsInput(false);
                      }}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </Button>
                  </div>
                </div>
              ) : (
                <span
                  className="cursor-pointer"
                  style={{
                    color: columns?.includes("available_method") ? "var(--chatlook-darkblue--)" : "",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (columns?.includes("available_method")) {
                      setIsInput(true);
                      setValue(item?.available_method?.split(","));
                    }
                  }}
                >
                  {words?.map((val, index) => {
                    return (
                      <>
                        <div key={index} style={{ color: "var(--chatlook-darkblue--)" }}>{val}</div>
                      </>
                    );
                  }) || "Not Set"}
                </span>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default PgAvailableMethod;
