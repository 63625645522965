import LogoutConfirmModal from "../../../Custom/Modals/LogoutConfirmModal/LogoutConfirmModal";
import {
  setAutoRefreshTime,
  setSidebar,
  useAutoRefreshTime,
  useUser,
} from "../../../../redux/slices/authSlice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./Header.scss";
import DropDown from "../../../Custom/Forms/DropDown/DropDown";
import { autoRefereshOptions } from "../../../../constant";

const Header = (props) => {
  const { isHorizontal, isSidebarOpen, setIsSidebarOpen } = props;
  const { user } = useUser();
  const dispatch = useDispatch();
  const autoRefreshTime = useAutoRefreshTime();
  const [isOpenLogoutModal, setIsOpenLogoutModal] = useState(false);
  const [input] = useState("");

  const onToggleModal = () => {
    setIsOpenLogoutModal(!isOpenLogoutModal);
  };

  useEffect(() => {
    const data = user?.SiderBar;
    if (input) {
      const newData = Object.fromEntries(
        Object.entries(data || {})?.filter(([key, val]) => {
          if (key === "SETTING") {
            return false;
          }
          return !!val?.find((item) => {
            return !!item?.name?.toLowerCase()?.includes(input?.toLowerCase());
          });
        }) || {}
      );
      const nData = { ...newData };
      Object.entries(nData || {})?.forEach(([key, val]) => {
        const filterd = val?.filter((item) => {
          return item?.name?.toLowerCase()?.includes(input?.toLowerCase());
        });
        newData[key] = filterd;
      });
      newData["SETTING"] = user?.SiderBar?.SETTING;
      dispatch(setSidebar(newData));
    } else {
      dispatch(setSidebar(data));
    }
  }, [input]);

  return (
    <React.Fragment>
      <div className="sticky-navbar w-100">
        <div className="header-padding w-100">
          <header
            id="page-topbar"
            style={{
              borderRadius: "5px",
              boxShadow: !isHorizontal
                ? "0 2px 6px rgba(47, 43, 61,.14),0 0 transparent,0 0 transparent"
                : "",
            }}
            className="position-relative w-100 header-border"
          >
            <div className="navbar-header">
              <div>
                <div className="d-flex gap-50">
                  <button
                    type="button"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    className="btn btn-sm font-size-16 header-item waves-effect vertical-menu-btn nav-menubtn ms-1"
                    id="vertical-menu-btn"
                  >
                    <i className="fa-solid fa-bars" />
                  </button>
                </div>
                <div className="logout-position d-flex gap-50 align-items-center">
                  <div style={{ width: "96px" }}>
                    <DropDown
                      placeholder="Select Time"
                      isDefaultOption={false}
                      options={autoRefereshOptions}
                      value={autoRefreshTime}
                      onChange={(e) => {
                        dispatch(setAutoRefreshTime(e.value));
                      }}
                    />
                  </div>
                  <div onClick={onToggleModal} className="cursor-pointer ">
                    <i className="fas fa-sign-out-alt me-50" />
                    <a>Logout</a>
                  </div>
                </div>
              </div>
            </div>
            <LogoutConfirmModal
              isOpenLogoutModal={isOpenLogoutModal}
              onCloseModal={onToggleModal}
            />
          </header>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
