import React, { useState } from "react";
import { GrSend } from "react-icons/gr";
import { Button } from "reactstrap";
import { notificationSend } from "../../../redux/slices/smsSlice";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../helperFunctions";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import Loader from "../../../components/Custom/Loader";

const SendNotificationButton = ({
  isNotIcon,
  nickNameId,
  className,
  userId,
  justIcon,
  size,
  isBulk = false,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const sendNotification = async (e) => {
    try {
      e.stopPropagation()
      setLoading(true)
      const recipientId = isBulk
        ? { userId: userId }
        : { nickNameId: nickNameId };
      const res = await dispatch(notificationSend(recipientId)).unwrap();
      responseToaster(res);
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  };
  return (
    <>
      {justIcon ? (
        loading ? <Loader /> : <GrSend onClick={sendNotification} size={size || 20} className="cursor-pointer" />
      ) : (
        isNotIcon ?
          <CustomeButton
            className={className}
            isButtonLoading={loading}
            onClick={sendNotification}
          >
            Bulk Notification
          </CustomeButton> : loading ? <Loader /> : <GrSend size={20} className={className} onClick={sendNotification} />
      )}
    </>
  );
};

export default SendNotificationButton;
