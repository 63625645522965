import React, { useState } from "react";
import { Button, Input, Modal } from "reactstrap";
import { showToaster } from "../../../../constant";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";

const staticData = [
  { value: "1", label: "Level 1" },
  { value: "2", label: "Level 2" },
  { value: "3", label: "Level 3" },
  { value: "4", label: "Level 4" },
];
const staticDataTwo = [
  { value: "is_phonepe", label: "PhonePe" },
  { value: "is_paytm", label: "Paytm" },
  { value: "is_googlepay", label: "GooglePay" },
  { value: "all", label: "All" },
];

const initialOthers = {
  is_phonepe: 0,
  is_paytm: 0,
  is_googlepay: 0,
  all: 0,
};
const MerchantPayInAddMetaModal = (props) => {
  const { data, onCancelClick, onDoneClick, isButtonLoading } = props;
  const [methods, setMethods] = useState([]);
  const [levels, setLevels] = useState([]);
  const [others, setOthers] = useState(initialOthers);

  const onHide = () => {
    setLevels([]);
    setMethods([]);
    setOthers(initialOthers);
    onCancelClick();
  };

  const prepareChecked = (value) => {
    if (value === "all") {
      const values = Object.entries(others || {})?.map(([key, item]) => item);
      return !values?.includes(0);
    } else {
      return !!others?.[value];
    }
  };

  return (
    <Modal isOpen={!!data} toggle={onHide} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Add Bank</h3>
        <i
          onClick={() => {
            onHide();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <div>
          <p className="mb-0">
            {`${data?.pgName}(${data?.account_id}) select method and level`}
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <div className="p-0 gap-1 d-flex flex-wrap align-items-center my-1">
            {data?.methods?.map((method) => {
              return (
                <div className="gap">
                  <Input
                    type="checkbox"
                    checked={!!methods?.includes(method)}
                    onChange={(e) => {
                      if (methods?.includes(method)) {
                        const newMethods = methods?.filter(
                          (item) => item !== method
                        );
                        setMethods(newMethods);
                      } else {
                        const newMethods = [...methods];
                        newMethods.push(method);
                        setMethods(newMethods);
                      }
                    }}
                  />
                  {method}
                </div>
              );
            })}
          </div>
        </div>
        <div className="level-main">
          <div className="p-1 levels">
            <h5>Levels</h5>
            {staticData?.map((level) => {
              return (
                <div className="d-flex align-items-center gap mb-50">
                  <Input
                    type="checkbox"
                    checked={!!levels?.includes(level?.value)}
                    onChange={() => {
                      if (levels?.includes(level?.value)) {
                        const newLevels = levels?.filter(
                          (item) => item !== level?.value
                        );
                        setLevels(newLevels);
                      } else {
                        const newLevels = [...levels];
                        newLevels.push(level?.value);
                        setLevels(newLevels);
                      }
                    }}
                  />
                  {level?.label}
                </div>
              );
            })}
          </div>
          <div className="p-1">
            <h5>UPI Apps</h5>
            {staticDataTwo?.map((level) => {
              return (
                <div className="d-flex align-items-center gap mb-50">
                  <Input
                    type="checkbox"
                    checked={prepareChecked(level?.value)}
                    onChange={() => {
                      if (level.value === "all") {
                        const val = others?.[level.value] === 0 ? 1 : 0;

                        setOthers({
                          ...others,
                          is_phonepe: val,
                          is_paytm: val,
                          is_googlepay: val,
                          all: val,
                        });
                      } else {
                        setOthers({
                          ...others,
                          [level.value]: others?.[level.value] === 0 ? 1 : 0,
                        });
                      }
                    }}
                  />
                  {level?.label}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={onHide}>
            Cancel
          </Button>
          <CustomeButton
            className="submitbutton"
            isButtonLoading={isButtonLoading}
            onClick={async () => {
              const errorMessages = [];

              if (!levels?.length) {
                errorMessages.push("Must Select One Level");
              }
              if (!methods?.length) {
                errorMessages.push("Must Select One Method");
              }

              if (errorMessages?.length > 0) {
                errorMessages?.forEach((item) => {
                  showToaster(item, "Error");
                });
                return;
              }
              await onDoneClick({
                merchantId: data?.merchantId,
                pgName: data?.pgName,
                pgId: data?.account_id,
                paymentMethod: methods || [],
                level: levels || [],
                ...others,
              });
              onHide();
            }}
          >
            Submit
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default MerchantPayInAddMetaModal;
