import React from "react";
import { Input, Label } from "reactstrap";
import "./InputField.scss";

const InputField = (props) => {
    const {
        inputClassName = "",
        className = "",
        name,
        label,
        icon,
        rightIcon,
        righticonClick,
        type,
        message,
        errors,
        err,
        ...rest
    } = props;

    const errorMessage = message ? message : errors?.[name]?.message ? errors?.[name]?.message : "" || err
    return (
        <div className={`${className} select-div input-field`}>
            {label ? <Label>{label}</Label> : null}
            <div className="position-relative d-flex align-items-center">
                {icon ? <div className="icon">{icon}</div> : null}
                <Input
                    type={type}
                    className={`${inputClassName} ${icon ? "input-icon" : ""} ${rightIcon ? "input-right-icon" : ""
                        } w-100`}
                    style={{ borderColor: errorMessage ? "red" : "#c9d5e1" }}
                    step="any"
                    name={name}
                    {...(type === "number" && {
                        onFocus: (e) =>
                            e.target.addEventListener(
                                "wheel",
                                (e) => {
                                    e.preventDefault();
                                },
                                { passive: false }
                            ),
                    })}
                    {...rest}
                />
                {rightIcon ? <div className="right-icon" onClick={righticonClick}>{rightIcon}</div> : null}
            </div>
            {errorMessage ? (
                <span style={{ color: "red", fontSize: 12 }}>{errorMessage}</span>
            ) : null}
        </div>
    );
};

export default InputField;
