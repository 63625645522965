import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { proxyAdd, proxyEdit } from "../../../redux/slices/proxySlice";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import { useForm } from "react-hook-form";
import { responseToaster } from "../../../helperFunctions";
import EyeOff from "../../../assets/images/pages/eye-off.svg";
import EyeOn from "../../../assets/images/pages/eye-on.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const AddProxyModal = (props) => {
  const { onHide, isOpen, onGet } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const initialValues = {
    proxy_ip: "",
    label: "",
    username: "",
    password: "",
  };

  const validationSchema = Yup.object({
    ...(!isOpen?.id && {
      label: Yup.string().trim().required("Please Enter Label"),
      proxy_ip: Yup.string().trim().required("Please Enter Proxy Ip"),
      password: Yup.string().trim().required("Please Enter Password"),
    }),
    username: Yup.string().trim().nullable().required("Please Enter User Name"),
  });

  const onSubmit = async () => {
    try {
      const values = getValues();
      setIsLoading(true);
      const res = isOpen?.id
        ? await dispatch(proxyEdit({ id: isOpen?.id, ...values })).unwrap()
        : await dispatch(proxyAdd(values)).unwrap();
      responseToaster(res);
      onClose();
      onGet();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (isOpen?.id) {
      setValue("username", isOpen?.username);
    }
  }, [isOpen]);

  const onClose = () => {
    reset();
    onHide();
  };

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={!!isOpen}
      toggle={onClose}
      centered={true}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <h4 className="modal-title mt-0">
            {isOpen?.id ? "Update" : "Add New"} Proxy
          </h4>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body">
          <Row>
            {!isOpen?.id ? (
              <>
                <ControlledInput
                  name="label"
                  label="Label"
                  placeholder="Enter Label"
                  type="text"
                  control={control}
                  errors={errors}
                />
                <ControlledInput
                  name="proxy_ip"
                  label="Proxy IP"
                  placeholder="Enter Proxy IP"
                  type="text"
                  control={control}
                  errors={errors}
                />
              </>
            ) : null}
            <ControlledInput
              name="username"
              label="User Name"
              placeholder="Enter User Name"
              type="text"
              autoComplete="new-password"
              control={control}
              errors={errors}
            />
            <ControlledInput
              type={isShow ? "text" : "password"}
              name="password"
              label="Password"
              autoComplete="new-password"
              placeholder="Enter password"
              rightIcon={
                <img
                  src={isShow ? EyeOn : EyeOff}
                  alt="Eye"
                  onClick={() => setIsShow(!isShow)}
                />
              }
              control={control}
              errors={errors}
            />
          </Row>
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              isButtonLoading={isLoading}
            >
              {isOpen?.id ? "Update" : "Submit"}
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddProxyModal;
