// UI IMPORT

// PROJECT IMPORT
import { Button, Modal } from "reactstrap";

// THIRD- PARTY IMPORT
import CopyToClipboard from "react-copy-to-clipboard";
import { showToaster } from "../../../constant";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";

const PasswordModal = (props) => {
  const { isOpen, onHide, isCopy } = props;
  const onClose = () => {
    onHide();
  };

  if (!isOpen) return null;

  return (
    <Modal isOpen={!!isOpen} toggle={onClose} centered={true}>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div className="modal-header">
          <h3 className="modal-title mt-0">Reset Password</h3>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div
          className="modal-body d-flex flex-column justify-content-center"
        >
          <span>
            <span className="text-ev-black">Username :- </span>
            <span className="bold text-ev-secondary">
              {isCopy?.userName}
            </span>
          </span>
          <span>
            <span className="text-ev-black">Password :- </span>
            <span className="bold text-ev-secondary">
              {isCopy?.password}
            </span>
          </span>
        </div>
        <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <CopyToClipboard text={`${isCopy?.userName} ${isCopy?.password}`}>
            <CustomeButton
              onClick={() => {
                onClose();
                showToaster("Copied");
              }}
            >
              Copy & Close
            </CustomeButton>
          </CopyToClipboard>
        </div>
        </div>
      </div>
    </Modal>
  );
};

export default PasswordModal;
