import React, { useEffect, useState } from "react";
import { Button, Form, Label, Modal, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import CustomeButton from "../../../../components/Custom/Forms/CustomeButton/CustomeButton";
import ControlledInput from "../../../../components/Custom/Forms/Controller/ControlledInput";
import { useForm } from "react-hook-form";
import { responseToaster } from "../../../../helperFunctions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CheckBox from "../../../../components/Custom/Elements/CheckBox";
import {
  merchantRoleAdd,
  merchantRoleEdit,
  merchantUserEdit,
  useMerchantPer,
} from "../../../../redux/slices/merchantSlice";
import { rolesDetail } from "../../../../redux/slices/rolesSlice";
import Loader from "../../../../components/Custom/Loader";

const menuDependencies = {
  "Claim UTR": ["Deposit-List"],
  "Bulk Upload": ["Withdrawal-List"],
  Report: ["Deposit-List", "Withdrawal-List"],
};
const menuDependenciesMenu = {
  "Claim UTR": ["Deposit"],
  "Bulk Upload": ["Withdrawal"],
  Report: ["Deposit", "Withdrawal"],
};

const AddRoleModal = (props) => {
  const { onHide, isOpen, onGet, merchantData } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForRole, setIsLoadingForRole] = useState(false);
  const merchantPermission = useMerchantPer()
  const [checkedPermissions, setCheckedPermissions] = useState([]);

  const initialValues = {
    name: "",
    permission: []
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .nullable()
      .required("Please Enter Name"),
  });

  const onSubmit = async () => {
    try {
      if (!checkedPermissions?.length) {
        setError("permission", {
          type: "manual",
          message: "Please Select Accesss",
        });
        return;
      }

      const values = getValues();
      setIsLoading(true);
      const payload = {
        merchantId: merchantData?.merchantId,
        permission: checkedPermissions
      }
      const res = isOpen?.id
        ? await dispatch(
          merchantRoleEdit({ id: isOpen?.id, ...values, ...payload, })
        ).unwrap()
        : await dispatch(merchantRoleAdd({ ...values, ...payload })).unwrap();
      responseToaster(res);
      onClose();
      onGet();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const onGetDataById = async () => {
    try {
      setIsLoadingForRole(true);
      const payload = {
        id: isOpen?.id,
      };
      const res = await dispatch(rolesDetail(payload)).unwrap();
      if (res) {
        setFormData(res);
      }
      setIsLoadingForRole(false);
    } catch (err) {
      setIsLoadingForRole(false);
    }
  };

  const setFormData = (res) => {
    setValue("name", res?.role?.name);
    setCheckedPermissions(
      (res?.permission || [])?.map?.((permission) => {
        return permission?.name;
      })
    );
  };

  useEffect(() => {
    if (isOpen?.id) {
      onGetDataById();
    }
  }, [isOpen]);

  const onClose = () => {
    reset();
    onHide();
    setCheckedPermissions([]);
  };
  const getAllPermissionByKey = (key) => {
    return merchantPermission?.data?.[key];
  };

  const isAllChecked = (key) => {
    return (
      getAllPermissionByKey(key)?.filter((item) =>
        checkedPermissions?.includes(item)
      ).length === getAllPermissionByKey(key)?.length
    );
  };
  const getMainMenusFromCheckedPermissions = (
    permissions,
    checkedPermissions
  ) => {
    const mainMenus = Object.keys(permissions || {}).filter((mainMenu) => {
      return permissions[mainMenu].some((submenu) =>
        checkedPermissions.includes(submenu)
      );
    });

    return mainMenus;
  };

  const getMenusFromDependencies = (mainMenus, menuDependencies) => {
    return mainMenus.filter((menu) => menuDependencies.hasOwnProperty(menu));
  };

  const handleAllCheckboxChange = (event, key) => {
    const { checked } = event.target;
    setCheckedPermissions((prev) => {
      let updatedPermissions = [...prev];
      const relatedPermissions = getAllPermissionByKey(key);

      const relatedPermissionss = getRelatedPermissionsForMenu(key);

      if (checked) {
        clearErrors("permission");
        updatedPermissions = [
          ...new Set([...prev, ...relatedPermissions, ...relatedPermissionss]),
        ];
      } else if (Object.keys(menuDependencies)?.includes(key)) {
        const relatedPermission = merchantPermission?.data?.[key];

        updatedPermissions = [
          ...prev?.filter?.((permission) => {
            return ![
              ...menuDependenciesMenu?.[key]?.flatMap(
                (dependencyKey) => merchantPermission?.data?.[dependencyKey] || []
              ),
              ...relatedPermission,
            ]?.includes?.(permission);
          }),
          ...(menuDependencies?.[
            getMenusFromDependencies(
              getMainMenusFromCheckedPermissions(
                merchantPermission?.data,
                checkedPermissions
              ),
              menuDependencies
            )?.filter((keyValue) => {
              return keyValue !== key;
            })
          ] || []),
        ];
      } else {
        updatedPermissions = prev.filter(
          (permission) => !relatedPermissions.includes(permission)
        );
      }
      return updatedPermissions;
    });
  };

  const handleCheckboxChange = (event, key) => {
    const { value, checked } = event.target;

    setCheckedPermissions((prev) => {
      let updatedPermissions = [...prev];
      const relatedPermissions = getRelatedPermissions(value, key);

      if (checked) {
        clearErrors("permission");
        updatedPermissions = [
          ...new Set([...prev, value, ...relatedPermissions]),
        ];
      } else {
        const relatedPermission = merchantPermission?.data?.[key];

        if (
          Object.keys(menuDependencies)?.includes(key) &&
          relatedPermission?.[0] === value
        ) {
          return [
            ...prev?.filter?.((permission) => {
              return ![
                ...menuDependenciesMenu?.[key]?.flatMap(
                  (dependencyKey) => merchantPermission?.data?.[dependencyKey] || []
                ),

                ...relatedPermission,
              ]?.includes?.(permission)
            }),
            ...(menuDependencies?.[
              getMenusFromDependencies(
                getMainMenusFromCheckedPermissions(
                  merchantPermission?.data,
                  checkedPermissions
                ),
                menuDependencies
              )?.filter((keyValue) => {
                return keyValue !== key;
              })
            ] || []),
          ];
        }

        if (hasRelatedPermissionsChecked(value, key)) {
          return prev;
        }
        updatedPermissions = prev.filter((permission) => permission !== value);
      }
      return updatedPermissions;
    });
  };

  const getRelatedPermissionsForMenu = (key) => {
    const dependentPermissions = menuDependencies?.[key];

    if (Object.keys(menuDependencies)?.includes(key)) {
      return [merchantPermission?.data?.[key]?.[0], ...dependentPermissions];
    }

    return [merchantPermission?.data?.[key]?.[0]] || [];
  };

  const getRelatedPermissions = (value, key) => {
    const relatedPermission = merchantPermission?.data?.[key];
    const dependentPermissions = menuDependencies?.[key];

    if (
      (Object.keys(menuDependencies)?.includes(key)) &&
      relatedPermission?.includes(value)
    ) {
      return [merchantPermission?.data?.[key]?.[0], ...dependentPermissions];
    }

    return [merchantPermission?.data?.[key]?.[0]] || [];
  };

  const hasRelatedPermissionsChecked = (value, key) => {
    const relatedPermissions = merchantPermission?.data?.[key];

    return (
      relatedPermissions?.some((relatedPermission) =>
        checkedPermissions
          ?.filter((per) => {
            return per !== merchantPermission?.data?.[key]?.[0];
          })
          ?.includes(relatedPermission)
      ) && value === merchantPermission?.data?.[key]?.[0]
    );
  };


  const getAllPermissions = () => {
    const arr = [];
    Object.entries?.(merchantPermission?.data || {})?.forEach(([_, value]) => {
      value.forEach((item) => {
        arr.push(item);
      });
    });
    return arr;
  };

  const handleSuperAllCheckboxChange = (event) => {
    const { checked } = event.target;
    setCheckedPermissions(() => {
      if (checked) {
        clearErrors("permission");
        return getAllPermissions();
      } else {
        return [];
      }
    });
  };

  const isSuperAllChecked = () => {
    return (
      getAllPermissions()?.filter((item) => checkedPermissions?.includes(item))
        .length === getAllPermissions()?.length
    );
  };

  return (
    <Modal
      className="add-manual-payout-modal  modal-lg"
      isOpen={!!isOpen}
      toggle={onClose}
      centered={true}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <h4 className="modal-title mt-0">
            {isOpen?.id ? "Update" : "Add New"} Role
          </h4>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body">
          {isLoadingForRole ? <Loader /> : <Row>
            <ControlledInput
              name="name"
              label="Name"
              placeholder="Enter Name"
              type="text"
              disabled={isOpen?.id}
              autoComplete="new-password"
              control={control}
              errors={errors}
            />

            <div className={`select-div input-field mt-1`}>
              <div className="d-flex justify-content-between">
                <Label>Select Accesss</Label>
                <div>
                  <CheckBox
                    checked={isSuperAllChecked()}
                    onChange={(e) => handleSuperAllCheckboxChange(e)}
                    label="Select all access"
                    labelClassName="select-div input-field"
                  />
                </div>
              </div>
              {Object.entries?.(merchantPermission?.data || {})?.map?.(
                ([key, value]) => (
                  <div className="row rolesub_sec" key={key}>
                    <div className="col-lg-3 col-sm-4 roles_name">
                      <div className="d-flex">
                        <CheckBox
                          value={key}
                          checked={isAllChecked(key)}
                          onChange={(e) => handleAllCheckboxChange(e, key)}
                        />
                        <div>
                          <strong>{key}</strong>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="row">
                        {value?.map?.((permission) => (
                          <div className="col-lg-4">
                            <div key={permission}>
                              <CheckBox
                                value={permission}
                                checked={checkedPermissions.includes(
                                  permission
                                )}
                                label={permission}
                                onChange={(e) => handleCheckboxChange(e, key)}
                                containerClassName="align-items-start"
                                className="checkbox_margin"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )
              )}
              {errors?.permission?.message ? (
                <span style={{ color: "red", fontSize: 12 }}>
                  {errors?.permission?.message}
                </span>
              ) : null}
            </div>
          </Row>}

        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              disabled={isLoadingForRole}
              isButtonLoading={isLoading}
            >
              {isOpen?.id ? "Update" : "Submit"}
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddRoleModal;
