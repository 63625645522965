import React, { useRef, useState } from "react";
import { Button, Form, Label, Modal } from "reactstrap";
import { Switch } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Generate_File,
  Payout_Amount_Total,
  Payout_Banklist_Manual,
  Payout_amount_total_amount,
  isManualLevelUpdate,
} from "../../../../redux/slices/payinPayoutSlice";
import {
  GetMerchant,
  useMerchantOptions,
} from "../../../../redux/slices/merchantSlice";
import { responseToaster, seperator } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { DROPDOWN_ALL_VALUE, showToaster } from "../../../../constant";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../Forms/Controller/ControlledDropDown";
import ControlledInput from "../../Forms/Controller/ControlledInput";
import Loader from "../../Loader";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRole } from "../../../../redux/slices/authSlice";
import { Filter_Data_Transaction } from "../../../../redux/slices/transactionSlice";

const logicalDataOptions = [
  {
    value: "equal",
    label: "Equal",
  },
  {
    value: "greater_than",
    label: "Greater Than",
  },
  {
    value: "less_than",
    label: "Less than",
  },
];

const bankSheetDataOptions = [
  {
    value: "amount",
    label: "Amount",
  },
  {
    value: "count",
    label: "Count",
  },
];

const initialValues = {
  bankId: "",
  merchantId: DROPDOWN_ALL_VALUE,
  logicKey: "",
  logicAmt: 0,
  sheetKey: "",
  sheetValue: "",
};

const GenerateBankfileModal = (props) => {
  const { onCloseGeneratebankModal, generateBankModal, onGet } = props;
  const dispatch = useDispatch();
  const role = useRole("Withdrawal Manual");

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const isInitialMount = useRef(true);
  const { transactionFilterState } = useSelector((state) => state.transaction);

  const {
    manualpayoutBankState,
    payoutTotalAmountState,
    getTotalMerchantStateData,
    getTotalMerchantLoading,
  } = useSelector((state) => state.payinPayout);

  const schema = Yup.object({
    bankId: Yup.string().required("Bank Name Is Required"),
    logicAmt: Yup.string().required("Amount Is Required"),
    sheetValue: Yup.string().required("Amount Is Required"),
    logicKey: Yup.string().required("Select Automatic Logic Field"),
    sheetKey: Yup.string().required("Select Requirement Logic Field"),
  });

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const merchantOptions = useMerchantOptions(false);

  const prepareBankDataOption = () => {
    const bankDataOption = manualpayoutBankState?.data
      ?.slice()
      ?.sort((a, b) => a?.bankName?.localeCompare(b?.bankName))
      ?.map((val) => ({
        value: `${val?.accountId}`,
        label: `${val?.bankName} - ${val?.note} `,
      }));
    return bankDataOption;
  };


  const getFilterTransactions = async () => {
    try {
      await dispatch(Filter_Data_Transaction()).unwrap();
    } catch (err) { }
  };

  const prepareMerchantOption = () => {
    const options = transactionFilterState?.cilent_data
      ?.slice()
      ?.sort((a, b) => a?.merchantName?.localeCompare(b?.merchantName))
      ?.map((val) => {
        const clientDataOption = {
          value: val?.merchantId,
          label: val?.merchantName,
        };
        return clientDataOption;
      });
    return options;
  };

  const GetMerchantData = async () => {
    try {
      const payload = {
        isFilter: false,
        limit: 25,
        page_no: 1,
      };
      await dispatch(GetMerchant(payload)).unwrap();
    } catch (err) { }
  };

  const GetMerchantDataAmount = async () => {
    try {
      const payload = {
        merchantId: watch("merchantId") || "all",
      };
      await dispatch(Payout_amount_total_amount(payload)).unwrap();
    } catch (err) { }
  };
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    if (watch("merchantId") && role?.includes("add-withdrawal-manual")) {
      GetMerchantDataAmount();
    }
  }, [watch("merchantId")]);

  const Payout_Banklist = async () => {
    try {
      await dispatch(Payout_Banklist_Manual()).unwrap();
    } catch (err) { }
  };

  useEffect(() => {
    // GetMerchantData();
    if (!!generateBankModal) {
      getFilterTransactions();      
      GetMerchantDataAmount()
    }
  }, [generateBankModal]);

  const onSubmit = async () => {
    try {
      const values = getValues();
      const res = await generateFile(values);
      if (res) {
        responseToaster(res);
      }
    } catch (err) { }
  };

  const generateFile = async (values) => {
    try {
      setIsButtonLoading(true);
      const res = await dispatch(Generate_File(values)).unwrap();
      responseToaster(res);
      onHide();
      setIsButtonLoading(false);
      onGet();
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const onUpdateIsManualLevel = async (value) => {
    try {
      const payload = {
        is_manual_level_active: value ? 1 : 0,
      };
      const res = await dispatch(isManualLevelUpdate(payload)).unwrap();
      Get_Payout_Amount_Total();
      responseToaster(res);
    } catch (err) { }
  };

  const Get_Payout_Amount_Total = async () => {
    try {
      await dispatch(Payout_Amount_Total()).unwrap();
    } catch (err) { }
  };

  useEffect(() => {
    if (generateBankModal && role?.includes("add-withdrawal-manual")) {
      Payout_Banklist();
      Get_Payout_Amount_Total();
    }
  }, [generateBankModal]);

  useEffect(() => {
    if (role?.includes("add-withdrawal-manual")) {
      Get_Payout_Amount_Total();
    }
  }, []);

  const onHide = () => {
    onCloseGeneratebankModal();
    reset();
  };

  return (
    <>
      <Modal
        className="add-manual-payout-modal"
        isOpen={generateBankModal}
        toggle={onHide}
        centered={true}
      >
        <div className="modal-header">
          <h3 className="modal-title mt-0">Generate File</h3>
          <i
            onClick={() => {
              onHide();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`modal-body w-100 ${getTotalMerchantLoading ? "opacity-25" : ""
              } pb-3`}
          >
            <div className="d-flex justify-content-end">
              <Switch
                size="small"
                checked={!!payoutTotalAmountState?.is_manual_level_active}
                disabled={!role?.includes("add-withdrawal-manual")}
                onChange={(e) => {
                  onUpdateIsManualLevel(e.target.checked);
                }}
              />
              <p>Is Manual Level</p>
            </div>
            <ControlledDropDown
              id="bankfilter"
              name="bankId"
              placeholder="Select Bank Name"
              label="Bank Name"
              disabled={!role?.includes("add-withdrawal-manual")}
              isDefaultOption={false}
              options={prepareBankDataOption()}
              control={control}
              errors={errors}
            />
            <ControlledDropDown
              id="merchantfilter"
              name="merchantId"
              label="Merchant Name"
              isDefaultOption={DROPDOWN_ALL_VALUE}
              options={prepareMerchantOption()}
              control={control}
            />
            {role?.includes("add-withdrawal-manual") ? <div className="my-2">
              <h4>
                Total Amount :
                {getTotalMerchantStateData?.data?.total_init_amount
                  ? seperator(
                    getTotalMerchantStateData?.data?.total_init_amount
                  )
                  : seperator(payoutTotalAmountState?.withdrawalAmt)}
              </h4>
              <h4>
                Total Init Count :
                {seperator(
                  getTotalMerchantStateData?.data?.total_init_count,
                  false
                )}
              </h4>
            </div> : null}
            <div className="select-div">
              <Label>Logical Amount</Label>
              <div className="d-flex">
                <ControlledInput
                  placeholder="Enter Amount"
                  name="logicAmt"
                  control={control}
                  errors={errors}
                />
                <ControlledDropDown
                  className="w-100"
                  id="bankfilter"
                  name="logicKey"
                  placeholder="Select Automatic Logic"
                  options={logicalDataOptions}
                  control={control}
                  errors={errors}
                />
              </div>
            </div>
            <div className="select-div">
              <Label>Bank Sheet Requirement</Label>
              <div className="d-flex">
                <ControlledInput
                  placeholder="Enter Amount"
                  name="sheetValue"
                  control={control}
                  errors={errors}
                />
                <ControlledDropDown
                  className="w-100"
                  id="bankfilter"
                  name="sheetKey"
                  placeholder="Select Your Requirement Logic"
                  options={bankSheetDataOptions}
                  control={control}
                  errors={errors}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="d-flex justify-content-end button-space">
              <Button className="graybutton" onClick={() => onHide()}>
                Cancel
              </Button>
              <CustomeButton
                className="submitbutton"
                type="submit"
                disabled={getTotalMerchantLoading}
                isButtonLoading={isButtonLoading}
              >
                Submit
              </CustomeButton>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default GenerateBankfileModal;
