import { ADD_BANK, ADD_NICK_NAME, ADD_SMS, ADD_USER, BLOCK_USER_DEVICE, DELETE_NICK_NAME, DELETE_USER, GET_MERCHANT_LIST, GET_SMS, GET_SMS_USERS, GET_USER_DETAIL, GET_USERLIST, RESET_DEVICE, RESET_PWD, SEND_NOTIFICATION, UPDATE_APK, UPDATE_NICK_NAME } from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const getSms = (payload) => {
  return apiInstance.post(GET_SMS, payload);
};
export const addSms = (payload) => {
  return apiInstance.post(ADD_SMS, payload);
};
export const getSmsUsers = (payload) => {
  return apiInstance.get(GET_SMS_USERS, payload);
};
export const getUserList = (payload) => {
  return apiInstance.post(GET_USERLIST, payload);
};
export const addUser = (payload) => {
  return apiInstance.post(ADD_USER, payload);
};
export const deleteUser = (payload) => {
  return apiInstance.post(DELETE_USER, payload);
};
export const getMerchantList = (payload) => {
  return apiInstance.post(GET_MERCHANT_LIST, payload);
};
export const addBank = (payload) => {
  return apiInstance.post(ADD_BANK, payload);
};
export const sendNotification = (payload) => {
  return apiInstance.post(SEND_NOTIFICATION, payload);
};
export const resetPassword = (payload) => {
  return apiInstance.post(RESET_PWD, payload);
};
export const resetDeviceId = (payload) => {
  return apiInstance.post(RESET_DEVICE, payload);
};
export const nickNameAddBank = (payload) => {
  return apiInstance.post(ADD_NICK_NAME, payload);
};
export const getUserDetails = (payload) => {
  return apiInstance.post(GET_USER_DETAIL, payload);
};
export const addUpdateNickName = (payload) => {
  return apiInstance.post(UPDATE_NICK_NAME, payload);
};
export const deleteNickName = (payload) => {
  return apiInstance.post(DELETE_NICK_NAME, payload);
};
export const blockUserDevice = (payload) => {
  return apiInstance.post(BLOCK_USER_DEVICE, payload);
};
export const updateApk = (payload) => {
  return apiInstance.post(UPDATE_APK, payload);
};