import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, Label, Modal, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import CustomeButton from "../../../../components/Custom/Forms/CustomeButton/CustomeButton";
import ControlledInput from "../../../../components/Custom/Forms/Controller/ControlledInput";
import { useForm } from "react-hook-form";
import { responseToaster } from "../../../../helperFunctions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ControlledDropDown from "../../../../components/Custom/Forms/Controller/ControlledDropDown";
import {
  merchantUserAdd,
  merchantUserEdit,
} from "../../../../redux/slices/merchantSlice";
import CopyToClipboard from "react-copy-to-clipboard";
import { showToaster } from "../../../../constant";

const AddUsersModal = (props) => {
  const { onHide, isOpen, onGet, data, merchantData } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  const initialValues = {
    user_name: "",
    // password: "",
    roleId: ""
  };

  const validationSchema = Yup.object({
    user_name: Yup.string()
      .trim()
      .nullable()
      .required("Please Enter User Name"),
    roleId: Yup.string().trim().required("Please Select Role"),
   /*  ...(!isOpen?.id && {
      password: Yup.string().trim().required("Please Enter Password"),
    }), */
  });

  const getMerchantRoleOptions = () => {
    const options = data?.roles
      ?.slice()
      ?.sort((a, b) => a?.name?.localeCompare(b?.name))
      ?.map((val) => {
        const clientDataOption = {
          value: val?.id,
          label: val?.name,
        };
        return clientDataOption;
      });
    return options;
  };

  const onSubmit = async () => {
    try {
      const values = getValues();
      setIsLoading(true);
      const res = isOpen?.id
        ? await dispatch(
          merchantUserEdit({ id: isOpen?.id, ...values, merchantId: merchantData?.merchantId, })
        ).unwrap()
        : await dispatch(merchantUserAdd({ ...values, merchantId: merchantData?.merchantId, })).unwrap();

        if(!isOpen?.id){
          setIsCopy(res);
        }else{
          onClose()
        }
      responseToaster(res);
      onGet();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
      if (isOpen?.id) {
        setValue("user_name", isOpen?.user_name);
        setValue("roleId", isOpen?.role_id);
      }
  }, [isOpen]);

  const onClose = () => {
    reset();
    setIsCopy(false);
    onHide();
    // setData(initialData);
  };
  console.log('isCopy', isCopy)

  return (
    <Modal
      className="add-manual-payout-modal"
      isOpen={!!isOpen}
      toggle={onClose}
      centered={true}
    >
      {isCopy?.password ? (
        <>
          <div>
            <div className="modal-header">
              <h3 className="modal-title mt-0">
                User account has been created.
              </h3>
              <i
                onClick={() => {
                  onClose();
                }}
                className="fa-solid fa-xmark"
              ></i>
            </div>
            <div
              className="modal-body"
              style={{
                padding: 100,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>
                <span>User Name :- </span>
                <span className="bold text-[#4d4444]">
                  {isCopy?.username}
                </span>
              </span>
              <span>
                <span>Password :- </span>
                <span className="bold text-[#4d4444]">
                  {isCopy?.password}
                </span>
              </span>
            </div>
            <div className="modal-footer">
              <div className="d-flex justify-content-end button-space">
                <CopyToClipboard
                  text={`User Name: ${isCopy?.username}\nPassword: ${isCopy?.password}`}
                >
                  <Button
                    className="submitbutton"
                    onClick={() => {
                      onClose();
                      showToaster("Copied");
                    }}
                  >
                    Copy & Close
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-header">
            <h4 className="modal-title mt-0">
              {isOpen?.id ? "Update" : "Add New"} User
            </h4>
            <i
              onClick={() => {
                onClose();
              }}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <div className="modal-body">
            <Row>
              <ControlledInput
                name="user_name"
                label="User Name"
                placeholder="Enter User Name"
                type="text"
                disabled={isOpen?.id}
                autoComplete="new-password"
                control={control}
                errors={errors}
              />
             {/*  {!isOpen?.id ? (
                <>
                  <ControlledInput
                    type={isShow ? "text" : "password"}
                    name="password"
                    label="Password"
                    autoComplete="new-password"
                    placeholder="Enter password"
                    rightIcon={
                      <img
                        src={isShow ? EyeOn : EyeOff}
                        alt="Eye"
                        onClick={() => setIsShow(!isShow)}
                      />
                    }
                    control={control}
                    errors={errors}
                  />
                </>
              ) : null} */}
              <ControlledDropDown
                name="roleId"
                label="Role"
                options={getMerchantRoleOptions()}
                control={control}
                errors={errors}
              />
            </Row>
          </div>
          <div className="modal-footer">
            <div className="d-flex justify-content-end button-space">
              <Button className="graybutton" onClick={() => onClose()}>
                Cancel
              </Button>
              <CustomeButton
                type="submit"
                className="submitbutton"
                isButtonLoading={isLoading}
              >
                {isOpen?.id ? "Update" : "Submit"}
              </CustomeButton>
            </div>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default AddUsersModal;
