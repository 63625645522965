import CopyToClipboard from "react-copy-to-clipboard";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { Button, Modal } from "reactstrap";
import { userAdd } from "../../../redux/slices/smsSlice";
import { getAcronym } from "../../../helperFunctions";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import { showToaster } from "../../../constant";
import { getBankListOptions } from "./mock";
import { useDispatch, useSelector } from "react-redux";
import { Filter_Data_Transaction } from "../../../redux/slices/transactionSlice";
import { FaPlus, FaRegCircleCheck } from "react-icons/fa6";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import { MdDelete } from "react-icons/md";
import DropDown from "../../../components/Custom/Forms/DropDown/DropDown";
import InputField from "../../../components/Custom/Forms/InputField/InputField";
import { IoMdClose } from "react-icons/io";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import { useRole } from "../../../redux/slices/authSlice";


const UserModal = (props) => {
  const { isOpen, data, onClose, getUserData } = props;
  const [isCopy, setIsCopy] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [bankFromIFSCDynamic, setbankFromIFSCDynamic] = useState([""]);
  const [bankOptions, setBankOptions] = useState();
  const [dynamicFields, setDynamicFields] = useState([]);
  const [dynamicFieldsErrors, setDynamicFieldsErrors] = useState([]);
  const [showMerchantInput, setShowMerchantInput] = useState(false);
  const dispatch = useDispatch();
  const { transactionFilterState } = useSelector(
    (state) => state.transaction
  );
  const role = useRole("Sms Sync");

  const validateIFSCAsync1 = async (value, index) => {
    try {
      if (value) {
        const response = await axios.get(`https://ifsc.razorpay.com/${value}`);

        setbankFromIFSCDynamic((prevState) => {
          const newBankFromIFSCDynamic = [...prevState];
          newBankFromIFSCDynamic[index] = `${response?.data?.BANK} - ${response?.data?.BRANCH}`;
          return newBankFromIFSCDynamic;
        });

        // Update bank options
        if (!bankOptions?.find?.((item) => item?.value === response?.data?.BANK)) {
          setBankOptions((prevBankOptions) => [
            ...prevBankOptions,
            {
              label: `${getAcronym(response?.data?.BANK)} - ${response?.data?.BANK}`,
              value: response?.data?.BANK,
            },
          ]);
        }
        return !!response.data;
      }
      return false;
    } catch (error) {
      setDynamicFieldsErrors((prevFields) => {
        const newFields = [...prevFields];
        newFields[index]["ifsc_code"] = "Invalid IFSC code";
        return newFields;
      });
      return false;
    }
  };

  const schema = yup.object({
    userName: yup.string().required("Please enter user name"),
    merchantName: yup.string().required(`Please ${showMerchantInput ? "add" : "select"} merchant name`),
  });

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    trigger,
    resetField,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });

  const handleValidateIFSC = async (data) => {
    if (data?.length > 0) {
      for (let index = 0; index < data?.length; index++) {
        const bank = data[index];
        await validateIFSCAsync1(bank?.ifsc_code, index);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(data)?.length) {
      const modifiedTemp = {
        userName: data?.user_name,
        merchantName: data?.merchant_name,
        userId: data?.id,
        bankData: data?.sms_user_bank_details?.map(
          (bank) => ({
            id: bank?.id,
            ifsc_code: bank?.ifsc_code,
            bank_name: bank?.bank_name,
            account_number: bank?.account_number,
            upi_id: bank?.upi_id,
          })
        ),
      };
      handleValidateIFSC(data?.sms_user_bank_details);

      setValue("userName", modifiedTemp?.userName);
      const tempMerchantName = prepareMerchantOptions()?.find((merchant) => {
        return merchant?.value === modifiedTemp?.merchantName
      })
      setShowMerchantInput(!tempMerchantName)
      setValue("merchantName", modifiedTemp?.merchantName);
      setDynamicFields(modifiedTemp?.bankData);
      setDynamicFieldsErrors(
        data?.sms_user_bank_details?.map((bank) => ({
          ifsc_code: "",
          bank_name: "",
          account_number: "",
          upi_id: "",
        }))
      );
      getMerchant();
    } else {
      // Add USER
      handleAddFields();
    }
  }, [data]);

  const getMerchant = async () => {
    try {
      await dispatch(Filter_Data_Transaction())?.unwrap()
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    getMerchant();
  }, []);

  const findDuplicateUPI = () => {
    const uniqueUPIs = new Set();
    const duplicateUPIs = [];

    dynamicFields.forEach((item) => {
      if (item.upi_id && uniqueUPIs.has(item.upi_id)) {
        duplicateUPIs.push(item.upi_id);
      } else {
        uniqueUPIs.add(item.upi_id);
      }
    });

    return duplicateUPIs;
  };

  const findDuplicateInfo = () => {
    const uniqueInfo = new Map();
    const duplicateInfo = [];

    dynamicFields.forEach((item) => {
      const key = `${item.ifsc_code}-${item.account_number}`;
      if (uniqueInfo.has(key)) {
        duplicateInfo.push(key);
      } else {
        uniqueInfo.set(key, new Set([item.upi_id]));
      }
    });

    return duplicateInfo;
  };

  const handleButtonClick = async () => {
    try {
      const newErrors = await Promise.all(
        dynamicFields?.map(async (item, index) => {
          const errors = {};

          const tempBankName = bankFromIFSCDynamic?.[index]?.split?.(" - ")?.[0]
          const errorMessage = item?.bank_name?.trim()?.length ? tempBankName !== item?.bank_name?.trim() ? "The bank and IFSC code do not match." : "" : "Please select Bank.";
          if (item?.ifsc_code?.trim() === "") {
            errors.ifsc_code = "Please enter IFSC code.";
          }
          if (item?.bank_name?.trim() === "") {
            errors.bank_name = "Please select Bank.";
          }
          if (tempBankName !== item?.bank_name?.trim()) {
            errors.bank_name = errorMessage;
          } else {
            delete errors["bank_name"]
          }
          if (item?.account_number?.trim() === "") {
            errors.account_number = "Please enter Bank account number.";
          }
          const isValidUpi = /^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$/.test(
            item?.upi_id?.trim()
          );
          if (!isValidUpi) {
            errors.upi_id = "Please enter UPI ID.";
          }

          if (!!item?.ifsc_code?.trim()) {
            try {
              await axios.get(`https://ifsc.razorpay.com/${item?.ifsc_code}`);
            } catch (error) {
              errors.ifsc_code = "Invalid IFSC code";
            }
          }

          setDynamicFieldsErrors((prevErrors) => {
            const newErrors = [...prevErrors];
            newErrors[index] = errors;
            return newErrors;
          });
          return errors;
        })
      );
      // Check if any field has error
      const hasError = newErrors?.some(
        (error) => Object.keys(error)?.length !== 0
      );

      if (hasError) {
        return;
      }

      if (!customValidation()) {
        return;
      }

      setisLoading(true);
      const values = getValues();
      const { IfscCode, bankAcNo, bankName, merchantName, upiId, userName } =
        values;
      let payload = {
        bankData: dynamicFields,
        IfscCode,
        bankAcNo,
        bankName,
        merchantName,
        upiId,
        userName,
      };
      const res = await dispatch(userAdd({
        ...(data && data?.id && { userId: data?.id }),
        ...payload,
      })).unwrap();
      if (res?.status && !data?.id) {
        showToaster(res?.message);
        setIsCopy(res?.data);
        getUserData();
        reset()
        setShowMerchantInput(false);
        setDynamicFieldsErrors([]);
        setDynamicFields([]);
        setbankFromIFSCDynamic([]);
      }
      if (!!data?.id) {
        showToaster(res?.message);
        getUserData();
        onClose();
        reset()
        setShowMerchantInput(false);
        setDynamicFieldsErrors([]);
        setDynamicFields([]);
        setbankFromIFSCDynamic([]);
      }
      setisLoading(false);
    } catch (err) {
      setisLoading(false);
      console.error("Error:", err);
    }
  };

  const prepareBankOptions = () => {
    const options =
      (getBankListOptions ?? [])
        .map((item) => ({
          label: `${item?.label} - ${item?.value}`,
          value: item?.value,
        }))
        .sort((a, b) =>
          a.label.localeCompare(b.label)
        ) || [];
    setBankOptions([...options]);
    return [...options];
  };

  useEffect(() => {
    prepareBankOptions();
  }, []);

  const prepareMerchantOptions = () => {
    const options = transactionFilterState?.cilent_data
      ?.slice()
      ?.sort((a, b) => a?.merchantName?.localeCompare(b?.merchantName))
      ?.map((val) => {
        const clientDataOption = {
          value: val?.merchantId,
          label: val?.merchantName,
        };
        return clientDataOption;
      });
    return options;
  };

  const handleAddFields = () => {
    setDynamicFields((prevFields) => [
      ...prevFields,
      {
        ifsc_code: "",
        bank_name: "",
        account_number: "",
        upi_id: "",
      },
    ]);
    setDynamicFieldsErrors((prevFields) => [
      ...prevFields,
      {
        ifsc_code: "",
        bank_name: "",
        account_number: "",
        upi_id: "",
      },
    ]);
  };

  const validateFields = () => {
    dynamicFields.forEach((item, index) => {
      Object.entries(item ?? {})
        ?.filter(([key]) => key !== "id")
        ?.forEach(([key, value]) => {
          setDynamicFieldsErrors((prevFields) => {
            const newFields = [...prevFields];

            const trimmedValue = value?.trim();

            let errorMessage = "";

            switch (key) {
              case "ifsc_code":
                errorMessage = trimmedValue.length
                  ? ""
                  : "Please enter IFSC code.";
                break;
              case "bank_name":
                const tempBankName = bankFromIFSCDynamic?.[index]?.split(" - ")?.[0]
                errorMessage = trimmedValue?.length ? tempBankName !== trimmedValue ? "The bank and IFSC code do not match." : "" : "Please select Bank.";
                break;
              case "account_number":
                errorMessage = trimmedValue.length
                  ? ""
                  : "Please enter Bank account number.";
                break;
              case "upi_id":
                const isValidUpi = /^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$/.test(
                  trimmedValue
                );
                errorMessage = isValidUpi ? "" : "Please enter UPI ID";
                break;
              default:
                errorMessage = "";
            }

            if (key === "ifsc_code") {
              validateIFSCAsync1(trimmedValue, index);
            }

            newFields[index][key] = errorMessage;
            return newFields;
          });
        });
    });
  };

  const customValidation = () => {
    // UPI ID could not be same
    const duplicateUPIs = findDuplicateUPI();
    if (duplicateUPIs?.length) {
      showToaster("There are duplicate UPI Ids", "Error");
      return;
    }

    // same bank account number should not available when ifsc are same
    const duplicateInfo = findDuplicateInfo();
    if (duplicateInfo?.length) {
      showToaster("There are duplicate accounts", "Error");
      return;
    }

    return true;
  };

  const handleChange = (index, key, value) => {
    setDynamicFields((prevFields) => {
      const newFields = [...prevFields];
      newFields[index][key] = value;
      return newFields;
    });

    setDynamicFieldsErrors((prevFields) => {
      const newFields = [...prevFields];
      const trimmedValue = value?.trim();

      let errorMessage = "";

      switch (key) {
        case "ifsc_code":
          errorMessage = trimmedValue.length ? "" : "Please enter IFSC code.";
          break;
        case "bank_name":
          errorMessage = trimmedValue.length ? "" : "Please select Bank.";
          break;
        case "account_number":
          errorMessage = trimmedValue.length
            ? ""
            : "Please enter Bank account number.";
          break;
        case "upi_id":
          const isValidUpi = /^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$/.test(
            trimmedValue
          );
          errorMessage = isValidUpi ? "" : "Please enter UPI ID";
          break;
        default:
          errorMessage = "";
      }

      newFields[index][key] = errorMessage;

      return newFields;
    });
  };

  const handleDeleteBank = (index) => {
    setDynamicFields((prevFields) => {
      const newFields = [...prevFields];
      newFields.splice(index, 1);
      return newFields;
    });
    setDynamicFieldsErrors((prevFields) => {
      const newFields = [...prevFields];
      newFields.splice(index, 1);
      return newFields;
    });
    setbankFromIFSCDynamic((prevFields) => {
      const newFields = [...prevFields];
      newFields.splice(index, 1);
      return newFields;
    });
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        reset();
        setIsCopy(null);
        onClose();
        setDynamicFieldsErrors([]);
        setDynamicFields([]);
        setbankFromIFSCDynamic([]);
        setShowMerchantInput(false);
        setisLoading(false);
      }}
      centered={true}
      className={`${!!!isCopy ? "modal-lg" : "modal-md"}`}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">{`${!!!isCopy ? `${Object.keys(data)?.length ? "Update" : "Add"} User` : "User's Credential"}`}</h3>
        <i
          onClick={() => {
            reset();
            setIsCopy(null);
            onClose();
            setDynamicFieldsErrors([]);
            setDynamicFields([]);
            setbankFromIFSCDynamic([]);
            setShowMerchantInput(false);
            setisLoading(false);
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <div>
          {!!!isCopy ? (
            <div>
              <form onSubmit={handleSubmit(handleButtonClick)}>
                <div className="row">
                  <div className="col-6">
                    <ControlledInput
                      className="w-full"
                      name="userName"
                      placeholder="Enter User Name"
                      label="User Name"
                      control={control}
                      onChange={(e) => {
                        if (/^\S*$/.test(e.target.value)) {
                          setValue("userName", e.target.value);
                        }
                        trigger("userName")
                      }}
                      errors={errors}
                      disabled={!!data?.id}
                    />
                  </div>
                  <div className="col-6 d-flex">
                    {!showMerchantInput ? (
                      <ControlledDropDown
                        className="w-100"
                        name="merchantName"
                        label="Merchant Name"
                        menuPlacement="auto"
                        options={prepareMerchantOptions()}
                        control={control}
                        errors={errors}
                      />
                    ) : (
                      <ControlledInput
                        className="w-100"
                        name="merchantName"
                        placeholder="Enter New Merchant Name"
                        label="Enter New Merchant Name"
                        control={control}
                        errors={errors}
                      />
                    )}
                    {!showMerchantInput ? (
                      <div style={{ marginTop: "27px", paddingLeft: "5px" }}>
                        <Button className="submitbutton" onClick={() => {
                          setShowMerchantInput(true);
                          resetField("merchantName");
                        }}>
                          <FaPlus
                            name="PlusIcon"
                          />
                        </Button>
                      </div>

                    ) : (
                      <div style={{ marginTop: "27px", paddingLeft: "5px" }}>
                        <Button className="submitbutton" onClick={() => {
                          setShowMerchantInput(false);
                          resetField("merchantName");
                        }}>
                          <IoMdClose
                            name="Closeicon"
                            alt="Logo"
                          />
                        </Button>
                      </div>
                    )}</div>
                </div>
                <div className="pt-2">
                  <p className="text-xl font-bold text-ev-Tertiary pt-2">
                    Bank Details
                  </p>
                  <div className="row align-items-start">
                    {dynamicFields?.map((item, i, arr) => {
                      return (
                        <>
                          <Fragment key={i}>
                            {Object.entries?.(item).map(
                              ([k, v], index, array) => {

                                return k === "ifsc_code" ||
                                  k === "bank_name" ||
                                  k === "account_number" ||
                                  k === "upi_id" ? (
                                  <div className="col-3 pb-1">
                                    <Fragment key={k}>
                                      {k === "ifsc_code" ? (
                                        <div className="d-flex flex-column">
                                          <div className="d-flex ">
                                            <InputField
                                              name={`IfscCode-${i}`}
                                              placeholder="Enter IFSC code"
                                              label="IFSC"
                                              value={v}
                                              onChange={(e) => {
                                                handleChange(
                                                  i,
                                                  k,
                                                  e.target.value.toUpperCase()
                                                );
                                                const newBankFromIFSCDynamic = [
                                                  ...bankFromIFSCDynamic,
                                                ];
                                                newBankFromIFSCDynamic[i] = "";
                                                setbankFromIFSCDynamic(
                                                  newBankFromIFSCDynamic
                                                );
                                              }}
                                              err={
                                                dynamicFieldsErrors[i]?.[
                                                "ifsc_code"
                                                ]
                                              }
                                            />
                                            <div
                                              style={{ marginTop: "30px", paddingLeft: "4px" }}
                                              onClick={() => {
                                                validateIFSCAsync1(v, i);
                                              }}
                                            >
                                              <FaRegCircleCheck />

                                            </div>
                                          </div>
                                          <span className="small text-primary">
                                            {!!bankFromIFSCDynamic?.[i]?.length
                                              ? bankFromIFSCDynamic?.[i]
                                              : null}
                                          </span>
                                        </div>
                                      ) : null}
                                      {k === "bank_name" ? (
                                        <div>
                                          <DropDown
                                            className="w-full"
                                            name={`bankName-${i}`}
                                            label="Bank Name"
                                            menuPlacement="auto"
                                            options={bankOptions}
                                            value={v}
                                            onChange={(value) => {
                                              handleChange(i, k, value?.value)
                                              validateIFSCAsync1(dynamicFields?.[i]?.ifsc_code?.trim(), i)
                                            }
                                            }
                                            err={
                                              dynamicFieldsErrors[i]?.[
                                              "bank_name"
                                              ]
                                            }
                                          />
                                        </div>
                                      ) : null}
                                      {k === "account_number" ? (
                                        <div>
                                          <InputField
                                            className="w-full"
                                            name={`bankAcNo-${i}`}
                                            type="number"
                                            placeholder="Enter bank account number"
                                            label="Bank A/C No"
                                            value={v}
                                            onChange={(e) => {
                                              if (
                                                /^\d*$/g.test(e?.target?.value)
                                              ) {
                                                handleChange(
                                                  i,
                                                  k,
                                                  e.target.value
                                                );
                                              }
                                            }}
                                            err={
                                              dynamicFieldsErrors[i]?.[
                                              "account_number"
                                              ]
                                            }
                                          />
                                        </div>
                                      ) : null}
                                      {k === "upi_id" ? (
                                        <div className="d-flex">
                                          <InputField
                                            className="w-full"
                                            name={`upiId-${i}`}
                                            placeholder="Enter UPI ID"
                                            label="UPI ID"
                                            value={v}
                                            onChange={(e) =>
                                              handleChange(i, k, e.target.value)
                                            }
                                            err={
                                              dynamicFieldsErrors[i]?.["upi_id"]
                                            }
                                          />
                                          {array?.length - 1 === index ||
                                            (array?.length - 1 === index &&
                                              arr?.length - 1 === i) ? (
                                            <div style={{ marginTop: "30px", paddingLeft: "4px" }}>
                                              {array?.length - 1 === index ? (
                                                <div className={`${i === 0 ? "opacity-25" : ""}`}>
                                                  <MdDelete
                                                    name="DeleteIcon"
                                                    onClick={() => {
                                                      if (i !== 0) {
                                                        handleDeleteBank(i)
                                                      }
                                                    }
                                                    }
                                                  />

                                                </div>
                                              ) : null}
                                            </div>
                                          ) : null}
                                        </div>
                                      ) : null}
                                    </Fragment>

                                  </div>
                                ) : null;
                              }
                            )}
                          </Fragment>
                        </>
                      );
                    })}
                  </div>
                </div>

                <div className="flex justify-end items-center gap-2 !pt-4">

                  <div className="d-flex justify-content-between w-full items-center pt-2">
                    <Button
                      onClick={() => {
                        validateFields();
                        setTimeout(() => {
                          if (
                            dynamicFieldsErrors?.every(
                              (item) =>
                                !!Object.values(item)?.every(
                                  (element) => element === ""
                                )
                            )
                          ) {
                            if (!customValidation()) {
                              return;
                            }
                            handleAddFields();
                          }
                        }, 1000);
                      }}
                      className="graybutton"
                      disabled={isLoading}
                      variant="Transaparent"
                    >
                      <FaPlus
                        name="PlusIcon"
                        width={16}
                        height={16}
                        className="mr-2"
                      />{" "}
                      Add more
                    </Button>
                    <div className="d-flex justify-content-end button-space">
                      <Button type="button" className="graybutton" onClick={() => {
                        reset();
                        setIsCopy(null);
                        onClose();
                        setDynamicFieldsErrors([]);
                        setDynamicFields([]);
                        setbankFromIFSCDynamic([]);
                        setShowMerchantInput(false);
                        setisLoading(false);
                      }}>
                        Cancel
                      </Button>
                      {role.includes("add-sms-user") || role.includes("update-sms-user") ? <CustomeButton className="submitbutton" type="submit" isButtonLoading={isLoading}>
                        {data?.id ? role.includes("update-sms-user") ? "Update" : null : role.includes("add-sms-user") ? "Submit" : null}
                      </CustomeButton> : null}
                    </div>
                  </div>

                </div>
              </form>
            </div>
          ) : (
            <>
              <div
                style={{
                  padding: 50,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>
                  <span>Username :- </span>
                  <span className="bold">{isCopy?.user_name}</span>
                </span>
                <span>
                  <span>Password :- </span>
                  <span className="bold">{isCopy?.password}</span>
                </span>
              </div>
              <div className="d-flex justify-content-end pt-2 align-items-center gap-3">
                <CopyToClipboard
                  text={`${isCopy?.user_name} ${isCopy?.password}`}
                >
                  <Button
                    className="graybutton"
                    onClick={() => {
                      onClose();
                      setIsCopy(null);
                      showToaster("Copied");
                      reset();
                    }}
                  >
                    Copy & Close
                  </Button>
                </CopyToClipboard>
              </div>
            </>
          )}
        </div>
      </div>

    </Modal>


  );
};

export default UserModal;
