import React, { useState, useEffect, useRef } from "react";
import "./Home.scss";

// Reactstrap Imports
import { Card, CardHeader, CardBody, Button, Col, Row } from "reactstrap";
import StatsHorizontal from "../../../components/widgets/stats/StatsHorizontal";
import {
  Get_Payout_CR_Data,
  MM_Dashboard_Data,
  MM_Dashboard_Summary,
  Payout_Summary_Get,
  Pg_Summary_Get,
  Summary_Payout,
  Transaction_Summary_Get,
  dashboardDepositChartGet,
  availableBalancegGet,
  dashboardWithdrawalChartGet,
  Payment_Data_Get,
  dashboardDepositChartLast4Get,
  dashboardWithdrawalChartLast4Get,
  dashboardDepositChartBank,
} from "../../../redux/slices/dashboardSlice";

import { useDispatch, useSelector } from "react-redux";
import { DROPDOWN_ALL_VALUE, FILTER, showToaster } from "../../../constant";

// Image
import IntializedIcon from "../../../assets/images/pages/intialized-icon.svg";
import PendingIcon from "../../../assets/images/pages/pending-icon.svg";
import SuccessIcon from "../../../assets/images/pages/success-icon.svg";
import ProcessingIcon from "../../../assets/images/pages/process-icon.svg";
import OpenIcon from "../../../assets/images/pages/open-Icon.svg";
import ExpiredIcon from "../../../assets/images/pages/Expire-Icon.svg";

import ProcessWithIcon from "../../../assets/images/pages/pendingiconwith.svg";
import FailWithIcon from "../../../assets/images/pages/fail-iconwith.svg";
import IntializeWithIcon from "../../../assets/images/pages/process-iconwith.svg";
import LowbalWithIcon from "../../../assets/images/pages/lowbal-iconwith.svg";
import SuccessWithIcon from "../../../assets/images/pages/success-iconwith.svg";
import ProseccingWithIcon from "../../../assets/images/pages/processing-iconwith.svg";
import RecheckWithIcon from "../../../assets/images/pages/recheck-iconwith.svg";
import FrozenIcon from "../../../assets/images/pages/alert-line.svg";
import CancelledIcon from "../../../assets/images/pages/canceledicon.svg";
import OnHoldIcon from "../../../assets/images/pages/onholdicon.svg";
import UnClaimIcon from "../../../assets/images/pages/unClaim.svg";
import notFoundSummary from "../../../assets/images/pages/notFound-summary.svg";

import TimeLineIcon from "../../../assets/images/pages/time-lineicon.svg";
import WithdrawalDownBal from "../../../assets/images/pages/withdraw-downbalance.svg";
import WithdrawalLawbal from "../../../assets/images/pages/withdrawal-lawbal.svg";
import WithdrawalIntialize from "../../../assets/images/pages/withdrawal-intializes.svg";

import Totalpayout from "../../../assets/images/pages/totalpayout.svg";
import Totalcollection from "../../../assets/images/pages/totalcollection.svg";
import Totalbalace from "../../../assets/images/pages/totalbalace.svg";
import Bankbalance from "../../../assets/images/pages/bankbalance.svg";
import { Table, TableCell, TableRow } from "../../../components/Table";
import DropDown from "../../../components/Custom/Forms/DropDown/DropDown";
import {
  dateFormatter,
  onFixed,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import Loader from "../../../components/Custom/Loader";
import BarChart from "../../../components/Custom/Elements/Chart/BarChart";
import DatePicker from "../../../components/Custom/Forms/DatePicker/DatePicker";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import RadialSeparators from "./RadialSeparators";
import { Bank_Available } from "../../../redux/slices/bankTransactionSlice";
import { Merchant_Pay_In_Available_Payment_Get } from "../../../redux/slices/merchantSlice";
import { tranPaymentData } from "./mock";
import ChartDetailsModal from "./ChartDetailsModal";
import ChartDetailsWdModal from "./ChartDetailsWdModal";
import CopyToClipboard from "react-copy-to-clipboard";
import { useAutoRefreshTime, useRole } from "../../../redux/slices/authSlice";

const initialDateFilter = {
  startDate: new Date(),
  endDate: new Date(),
};

const Home = () => {
  const merchantimage = [
    Totalcollection,
    Totalpayout,
    Totalbalace,
    Bankbalance,
  ];

  const transummary = {
    Initialized: IntializedIcon,
    Pending: PendingIcon,
    Processing: ProcessingIcon,
    Success: SuccessIcon,
    Open: OpenIcon,
    Expired: ExpiredIcon,
  };

  const withdraimgummary = {
    Failed: FailWithIcon,
    Initialized: IntializeWithIcon,
    Pending: ProcessWithIcon,
    LOWBAL: LowbalWithIcon,
    Success: SuccessWithIcon,
    Processing: ProseccingWithIcon,
    RECHECK: RecheckWithIcon,
    FROZEN: FrozenIcon,
    Cancelled: CancelledIcon,
    OnHold: OnHoldIcon,
    NotFound: notFoundSummary,
    "Fed Unclaim Txn": UnClaimIcon,
  };
  const withdrawalDashSummary = {
    "Withdrawal LowBal Count": WithdrawalDownBal,
    "Withdrawal Initialized Amount": WithdrawalLawbal,
    "Withdrawal Initialized Count": WithdrawalIntialize,
  };
  const dispatch = useDispatch();
  const {
    transactionSummaryState,
    payoutSummaryState,
    pgSummaryState,
    mmDashboardDataState,
    mmDashboardSummaryState,
    payoutSummarys,
    pgSummaryIsLoading,
    depositChart,
    withdrawalChart,
    depositChartIsLoading,
    withdrawalChartIsLoading,
    availableBalanceState,
    getPaymentDataState,
    payoutLoading,
  } = useSelector((state) => state.dashboard);
  const role = useRole("Dashboard");

  const { merchantPayInAvailablePaymentState } = useSelector(
    (state) => state.merchant
  );
  const [filterCollectionColumns, setFilterCollectionColumns] = useState([]);
  const [filterWithdrawalColumns, setFilterWithdrawalColumns] = useState([]);
  const [filterAvailableColumns, setFilterAvailableColumns] = useState([]);
  const [filterMmColumns, setFilterMmColumns] = useState([]);
  const [availableBal, setAvailableBal] = useState();
  const [refresh, setRefresh] = useState(false);
  const [balanceRefresh, setBalanceRefresh] = useState(false);
  const [withdrawalRefresh, setWithdrawalRefresh] = useState(false);
  const [summaryRefresh, setSummaryRefresh] = useState(false);
  const [isChartDetailsModal, setIsChartDetailsModal] = useState(false);
  const [isChartWDDetailsModal, setIsChartWDDetailsModal] = useState(false);
  const [hourForWd, setHourForWd] = useState();
  const tableRef = useRef(null);
  const autoRefreshTime = useAutoRefreshTime();

  const [chartFilter, setChartFilter] = useState({
    ...initialDateFilter,
    merchantId: DROPDOWN_ALL_VALUE,
    custLevel: DROPDOWN_ALL_VALUE,
    isLast4: false,
    isCall: true,
    isFilter: false,
  });

  const [summeryFilter, setSummeryFilter] = useState({
    ...initialDateFilter,
    merchantId: DROPDOWN_ALL_VALUE,
  });

  const [upiAppSummaryFilter, setUpiAppSummaryFilter] = useState({
    ...initialDateFilter,
    acNo: DROPDOWN_ALL_VALUE,
  });
  const [mmDashboardFilter, setMmDashboardFilter] = useState(initialDateFilter);
  const [isMmLoading, setIsMmLoading] = useState(false);
  const [pgSummeryFilter, setPgSummeryFilter] = useState({
    ...initialDateFilter,
    merchantId: DROPDOWN_ALL_VALUE,
  });

  const levelOptions = [
    { value: "1", label: "Level 1" },
    { value: "2", label: "Level 2" },
    { value: "3", label: "Level 3" },
    { value: "4", label: "Level 4" },
  ];

  const collectionColumns = [
    {
      title: "PG",
      name: "pgName",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <span>
                {data?.pgData?.pgName || ""}
                {data?.pgData?.acId ? `(${data?.pgData?.acId})` : ""}
              </span>
              {data?.pgData?.pgLabel ? (
                <div className="order-singal-div">
                  <p>
                    <span> PG Label : </span>
                    {data?.pgData?.pgLabel}
                  </p>
                </div>
              ) : null}
              {data?.pgData?.bankName ? (
                <div className="order-singal-div">
                  <p>
                    <span> Bank name : </span>
                    {data?.pgData?.bankName}
                  </p>
                </div>
              ) : null}
              {data?.pgData?.acNumber ? (
                <div className="order-singal-div">
                  <p>
                    <span> A/C No : </span>
                    {data?.pgData?.acNumber}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "Total Amount",
      name: "totalAmount",
      Cell: (data) => {
        return <>{`${seperator(+data?.totalAmount)}`}</>;
      },
    },
    {
      title: "Success Count",
      name: "successCount"
    }
  ];

  const withdrawalColumns = [
    {
      title: "PG",
      name: "pgName",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <span>
                {data?.pgData?.pgName || ""}
                {data?.pgData?.acId ? ` (${data?.pgData?.acId})` : ""}
              </span>
              {data?.pgData?.pgLabel ? (
                <div className="order-singal-div">
                  <p>
                    <span> PG Label : </span>
                    {data?.pgData?.pgLabel}
                  </p>
                </div>
              ) : null}
              {data?.pgData?.bankName ? (
                <div className="order-singal-div">
                  <p>
                    <span> Bank name : </span>
                    {data?.pgData?.bankName}
                  </p>
                </div>
              ) : null}
              {data?.pgData?.acNumber ? (
                <div className="order-singal-div">
                  <p>
                    <span> A/C No : </span>
                    {data?.pgData?.acNumber}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "Total Amount",
      name: "totalWithdrawalAmount",
      Cell: (data) => {
        return <>{`${seperator(+data?.totalWithdrawalAmount)}`}</>;
      },
    },
    {
      title: "Success Count",
      name: "successCount"
    }
  ];

  const payoutCRColumns = [
    {
      title: "#",
      name: "id",
    },
    {
      title: "ACCOUNT INFO",
      name: "acNumber",
    },
    {
      title: "PG INFO",
      name: "pgName",
    },
    {
      title: "UTR",
      name: "utrNumber",
    },
    {
      title: "BALANCE",
      name: "addedBal",
      Cell: (data) => {
        return <>{`${seperator(+data?.addedBal)}`}</>;
      },
    },
    {
      title: "MANUAL DEPOSIT",
      name: "createdAtIst",
    },
  ];

  const availableBalanceColumns = [
    {
      title: "PG NAME",
      name: "pg_name",
    },
    {
      title: "MERCHANT ID",
      name: "merchant_id",
    },
    {
      title: "LABEL",
      name: "label",
    },
    {
      title: "AVAILABLE BALANCE",
      name: "available_balance",
      Cell: (data) => {
        return <>{`${seperator(data?.available_balance)}`}</>;
      },
    },
  ];

  const mmColumns = [
    {
      title: "",
      name: "progressbar",
      Cell: (data) => {
        return (
          <div
            className="d-flex align-items-center gap-1 avtarstyle"
            style={{ width: 40, height: 40 }}
          >
            <CircularProgressbarWithChildren
              value={data?.successRate}
              text={`${onFixed(data?.successRate)}`}
              strokeWidth={10}
              styles={buildStyles({
                strokeLinecap: "butt",
                textSize: "24px",
                textColor: "black",
              })}
            >
              <RadialSeparators
                count={36}
                style={{
                  background: "#fff",
                  width: "1px",
                  height: `${10}%`,
                }}
              />
            </CircularProgressbarWithChildren>
          </div>
        );
      },
    },
    {
      title: "CLIENT NAME",
      name: "client_name",
      Cell: (data) => {
        return (
          <div className="d-flex align-items-center gap-1 avtarstyle">
            <span>
              {data?.merchantDatas?.merchantName
                ? data?.merchantDatas?.merchantName
                : "-"}
            </span>
          </div>
        );
      },
    },
    {
      title: "COLLECTION",
      name: "deposit",
      Cell: (data) => {
        return <>{`${seperator(data?.deposit)}`}</>;
      },
    },
    {
      title: "WITHDRAWAL",
      name: "withdrawal",
      Cell: (data) => {
        return <>{`${seperator(data?.withdrawal)}`}</>;
      },
    },
    {
      title: "WITHDRAWAL MANUAL",
      name: "withdrawalManual",
      Cell: (data) => {
        return <>{`${seperator(data?.withdrawalManual)}`}</>;
      },
    },
    {
      title: "WITHDRAWAL BALANCE",
      name: "closingBalance",
      Cell: (data) => {
        return <>{`${seperator(data?.closingBalance?.balance || data?.closingBalance)}`}</>;
      },
    },
    {
      title: "UNSETTLE BALANCE",
      name: "unSettleBalance",
      Cell: (data) => {
        return <>{`${seperator(data?.closingBalance?.unsettleBalance)}`}</>;
      },
    },
    {
      title: "CUR. MIN TICKET",
      name: "minPass",
      Cell: (data) => {
        return <>{`${seperator(data?.minPass, false)}`}</>;
      },
    },
    {
      title: "CUR. MAX TICKET",
      name: "maxPass",
      Cell: (data) => {
        return <>{`${seperator(data?.maxPass, false)}`}</>;
      },
    },
  ];

  useEffect(() => {
    const newCollectionColumns = collectionColumns?.map((item) => item?.name);
    const newWithdrawalColumns = withdrawalColumns?.map((item) => item?.name);
    const newPayoutCRColumns = payoutCRColumns?.map((item) => item?.name);
    const newMmColumns = mmColumns?.map((item) => item?.name);
    const availableFilterColumns = availableBalanceColumns?.map(
      (item) => item?.name
    );
    setFilterCollectionColumns(newCollectionColumns);
    setFilterWithdrawalColumns(newWithdrawalColumns);
    setFilterMmColumns(newMmColumns);
    setFilterAvailableColumns(availableFilterColumns);
  }, []);

  // GET CHART DATA
  const onGetChart = async () => {
    const { isCall, isLast4, startDate, endDate, isFilter, ...rest } =
      chartFilter;
    try {
      if (role.includes("deposit-chart") || role.includes("withdrawal-chart")) {
        if (isLast4 && isFilter) {
          const payload = {
            filter_data: rest,
          };

          const promises = [];

          if (role.includes("deposit-chart")) {
            promises.push(
              dispatch(dashboardDepositChartLast4Get(payload)).unwrap()
            );
          }

          if (role.includes("withdrawal-chart")) {
            promises.push(
              dispatch(dashboardWithdrawalChartLast4Get(payload)).unwrap()
            );
          }

          await Promise.all(promises).then((allRes) => {
            setChartFilter({
              ...chartFilter,
              isCall: false,
              isFilter: false,
            });
            if (allRes) {
              showToaster(
                !allRes?.[0]?.status && !allRes?.[1]?.status
                  ? allRes?.[0]?.message
                  : allRes?.[0]?.status
                  ? allRes?.[0]?.message
                  : allRes?.[1]?.message,
                !allRes?.[0]?.status && !allRes?.[1]?.status
                  ? "Warning"
                  : "Success"
              );
            }
          });
        } else {
          const payload = {
            filter_data: rest,
            startDate: dateFormatter(startDate, "start"),
            endDate: dateFormatter(endDate, "end", startDate),
          };
          if (isCall) {
            const promises = [];

            if (role.includes("deposit-chart")) {
              promises.push(
                dispatch(dashboardDepositChartGet(payload)).unwrap()
              );
            }

            if (role.includes("withdrawal-chart")) {
              promises.push(
                dispatch(dashboardWithdrawalChartGet(payload)).unwrap()
              );
            }
            await Promise.all(promises).then((allRes) => {
              if (isFilter) {
                if (allRes) {
                  showToaster(
                    !allRes?.[0]?.status && !allRes?.[1]?.status
                      ? allRes?.[0]?.message
                      : allRes?.[0]?.status
                      ? allRes?.[0]?.message
                      : allRes?.[1]?.message,
                    !allRes?.[0]?.status && !allRes?.[1]?.status
                      ? "Warning"
                      : "Success"
                  );
                }
              }
              setChartFilter({
                ...chartFilter,
                isCall: false,
                isFilter: false,
              });
            });
          }
        }
      }
    } catch (err) {}
  };

  const onGetSummery = async (isToast = false) => {
    try {
      const { startDate, endDate, ...rest } = summeryFilter;
      const payload = {
        startDate: dateFormatter(startDate, "start"),
        endDate: dateFormatter(endDate, "end", startDate),
        ...rest,
      };

      const promises = [];
      if (role?.includes("deposit-summary")) {
        promises.push(dispatch(Transaction_Summary_Get(payload)).unwrap());
      }

      if (role?.includes("withdrawal-summary")) {
        promises.push(dispatch(Payout_Summary_Get(payload)).unwrap());
      }

      await Promise.all(promises).then((res) => {
        if (isToast) {
          showToaster(
            res[0]?.status ? res[0]?.message : res[1]?.message,
            !res[0]?.status && !res[1]?.status ? "Warning" : "Success"
          );
        }
      });
    } catch (err) {}
  };

  const onGetWithdrawalDashboardSummery = async (isToast = false) => {
    try {
      const res = await dispatch(Summary_Payout()).unwrap();
      if (isToast) {
        responseToaster(res);
      }
    } catch (err) {}
  };

  const onUpiAppSummary = async (isToast = false) => {
    try {
      const { startDate, endDate, ...rest } = upiAppSummaryFilter;
      const payload = {
        filter_data: {
          startDate: dateFormatter(startDate, "start"),
          endDate: dateFormatter(endDate, "end", startDate),
          ...rest,
        },
      };
      const res = await dispatch(Payment_Data_Get(payload)).unwrap();
      if (isToast) {
        responseToaster(res);
      }
    } catch (err) {}
  };

  // Withdraw CR Data
  const onGetPayoutCR = async () => {
    try {
      const { startDate, endDate } = initialDateFilter;
      const payload = {
        filter_data: {
          startDate: dateFormatter(startDate, "start"),
          endDate: dateFormatter(endDate, "end", startDate),
        },
        page_no: 1,
        limit: 100,
      };
      await dispatch(Get_Payout_CR_Data(payload)).unwrap();
    } catch (err) {}
  };

  // const prepareAvailableData = () => {
  //   const dd = availableBalanceState?.data?.[availableBal] || [];
  //   console.log(availableBalanceState,availableBal);
  //   let total = 0;
  //   dd?.forEach((item) => {
  //     total += +item?.available_balance || 0;
  //   });
  //   return { data: dd, total: total };
  // };

  const prepareAvailableData = () => {
    const newData = [];
    let total = 0;

    Object.entries(availableBalanceState?.data || {})?.forEach(
      ([key, item]) => {
        newData.push(...(item || []));
        item?.forEach((subItem) => {
          total += +subItem?.available_balance || 0;
        });
      }
    );

    return { data: newData, total: total };
  };

  // PAYIN - WITHDRAW DATA
  const onGetMmDashboardSummery = async (loading = false, isToast = false) => {
    try {
      if (loading) {
        setIsMmLoading(true);
      }
      const { startDate, endDate } = mmDashboardFilter;
      const payload = {
        startDate: dateFormatter(startDate, "start"),
        endDate: dateFormatter(endDate, "end", startDate),
      };
      const res = await dispatch(MM_Dashboard_Data(payload)).unwrap();
      if (isToast) {
        responseToaster(res);
      }
      setIsMmLoading(false);
    } catch (err) {
      setIsMmLoading(false);
    }
  };

  const onGetMDashboard = async () => {
    try {
      await dispatch(MM_Dashboard_Summary()).unwrap();
    } catch (err) {}
  };
  const onGetAvailableBalance = async (loading = false) => {
    try {
      if (loading) {
        setIsMmLoading(true);
      }
      await dispatch(availableBalancegGet()).unwrap();
      setIsMmLoading(false);
    } catch (err) {
      setIsMmLoading(false);
    }
  };

  const onGetPgSummery = async (isToast = false) => {
    try {
      const { startDate, endDate, merchantId } = pgSummeryFilter;
      const payload = {
        merchantId,
        startDate: dateFormatter(startDate, "start"),
        endDate: dateFormatter(endDate, "end", startDate),
      };
      const res = await dispatch(Pg_Summary_Get(payload)).unwrap();
      if (isToast) {
        showToaster(
          res?.message,
          res?.data?.collection || res?.data?.withdrawal ? "Success" : "Warning"
        );
      }
    } catch (err) {}
  };

  const bank_Available = async () => {
    try {
      const payload = {};
      await dispatch(Bank_Available(payload)).unwrap();
    } catch (err) {}
  };

  const onGetData = async () => {
    try {
      const payload = {};
      await dispatch(Merchant_Pay_In_Available_Payment_Get(payload)).unwrap();
    } catch (err) {}
  };

  useEffect(() => {
    if (role.includes("upi-app")) {
      bank_Available();
    }
    if (role.includes("merchant-deposit-withdrawal-summary")) {
      onGetMmDashboardSummery(true);
    }
    if (
      role.includes("deposit-summary") ||
      role.includes("withdrawal-summary")
    ) {
      onGetSummery();
    }
    if (role.includes("withdrawal-dashboard-summary")) {
      onGetWithdrawalDashboardSummery();
    }
    if (role.includes("dashboard-summary")) {
      onGetMDashboard();
    }
    if (role.includes("pg-summary")) {
      onGetPgSummery();
    }
    if (role.includes("available-balance")) {
      onGetAvailableBalance(true);
    }
    if (role.includes("upi-app-summary")) {
      onUpiAppSummary();
    }
  }, []);

  useEffect(() => {
    if (chartFilter?.isFilter || chartFilter?.isCall) {
      onGetChart();
    }
  }, [chartFilter]);

  useEffect(() => {
    const IntervalClick = setInterval(() => {
      if (refresh) {
        onGetMmDashboardSummery(true);
      }
    }, autoRefreshTime);
    return () => clearInterval(IntervalClick);
  }, [refresh, mmDashboardFilter]);

  useEffect(() => {
    const IntervalClick = setInterval(() => {
      if (withdrawalRefresh) {
        onGetWithdrawalDashboardSummery();
      }
    }, autoRefreshTime);
    return () => clearInterval(IntervalClick);
  }, [withdrawalRefresh]);

  useEffect(() => {
    const IntervalClick = setInterval(() => {
      if (summaryRefresh) {
        onGetSummery();
      }
    }, autoRefreshTime);
    return () => clearInterval(IntervalClick);
  }, [summaryRefresh, summeryFilter]);

  useEffect(() => {
    const IntervalClick = setInterval(() => {
      if (balanceRefresh) {
        onGetAvailableBalance(true);
      }
    }, autoRefreshTime);
    return () => clearInterval(IntervalClick);
  }, [balanceRefresh]);

  useEffect(() => {
    if (role.includes("upi-app-summary")) {
      onGetPayoutCR();
    }
  }, []);

  useEffect(() => {
    if (role.includes("upi-app-summary")) {
      onGetData();
    }
  }, []);

  const getClientOptions = () => {
    const options = depositChart?.merchantList
      ?.slice()
      ?.sort((a, b) => a?.merchant_name?.localeCompare(b?.merchant_name))
      ?.map((val) => ({
        value: val?.merchant_id,
        label: val?.merchant_name,
      }));
    return options || [];
  };

  const prepareBanklistOptions = () => {
    const opttions = merchantPayInAvailablePaymentState?.data?.manual
      ?.slice()
      ?.sort((a, b) => a?.bankName?.localeCompare(b?.bankName))
      ?.map((val) => ({
        value: val?.acNumber,
        label: `${val?.bankName} - ${val?.pgLabel} - ${val?.acNumber}`,
      }));
    return opttions || [];
  };

  const dashboardDepositChartBankGet = async (chartHours) => {
    const { isCall, isLast4, startDate, endDate, ...rest } = chartFilter;
    try {
      const payload = {
        filter_data: rest,
        startDate: dateFormatter(startDate, "start"),
        endDate: dateFormatter(endDate, "end", startDate),
        chartHours,
      };
      await dispatch(dashboardDepositChartBank(payload)).unwrap();
    } catch (err) {}
  };

  const depositOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
      },
      title: {
        display: false,
        text: "Deposit Chart",
        align: "start",
        font: {
          size: 16,
        },
        padding: {
          bottom: 15,
          left: 10,
          top: 20,
        },
      },
    },
    onClick: (i, elements) => {
      if (elements[0]?.datasetIndex == 3) {
        setIsChartDetailsModal(!isChartDetailsModal);
        dashboardDepositChartBankGet(elements[0]?.index);
      }
    },
  };

  const withdrawalOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
      },
      title: {
        display: false,
        text: "Withdrawal Chart",
        align: "start",
        font: {
          size: 16,
        },
        padding: {
          bottom: 15,
          left: 10,
          top: 20,
        },
      },
    },
    onClick: (i, elements) => {
      if (elements[0]?.datasetIndex == 1) {
        setIsChartWDDetailsModal(!isChartWDDetailsModal);
        setHourForWd(elements[0]?.index);
      }
    },
  };

  // const availableBalance = Object?.entries(
  //   availableBalanceState?.data || []
  // )?.map(([key]) => {
  //   const Ff = {
  //     value: key,
  //     label: key,
  //   };
  //   return Ff;
  // });

  // useEffect(() => {
  //   if (
  //     !availableBal ||
  //     !availableBalance?.find((item) => item?.value === availableBal)
  //   ) {
  //     setAvailableBal(availableBalance?.[0]?.value);
  //   }
  // }, [availableBalanceState]);

  const prepareCopyContent = (data, total) => {
    let temp = [];

    if (data && data.length > 0) {
      temp = data.map((dt, index) => {
        let content = "";

        if (dt?.pgData?.pgName) {
          content += `${dt.pgData.pgName} `;
        }
        if (dt?.pgData?.acId) {
          content += `(${dt.pgData.acId})\n`;
        }
        if (dt?.pgData?.pgLabel) {
          content += `PG Label : ${dt.pgData.pgLabel}\n`;
        }
        if (dt?.pgData?.bankName) {
          content += `Bank Name : ${dt.pgData.bankName}\n`;
        }
        if (dt?.pgData?.acNumber) {
          content += `A/C No : ${dt.pgData.acNumber}\n`;
        }
        if (dt?.totalAmount) {
          content += `Total Amount : ${seperator(+dt.totalAmount)}\n`;
        }

        return `${content}\n`;
      });
    }

    temp.push(`Total : ${seperator(total)}`);
    const copyContent = temp.join("");
    return copyContent;
  };
  const prepareCopyContent1 = (data, total) => {
    let temp = [];

    if (data && data.length > 0) {
      temp = data.map((dt, index) => {
        let content = "";

        if (dt?.pgData?.pgName) {
          content += `${dt.pgData.pgName} `;
        }
        if (dt?.pgData?.acId) {
          content += `(${dt.pgData.acId})\n`;
        }
        if (dt?.pgData?.pgLabel) {
          content += `PG Label : ${dt.pgData.pgLabel}\n`;
        }
        if (dt?.pgData?.bankName) {
          content += `Bank Name : ${dt.pgData.bankName}\n`;
        }
        if (dt?.pgData?.acNumber) {
          content += `A/C No : ${dt.pgData.acNumber}\n`;
        }
        if (dt?.totalWithdrawalAmount) {
          content += `Total Amount : ${seperator(+dt.totalWithdrawalAmount)}\n`;
        }

        return `${content}\n`;
      });
    }

    temp.push(`Total : ${seperator(total)}`);
    const copyContent = temp.join("");
    return copyContent;
  };

  return (
    <div className="home-main-div">
      {role.includes("dashboard-summary") ? (
        <>
          <Card className="merchant-management-card">
            {mmDashboardSummaryState?.status ? (
              <>
                <CardHeader style={{ display: "unset" }}>
                  {/* <div className="d-flex align-items-center w-100 justify-content-between mb-1">
            <h4 className="main-title">
              <b>Merchant Management Dashboard</b>
            </h4>
          </div> */}
                  <div>
                    {mmDashboardSummaryState?.status == true &&
                    mmDashboardSummaryState?.data?.length ? (
                      <div className="transaction-summary-row">
                        {mmDashboardSummaryState?.data?.length &&
                          mmDashboardSummaryState?.data?.map((val, index) => {
                            const concatenatedString = val?.value
                              ?.toString()
                              ?.split(",")
                              ?.join("");
                            return (
                              <div className="my-1" key={index}>
                                <StatsHorizontal
                                  className="fontstyle greentext"
                                  color="primary"
                                  index={index}
                                  image={merchantimage[index]}
                                  statTitle={seperator(concatenatedString)}
                                  renderStats={
                                    <h5
                                      className="fw-bolder mb-0"
                                      title={concatenatedString}
                                    >
                                      {val?.name}
                                    </h5>
                                  }
                                />
                              </div>
                            );
                          })}
                      </div>
                    ) : null}
                  </div>
                </CardHeader>
              </>
            ) : null}
          </Card>
        </>
      ) : null}

      {role.includes("deposit-chart") || role.includes("withdrawal-chart") ? (
        <>
          <Card>
            <CardHeader className="d-flex flex-md-row flex-column justify-content-md-between justify-content-start align-items-start">
              {role.includes("deposit-chart") ? (
                <>
                  <h4 className="main-title">
                    <b>Deposit Chart</b>
                  </h4>
                </>
              ) : null}
              <div className="my-xl-auto right-content align-items-center">
                <div
                  className="d-flex align-items-center mt-md-0 mt-1"
                  style={{ flexWrap: "wrap" }}
                >
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-end inputgap">
                        <Button
                          className={chartFilter?.isLast4 ? "" : "filterbtn"}
                          color={chartFilter?.isLast4 ? "primary" : ""}
                          type="button"
                          onClick={() =>
                            setChartFilter({
                              ...chartFilter,
                              isCall: true,
                              isLast4: !chartFilter?.isLast4,
                              isFilter: true,
                            })
                          }
                        >
                          {chartFilter?.isLast4
                            ? "Set Default"
                            : "Set Last 4 Hour"}
                        </Button>
                        <DropDown
                          label="Client"
                          options={getClientOptions()}
                          isDefaultOption={DROPDOWN_ALL_VALUE}
                          value={chartFilter?.merchantId}
                          onChange={(selectedOption) => {
                            setChartFilter({
                              ...chartFilter,
                              merchantId: selectedOption?.value,
                              isCall: false,
                            });
                          }}
                        />
                        <DropDown
                          label="Level"
                          options={levelOptions}
                          value={chartFilter?.custLevel}
                          isDefaultOption={DROPDOWN_ALL_VALUE}
                          onChange={(selectedOption) => {
                            setChartFilter({
                              ...chartFilter,
                              custLevel: selectedOption?.value,
                              isCall: false,
                            });
                          }}
                        />
                        <DatePicker
                          label="Date"
                          maxDate={new Date()}
                          placeholder="Select Date"
                          selectsRange
                          startDate={chartFilter?.startDate}
                          endDate={chartFilter?.endDate}
                          onChange={(selectedDate) => {
                            setChartFilter({
                              ...chartFilter,
                              startDate: selectedDate?.[0] || null,
                              endDate: selectedDate?.[1] || null,
                              isCall: false,
                            });
                          }}
                        />
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => {
                            setChartFilter({
                              ...chartFilter,
                              isCall: true,
                              isFilter: true,
                            });
                          }}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {depositChartIsLoading || withdrawalChartIsLoading ? (
                <div
                  style={{
                    height: 600,
                  }}
                  className="flex-center"
                >
                  <Loader />
                </div>
              ) : (
                <div className="chartcss">
                  {role.includes("deposit-chart") ? (
                    <BarChart
                      data={depositChart?.data}
                      options={depositOptions}
                      isLoading={depositChartIsLoading}
                    />
                  ) : null}
                  {role.includes("withdrawal-chart") ? (
                    <>
                      <h4 className="main-title">
                        <b>Withdrawal Chart</b>
                      </h4>
                      <BarChart
                        data={withdrawalChart?.data}
                        options={withdrawalOptions}
                        isLoading={withdrawalChartIsLoading}
                      />
                    </>
                  ) : null}
                </div>
              )}
            </CardBody>
          </Card>
        </>
      ) : null}

      {role.includes("deposit-summary") ||
      role.includes("withdrawal-summary") ? (
        <>
          <Card>
            <CardHeader className="d-flex flex-column justify-content-md-between justify-content-start align-items-start">
              <div className="d-flex justify-content-between w-100">
                {role.includes("deposit-summary") ? (
                  <>
                    <div className=" ">
                      <h5 className="tab-table-heading">Deposit Summary</h5>
                    </div>
                  </>
                ) : null}
                <div className="d-flex button-space ">
                  <Button
                    type="button"
                    className={`${summaryRefresh ? "" : "filterbtn"} gap`}
                    color={summaryRefresh ? "primary" : ""}
                    onClick={() => setSummaryRefresh(!summaryRefresh)}
                    title="Auto refresh"
                  >
                    {summaryRefresh ? (
                      <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                    ) : (
                      <i className="fa-solid fa-arrows-rotate"></i>
                    )}
                    Auto Refresh
                  </Button>
                  <DropDown
                    options={getClientOptions()}
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    value={summeryFilter?.merchantId}
                    onChange={(selectedOption) => {
                      setSummeryFilter({
                        ...summeryFilter,
                        merchantId: selectedOption?.value,
                      });
                    }}
                  />
                  <DatePicker
                    maxDate={new Date()}
                    placeholder="Select Date"
                    selectsRange
                    startDate={summeryFilter?.startDate}
                    endDate={summeryFilter?.endDate}
                    onChange={(selectedDate) => {
                      setSummeryFilter({
                        ...summeryFilter,
                        startDate: selectedDate?.[0] || null,
                        endDate: selectedDate?.[1] || null,
                      });
                    }}
                  />
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => onGetSummery(true)}
                  >
                    Apply
                  </Button>
                </div>
              </div>
              <div className=" button-space withcss pt-1">
                {transactionSummaryState?.data?.map((sale, index) => {
                  const saletotalamount = sale?.totalAmt
                    ?.toString()
                    ?.split(",")
                    .join("");
                  return (
                    <div
                      className={` ${
                        index === 3
                          ? ""
                          : index === 4
                          ? ""
                          : index === 5
                          ? ""
                          : ""
                      }`}
                      key={index}
                    >
                      <StatsHorizontal
                        className="fontstyle imgstyle"
                        color="primary"
                        index={index}
                        image={transummary?.[sale?.name]}
                        count_per={`~ ${onFixed(sale?.txnCountPer)}%`}
                        count={sale?.txnCount}
                        renderStats={
                          <h5 className="fw-bolder" title={saletotalamount}>
                            {sale?.name} {`(${sale?.txnCount})`}
                          </h5>
                        }
                        statTitle={seperator(saletotalamount)}
                      />
                    </div>
                  );
                })}
              </div>
              {role.includes("withdrawal-summary") ? (
                <>
                  <div className="d-flex right-content align-items-center ">
                    <h5 className="tab-table-heading py-1">
                      Withdrawal Summary
                    </h5>
                  </div>
                  <div className=" button-space  withcss">
                    {payoutSummaryState?.data?.map((val, index) => {
                      const concatenatedString = val?.totalAmt
                        ?.toString()
                        ?.split(",")
                        ?.join("");
                      return (
                        <div key={index}>
                          <StatsHorizontal
                            className="fontstyle withdrawimg"
                            color="primary"
                            image={withdraimgummary?.[val?.name]}
                            statTitle={seperator(concatenatedString)}
                            count_per={`~ ${onFixed(val?.txnCountPer)}%`}
                            count={val?.txnCount}
                            renderStats={
                              <h5 className="fw-bolder" title={val?.totalAmt}>
                                {val?.name} {`(${val?.txnCount})`}
                              </h5>
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : null}
            </CardHeader>
          </Card>
        </>
      ) : null}

      {role.includes("upi-app-summary") ? (
        <>
          <Card>
            <CardHeader className="d-flex flex-column justify-content-md-between justify-content-start align-items-start">
              <div className="d-flex justify-content-between w-100">
                <div className=" ">
                  <h5 className="tab-table-heading">UPI App Summary</h5>
                </div>
                <div className="d-flex button-space ">
                  <DropDown
                    options={prepareBanklistOptions()}
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    value={upiAppSummaryFilter?.acNo}
                    onChange={(selectedOption) => {
                      setUpiAppSummaryFilter({
                        ...upiAppSummaryFilter,
                        acNo: selectedOption?.value,
                      });
                    }}
                  />
                  <DatePicker
                    maxDate={new Date()}
                    placeholder="Select Date"
                    selectsRange
                    startDate={upiAppSummaryFilter?.startDate}
                    endDate={upiAppSummaryFilter?.endDate}
                    onChange={(selectedDate) => {
                      setUpiAppSummaryFilter({
                        ...upiAppSummaryFilter,
                        startDate: selectedDate?.[0] || null,
                        endDate: selectedDate?.[1] || null,
                      });
                    }}
                  />
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => onUpiAppSummary(true)}
                  >
                    Apply
                  </Button>
                </div>
              </div>
              <div className=" button-space withcssupi pt-1">
                {getPaymentDataState?.data
                  ? getPaymentDataState?.data?.map((sale, index) => {
                      return (
                        <div
                          className={` ${
                            index === 4
                              ? ""
                              : index === 4
                              ? ""
                              : index === 5
                              ? ""
                              : ""
                          }`}
                          key={index}
                        >
                          <StatsHorizontal
                            className="fontstyle imgstyle"
                            color="primary"
                            index={index}
                            image={tranPaymentData?.[sale?.payment_method]}
                            count_per={`~ ${onFixed(sale?.percentage_count)}%`}
                            count={sale?.payment_method}
                            statTitle={seperator(sale?.total_amount)}
                            renderStats={
                              <h5
                                className="fw-bolder"
                                title={sale?.percentage_count}
                              >
                                {sale?.payment_method}
                              </h5>
                            }
                          />
                        </div>
                      );
                    })
                  : null}
              </div>
            </CardHeader>
          </Card>
        </>
      ) : null}

      {role.includes("withdrawal-dashboard-summary") ? (
        <>
          <Card
            className={`${
              withdrawalRefresh && payoutLoading ? "opacity-25" : ""
            }`}
          >
            <CardHeader className="d-flex flex-column justify-content-md-between justify-content-start align-items-start">
              <div className="d-flex justify-content-between w-100">
                <div className=" ">
                  <h5 className="tab-table-heading">Withdrawal Dashboard</h5>
                </div>
                <div className="d-flex button-space ">
                  <Button
                    type="button"
                    className={`${withdrawalRefresh ? "" : "filterbtn"} gap`}
                    color={withdrawalRefresh ? "primary" : ""}
                    onClick={() => setWithdrawalRefresh(!withdrawalRefresh)}
                    title="Auto refresh"
                  >
                    {withdrawalRefresh ? (
                      <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                    ) : (
                      <i className="fa-solid fa-arrows-rotate"></i>
                    )}
                    Auto Refresh
                  </Button>
                </div>
              </div>
              <div className="withdashalign button-space w-100 pt-1">
                {payoutSummarys?.status == true && (
                  <>
                    {payoutSummarys?.firstSummary?.map((val, index) => {
                      const value =
                        val?.name === "Withdrawal Initialized Amount"
                          ? seperator(+val?.value)
                          : val?.value;
                      return (
                        <div
                          className=""
                          key={index}
                          xl="4"
                          lg="6"
                          md="6"
                          title={typeof val?.value === "number" ? value : null}
                        >
                          <StatsHorizontal
                            className="fontstyle timelimeimg"
                            color="primary"
                            image={withdrawalDashSummary?.[val?.name]}
                            statTitle={value}
                            renderStats={
                              <h5 className="fw-bolder mb-75">{val?.name}</h5>
                            }
                          />
                        </div>
                      );
                    })}
                    {payoutSummarys?.secondSummary?.map((val, index) => {
                      return (
                        <div
                          className=" "
                          xl="4"
                          lg="6"
                          md="6"
                          key={index}
                          title={
                            typeof val?.value == "number" ? val?.value : null
                          }
                        >
                          <StatsHorizontal
                            className="fontstyle timelimeimg"
                            color="primary"
                            image={TimeLineIcon}
                            statTitle={val?.value}
                            renderStats={
                              <h5 className="fw-bolder mb-75">{val?.name}</h5>
                            }
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </CardHeader>
          </Card>
        </>
      ) : null}

      {role.includes("merchant-deposit-withdrawal-summary") ? (
        <>
          <Card className={`${isMmLoading && refresh ? "opacity-25" : ""}`}>
            <CardHeader className="justify-content-between">
              <h5 className="tab-table-heading">
                Merchant Deposit/Withdrawal Summary
              </h5>
              <div className="d-flex button-space">
                <div className="mb-xl-0 d-flex align-items-center">
                  <Button
                    type="button"
                    className={`${refresh ? "" : "filterbtn"} gap`}
                    color={refresh ? "primary" : ""}
                    onClick={() => setRefresh(!refresh)}
                    title="Auto refresh"
                  >
                    {refresh ? (
                      <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                    ) : (
                      <i className="fa-solid fa-arrows-rotate"></i>
                    )}
                    Auto Refresh
                  </Button>
                </div>
                <DatePicker
                  maxDate={new Date()}
                  placeholder="Select Date"
                  selectsRange
                  startDate={mmDashboardFilter?.startDate}
                  endDate={mmDashboardFilter?.endDate}
                  onChange={(selectedDate) => {
                    setMmDashboardFilter({
                      ...mmDashboardFilter,
                      startDate: selectedDate?.[0] || null,
                      endDate: selectedDate?.[1] || null,
                    });
                  }}
                />
                <Button
                  color="primary"
                  type="button"
                  onClick={() => onGetMmDashboardSummery(true, true)}
                >
                  Apply
                </Button>
              </div>
            </CardHeader>
            <Table
              columns={mmColumns}
              filterColumns={filterMmColumns}
              isLoading={isMmLoading /*  && !mmDashboardFilter */}
              data={mmDashboardDataState?.data || []}
              isData={!!mmDashboardDataState?.data?.length}
              isExpandable={false}
            >
              {mmDashboardDataState?.data?.map((item) => {
                return (
                  <TableRow
                    isExpandable={false}
                    columns={mmColumns}
                    item={item}
                    filterColumns={filterMmColumns}
                  />
                );
              })}
            </Table>
          </Card>
        </>
      ) : null}

      {role.includes("pg-summary") ? (
        <>
          <Card>
            <CardHeader className="d-block">
              <div className="d-flex justify-content-between">
                <h4 className="main-title">
                  <b>PG Summary</b>
                </h4>
                <div className="d-flex align-items-end button-space">
                  <DatePicker
                    maxDate={new Date()}
                    placeholder="Select Date"
                    selectsRange
                    startDate={pgSummeryFilter?.startDate}
                    endDate={pgSummeryFilter?.endDate}
                    onChange={(selectedDate) => {
                      setPgSummeryFilter({
                        ...pgSummeryFilter,
                        startDate: selectedDate?.[0] || null,
                        endDate: selectedDate?.[1] || null,
                      });
                    }}
                  />

                  <DropDown
                    options={getClientOptions()}
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    value={pgSummeryFilter?.merchantId}
                    // control={control}
                    onChange={(selectedOption) => {
                      setPgSummeryFilter({
                        ...pgSummeryFilter,
                        merchantId: selectedOption?.value,
                        isCall: false,
                      });
                    }}
                  />
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => onGetPgSummery(true)}
                  >
                    Apply
                  </Button>
                </div>
              </div>
              <Row>
                <Col lg={6} md={12} className="right-content autometa-sec">
                  <div className="py-1 d-flex justify-content-between align-items-center ">
                    <h5 className="tab-table-heading">PG Collection</h5>
                    <CopyToClipboard
                      text={prepareCopyContent(
                        pgSummaryState?.data?.collection,
                        pgSummaryState?.data?.totalCollectionAmt
                      )}
                    >
                      <Button
                        className={
                          !pgSummaryState?.data?.collection?.length
                            ? "graybutton"
                            : "submitTransbutton"
                        }
                        disabled={!pgSummaryState?.data?.collection?.length}
                        onClick={(e) => {
                          showToaster("Copied");
                        }}
                      >
                        Copy
                      </Button>
                    </CopyToClipboard>
                  </div>
                  <Table
                    columns={collectionColumns}
                    isLoading={pgSummaryIsLoading}
                    filterColumns={filterCollectionColumns}
                    data={pgSummaryState?.data?.collection || []}
                    isData={!!pgSummaryState?.data?.collection?.length}
                    isExpandable={false}
                    tableBodyRef={tableRef}
                  >
                    {pgSummaryState?.data?.collection?.map((item) => {
                      return (
                        <TableRow
                          columns={collectionColumns}
                          filterColumns={filterCollectionColumns}
                          isExpandable={false}
                          item={item}
                        />
                      );
                    })}
                    <tr className="lasttablerow">
                      <TableCell>Total</TableCell>
                      <TableCell>
                        {seperator(pgSummaryState?.data?.totalCollectionAmt)}
                      </TableCell>
                      <TableCell>
                        {pgSummaryState?.data?.totalCollectionCount}
                      </TableCell>
                    </tr>
                  </Table>
                </Col>
                <Col lg={6} md={12} className="right-content ">
                  <div className="py-1 d-flex justify-content-between align-items-center ">
                    <h5 className="tab-table-heading">Withdrawal Summary</h5>
                    <CopyToClipboard
                      text={prepareCopyContent1(
                        pgSummaryState?.data?.withdrawal,
                        pgSummaryState?.data?.totalWithdrawalAmt
                      )}
                    >
                      <Button
                        className={
                          !pgSummaryState?.data?.withdrawal?.length
                            ? "graybutton"
                            : "submitTransbutton"
                        }
                        disabled={!pgSummaryState?.data?.withdrawal?.length}
                        onClick={(e) => {
                          showToaster("Copied");
                        }}
                      >
                        Copy
                      </Button>
                    </CopyToClipboard>
                  </div>
                  <Table
                    filterColumns={filterWithdrawalColumns}
                    isLoading={pgSummaryIsLoading}
                    data={pgSummaryState?.data?.withdrawal || []}
                    isData={!!pgSummaryState?.data?.withdrawal?.length}
                    columns={withdrawalColumns}
                    isExpandable={false}
                  >
                    {pgSummaryState?.data?.withdrawal?.map((item) => {
                      return (
                        <TableRow
                          filterColumns={filterWithdrawalColumns}
                          columns={withdrawalColumns}
                          isExpandable={false}
                          item={item}
                        />
                      );
                    })}
                    <tr className="lasttablerow">
                      <TableCell>Total</TableCell>
                      <TableCell>
                        {seperator(pgSummaryState?.data?.totalWithdrawalAmt)}
                      </TableCell>
                      <TableCell>
                        {pgSummaryState?.data?.totalWithdrawalCount}
                      </TableCell>
                    </tr>
                  </Table>
                </Col>
              </Row>
            </CardHeader>
          </Card>
        </>
      ) : null}

      {role.includes("available-balance") ? (
        <>
          <div className="row">
            <div
              className={`col-lg-12  ${
                isMmLoading && balanceRefresh ? "opacity-25" : ""
              }`}
            >
              <Card>
                <div className="p-1 d-flex justify-content-between align-items-center">
                  <h5 className="tab-table-heading">Available Balance</h5>
                  <div className="d-flex justify-content-between align-items-center button-space">
                    {/* <DropDown
                      id="merchantId"
                      name="merchantId"
                      options={availableBalance}
                      value={availableBal}
                      onChange={(selectedOption) =>
                        setAvailableBal(selectedOption.value)
                      }
                    /> */}
                    <div className="mb-xl-0 d-flex align-items-center">
                      <Button
                        type="button"
                        className="filterbtn"
                        onClick={() => setBalanceRefresh(!balanceRefresh)}
                        title="Auto refresh"
                      >
                        {balanceRefresh ? (
                          <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                        ) : (
                          <i className="fa-solid fa-arrows-rotate"></i>
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
                <Table
                  columns={availableBalanceColumns}
                  filterColumns={filterAvailableColumns}
                  isLoading={isMmLoading && !balanceRefresh}
                  data={prepareAvailableData()?.data || []}
                  isData={!!prepareAvailableData()?.data?.length}
                  isExpandable={false}
                >
                  {prepareAvailableData()?.data?.map((item) => {
                    return (
                      <TableRow
                        columns={availableBalanceColumns}
                        item={item}
                        filterColumns={filterAvailableColumns}
                        isExpandable={false}
                      ></TableRow>
                    );
                  })}
                  <tr className="lasttablerow">
                    <TableCell>Total</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {seperator(prepareAvailableData()?.total)}
                    </TableCell>
                  </tr>
                </Table>
              </Card>
            </div>
            <ChartDetailsModal
              isOpen={isChartDetailsModal}
              onHide={() => setIsChartDetailsModal(!isChartDetailsModal)}
            />
            <ChartDetailsWdModal
              isOpen={isChartWDDetailsModal}
              onHide={() => {
                setIsChartWDDetailsModal(!isChartWDDetailsModal);
                setHourForWd();
              }}
              chartFilter={chartFilter}
              hourForWd={hourForWd}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};
export default Home;
