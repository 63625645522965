export const prepareMerchantFormData = (currentItem) => {
  if (!currentItem) {
    return null;
  }
  const timeString = currentItem?.dailyAt;
  let dailyAt = null;
  if (timeString && typeof timeString === "string" && timeString?.includes?.(":")) {
    const [hours, minutes, seconds] = timeString?.split?.(":")?.map?.(Number);
    dailyAt = new Date();
    dailyAt?.setHours?.(hours || 0, minutes || 0, seconds || 0, 0);
  }

  return {
    merchantName: currentItem?.merchantName,
    merchantId: currentItem?.merchantId,
    merchantEmail: currentItem?.merchantEmail,
    depositEnable: currentItem?.deposit_availability ? 1 : 0,
    isVerifyUpiFirst: currentItem?.isVerifyUpiFirst ? 1 : 0,
    isFlowV2: currentItem?.isFlowV2 ? 1 : 0,
    depositManualFees: +currentItem?.deposit_manual_fees ?? "",
    depositNotify: currentItem?.deposit_notify ?? "",
    depositAssociateFees: +currentItem?.deposit_astFees ?? "",
    depositAutoFees: +currentItem?.deposit_auto_fees ?? "",
    withdrawalEnable: currentItem?.withdrawal_availability ? 1 : 0,
    IsEnablewithdrawalBalanceCheck: currentItem?.check_withdrawal_eligibility
      ? 1
      : 0,
    withdrawalWebhook: currentItem?.withdrawal_Notify ?? "",
    IsDashboardwithdrawalEnable:
      +currentItem?.enable_dashboard_withdrawal ?? "",
    withdrawalDelayedTime: +currentItem?.withdrawal_hold_duration ?? "",
    withdrawalFees: +currentItem?.withdrawal_fees ?? "",
    withdrawalAssociateFees: +currentItem?.withdrawalAssociateFees ?? "",
    checkOutUrl: currentItem?.checkOutUrl ?? "",
    acStatus: currentItem?.accountSts ?? "",
    minLimit: +currentItem?.lower_limit ?? "",
    maxLimit: +currentItem?.upper_limit ?? "",
    minPayoutLimit: +currentItem?.minPayoutLimit ?? "",
    maxPauoutLimit: +currentItem?.maxPauoutLimit ?? "",
    IsFailedWebhookRequired: currentItem?.failed_notify_req ? 1 : 0,
    IsEnableBrowserCheck: currentItem?.browser_check_sts ? 1 : 0,
    SettlementCycle: +currentItem?.financial_cycle ?? 0,
    OldUsersDays: +currentItem?.elderly_users_time ?? 0,
    CheckoutColor: currentItem?.closure_Color ?? "",
    CheckoutThemeUrl: currentItem?.closure_themeUrl ?? "",
    IsAutoApprovedwithdrawal: currentItem?.auto_approved_withdrawal ? 1 : 0,
    ShowCustomerDetailsPage: currentItem?.display_cust_info_page ? 1 : 0,
    IsCustomerDetailsRequired: currentItem?.cust_info_required ? 1 : 0,
    IsSettlementEnable: currentItem?.settlement_action ? 1 : 0,
    merchantBouncerUrl: currentItem?.merchantBouncerUrl ?? "",
    isIntentInResponse: currentItem?.isIntentInResponse ?? 0,
    isDesktopEnable: currentItem?.isDesktopEnable ? 1 : 0,
    isPlayerInfoRequired: currentItem?.isPlayerInfoRequired ? 1 : 0,
    isLevelCustoActive: currentItem?.isLevelCustoActive ? 1 : 0,
    isLevelActive: currentItem?.isLevelActive ? 1 : 0,
    isLevelHidActive: currentItem?.isLevelHidActive ? 1 : 0,
    isAllowExpire: currentItem?.isAllowExpire ? 1 : 0,
    checkoutExpireTimeInSec: currentItem?.checkoutExpireTimeInSec ?? "",
    isEnableCostumerLevel: currentItem?.isEnableCostumerLevel || 0,
    radio1:
      currentItem?.byCid === 1
        ? "cid"
        : currentItem?.byHid === 1
          ? "hid"
          : null,
    dailyAt,
    t1Settlement: currentItem?.t1Settlement ? 1 : 0,
  };
};
