import React, { useRef } from "react";
import { Button, Card, CardBody, CardHeader, Form } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  fedTransactionUpdate,
  get_fed_transcation,
} from "../../../redux/slices/payinPayoutSlice";
import { useEffect } from "react";
import { Table, TableRow } from "../../../components/Table";
import {
  dateFormatter,
  getSessionData,
  responseToaster,
  seperator,
  setSessionData,
} from "../../../helperFunctions";
import { getStatusStyle } from "../../../theme";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import {
  DROPDOWN_ALL_VALUE,
  TRA_FILTER,
  TRA_PAGINATION_OPTIONS,
} from "../../../constant";
import { useRole } from "../../../redux/slices/authSlice";

const FedTransaction = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ucVal, setUcVal] = useState(getSessionData("ucVal", "0")?.toString());

  const isInitialMount = useRef(true);

  const searchDataOptions = [
    { value: "depositId", label: "Deposit Id" },
    { value: "orderId", label: "Order Id" },
    { value: "sentAcNo", label: "Sent A/C" },
  ];

  const initialValues = {
    filter: searchDataOptions?.[0]?.value,
    search: "",
    status: DROPDOWN_ALL_VALUE,
    AcNo: DROPDOWN_ALL_VALUE,
    date: [null, null],
    isFilter: false,
  };

  const [filter, setFilter] = useState(TRA_FILTER);
  const role = useRole("FED Transactions");

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    setSessionData("ucVal", ucVal?.toString(), "0");
  }, [ucVal]);

  const dispatch = useDispatch();
  const { getFedTranscationState } = useSelector((state) => state.payinPayout);

  const statusDataOptions = [
    { value: "Success", label: "Success" },
    { value: "Processing", label: "Processing" },
    { value: "Initialized", label: "Initialized" },
    { value: "Pending", label: "Pending" },
    { value: "Failed", label: "Failed" },
    { value: "Not Attempted", label: "Not Attempted" },
  ];

  const getBlockingIndicatorForState = (isBlock, state, country, city) => {
    if (isBlock && state && country === "IN") {
      var blockedStateArray = [
        "jharkhand",
        "rajasthan",
        "haryana",
        "uttarakhand",
        "chhattisgarh",
        "bihar",
        "gujarat",
        "maharashtra",
        "karnataka",
      ];
      const lowerState = state.toLowerCase();
      if (blockedStateArray.indexOf(lowerState) > -1) {
        if (lowerState !== "maharashtra") {
          return (
            <div>
              <span className="badge badge-danger" style={{ marginTop: "2px" }}>
                {state}
              </span>
            </div>
          );
        }
      }
    }
    if (country !== "IN") {
      return (
        <div>
          <span className="badge badge-danger" style={{ marginTop: "2px" }}>
            {country}
          </span>
        </div>
      );
    }
    return "";
  };

  const getBlockingIndicatorForDataBlocked = (is_block_by_data) => {
    if (is_block_by_data) {
      return (
        <div>
          <span className="badge badge-danger" style={{ marginTop: "2px" }}>
            Manual Blocked
          </span>
        </div>
      );
    }
    return "";
  };

  const columns = [
    {
      title: "DATE",
      name: "createdAtIst",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Create : </span>
                  {data?.created_at_ist || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Transcation ID : </span>
                  {data?.transactionId || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "ORDER DETAILS",
      name: "orderId",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Order ID : </span>
                  {data?.orderId || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Sender A/c : </span>
                  {data?.sentAccountNumber || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Remitter A/c : </span>
                  {data?.remitterAccountNumber || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "STATUS DESC",
      name: "custName",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Desc : </span>
                  {data?.statusDesc || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Mode : </span>
                  {data?.txnMode || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Txn Channle : </span>
                  {data?.txn_channel || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "BANK DETAILS",
      name: "BANK",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span>NickName : </span>
                  {data?.benNickName || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span>Full Name : </span>
                  {data?.benFullName || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "AMOUNT",
      name: "paymentAmt",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>{`${seperator(data?.amount)}`}</p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "STATUS",
      name: "txn_status",
      Cell: (data) => {
        const { bgClassName } = getStatusStyle(data?.txn_status);
        const isBlockedByVpn =
          data?.isBlocked && data?.Purpose === "Transaction Blocked By VPN";
        const isBlockedByCustomer =
          data?.isBlocked &&
          data?.Purpose === "Transaction Blocked By Fraud System";
        return (
          <>
            <div>
              <span className={`${bgClassName}`}>{data?.txn_status}</span>
            </div>
            {isBlockedByVpn ? (
              <div className="mt-50">
                <span className="red-light"></span>
              </div>
            ) : null}
            {isBlockedByCustomer ? (
              <div className="mt-50">
                <span className="green-light"></span>
              </div>
            ) : null}
            {data?.isRisky && (
              <div className="mt-50">
                <span className="red-light"></span>
              </div>
            )}
            {data?.is_verified === 2 ? (
              <div>
                <span className={"yellow-light"}></span>
              </div>
            ) : null}
            <div className="cell-order">
              {data?.isBlocked && data?.custState && data?.custCountry
                ? getBlockingIndicatorForState(
                    data?.isBlocked,
                    data?.custState,
                    data?.custCountry,
                    data?.custCity
                  )
                : ""}
              {getBlockingIndicatorForDataBlocked(data?.is_block_by_data)}
            </div>
          </>
        );
      },
    },
    {
      title: "ACTIONS",
      name: "is_verified",
      isOpen: role.includes("fed-transaction-update"),
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                {data?.is_verified === 2 ? (
                  <Button
                    color="primary"
                    onClick={() => onUpdateFedTransaction(data?.id)}
                  >
                    Claim UTR
                  </Button>
                ) : null}
              </div>
            </div>
          </>
        );
      },
    },
  ];
 
  const getClientDataOptions = () => {
    const clientData = getFedTranscationState?.remitter?.map((val) => {
      const clientDataOption = {
        value: val?.remitterAccountNumber,
        label: `${val?.remitterName}  -  ${val?.remitterAccountNumber}`,
      };
      return clientDataOption;
    });
    return clientData;
  };

  const onUpdateFedTransaction = async (id) => {
    try {
      setIsLoading(true);
      const payload = {
        id,
        is_verified: 1,
      };
      await dispatch(fedTransactionUpdate(payload)).unwrap();
      payout_Manual_Get();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const payout_Manual_Get = async () => {
    try {
      setIsLoading(true);
      const {
        isFilter,
        page_no,
        limit,
        filter: dropdown,
        date,
        search,
        ...rest
      } = filter;

      const payload = isFilter
        ? {
            filter_data: {
              [dropdown]: search,
              unClaim: ucVal?.toString(),
              startDate: dateFormatter(date?.[0], "start"),
              endDate: dateFormatter(date?.[1], "end", date?.[0]),
              ...rest,
            },
            page_no,
            limit,
          }
        : {
            filter_data: {
              unClaim: ucVal?.toString(),
            },
            page_no,
            limit,
          };

      const res = await dispatch(get_fed_transcation(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("fedTransactions-list")) {
      payout_Manual_Get();
    }
  }, [filter, ucVal]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...TRA_FILTER,
      isFilter: true,
    });
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  return (
    <>
      {role.includes("fedTransactions-list") ? (
        <>
          <div className="payoutmanual-main-div">
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div>
                    <h4 className="main-title">
                      <b>FED Transaction</b>
                    </h4>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div
                    className="d-flex my-xl-auto right-content align-items-end button-space "
                    style={{ flexWrap: "wrap" }}
                  >
                    <ControlledDropDown
                      id="filter"
                      name="filter"
                      label="Filter"
                      isDefaultOption={false}
                      options={searchDataOptions}
                      control={control}
                    />
                    <ControlledInput
                      placeholder="Enter Search Value"
                      name="search"
                      label="Search"
                      control={control}
                    />
                    <ControlledDropDown
                      id="type"
                      name="status"
                      label="Status"
                      isDefaultOption={DROPDOWN_ALL_VALUE}
                      options={statusDataOptions}
                      control={control}
                    />

                    <div className="select-div">
                      <ControlledDropDown
                        inputClassName="selectwidth"
                        id="debitAc"
                        name="AcNo"
                        label="Client"
                        isDefaultOption={DROPDOWN_ALL_VALUE}
                        options={getClientDataOptions()}
                        control={control}
                      />
                    </div>

                    <div className="d-flex flex-column select-div">
                      <ControlledDatePicker
                        placeholder="Select Date"
                        label="Date"
                        name="date"
                        maxDate={new Date()}
                        selectsRange
                        control={control}
                      />
                    </div>
                    <Button type="submit" color="primary">
                      Apply
                    </Button>
                    <Button
                      type="button"
                      color="danger"
                      onClick={() => {
                        setFilter({ ...TRA_FILTER });
                        reset();
                      }}
                    >
                      Clear
                    </Button>
                    {ucVal === "0" ? (
                      <Button
                        type="button"
                        color="primary"
                        onClick={() => {
                          setUcVal("2");
                        }}
                      >
                        Show UnClaimed
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        color="primary"
                        onClick={() => {
                          setUcVal("0");
                        }}
                      >
                        Show Default
                      </Button>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>

            <Card>
              <Table
                columns={columns}
                filterColumns={filterColumns}
                isLoading={isLoading}
                data={getFedTranscationState?.data || []}
                isData={!!getFedTranscationState?.data?.length}
                count={getFedTranscationState?.total_item || 0}
                pagination={filter}
                isExpandable={false}
                handlePaginationChange={(pagination) => {
                  setFilter({
                    ...filter,
                    ...pagination,
                  });
                }}
                onColumnsChange={(columns) => {
                  setFilterColumns(columns);
                }}
                paginationOptions={TRA_PAGINATION_OPTIONS}
              >
                {getFedTranscationState?.data?.map((item) => {
                  return (
                    <TableRow
                      columns={columns}
                      filterColumns={filterColumns}
                      item={item}
                      isExpandable={false}
                    />
                  );
                })}
              </Table>
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default FedTransaction;
