import { Button, Card, CardHeader } from "reactstrap";
import { Table, TableRow } from "../../../../components/Table";
import React, { useEffect, useState } from "react";
import { FILTER } from "../../../../constant";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../../helperFunctions";
import { useRole } from "../../../../redux/slices/authSlice";
import {
  merchantUserDelete,
  merchantUserGet,
  useMerchantSupportUser,
} from "../../../../redux/slices/merchantSlice";
import AddUsersModal from "./AddUserModal";
import DeleteModal from "../../../../components/Custom/Modals/DeleteModal/DeleteModal";
import ResetPassword from "./ResetPassword";

const SupportUser = (props) => {
  const { merchantData } = props;
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(FILTER);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteOpenModal, setIsDeleteOpenModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  const role = useRole("Merchant");

  const data = useMerchantSupportUser();
  const dispatch = useDispatch();

  const onDelete = async () => {
    try {
      setDeleteLoading(true);
      const payload = {
        id: isDeleteOpenModal?.id,
        merchantId: isDeleteOpenModal?.merchant_id,
      };
      const res = await dispatch(merchantUserDelete(payload)).unwrap();
      responseToaster(res);
      setFilter({
        ...filter,
      });
      setIsDeleteOpenModal(null);
      setDeleteLoading(false);
    } catch (err) {
      setDeleteLoading(false);
    }
  };

  const columns = [
    {
      title: "Id",
      name: "id",
    },
    {
      title: "user name",
      name: "user_name",
    },
    {
      title: "merchant name",
      name: "merchant_name",
      Cell: (data) => {
        return <div>{data?.merchant_details?.merchant_name || "-"}</div>;
      },
    },
    {
      title: "merchant email",
      name: "merchant_email",
      Cell: (data) => {
        return <div>{data?.merchant_details?.merchant_email || "-"}</div>;
      },
    },
    {
      title: "ACTIONS",
      name: "actions",
      Cell: (data) => {
        return (
          <>
            <div className="button-space d-flex">
              {role.includes("reset-merchant-user-password") ? (
                <Button
                  title="Reset Password"
                  color="primary"
                  className="btn-smallsize"
                  onClick={() => setResetPassword(data)}
                >
                  Reset Password
                </Button>
              ) : null}
              {role.includes("update-merchant-user") ? (
                <Button
                  title="Edit"
                  color="primary"
                  className="btn-smallsize"
                  onClick={() => {
                    setIsOpenModal(data);
                  }}
                >
                  Edit
                </Button>
              ) : null}
              {role.includes("delete-merchant-user") ? (
                <Button
                  title="Delete"
                  className="btn-smallsize"
                  color="danger"
                  onClick={() => setIsDeleteOpenModal(data)}
                >
                  Delete
                </Button>
              ) : null}
            </div>
          </>
        );
      },
    },
  ];
  const onGetData = async () => {
    try {
      setIsLoading(true);
      const { isFilter, ...payload } = filter;
      const clonedPayload = {
        merchantId: merchantData?.merchantId, 
        ...payload
      }
      const res = await dispatch(merchantUserGet(clonedPayload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("merchant-user-list") && merchantData?.merchantId) {
      onGetData();
    }
  }, [filter,merchantData]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  if (!role.includes("merchant-user-list")) {
    return
  }

  return (
    <>
      <div className="merchant-main-div">
        <Card>
          <CardHeader className="flex-column align-items-start">
            <div className="d-flex align-items-center w-100 justify-content-between">
              <h4 className="main-title">
                <b>Sub User</b>
              </h4>

              {role.includes("add-merchant-user") ? (
                <div
                  className="d-flex align-items-center justify-content-around"
                  style={{ gap: "10px" }}
                >
                  <Button
                    title="Add User"
                    color="primary"
                    onClick={() => setIsOpenModal(true)}
                  >
                    Add User
                  </Button>
                </div>
              ) : null}
            </div>
          </CardHeader>
          <Table
            columns={columns}
            filterColumns={filterColumns}
            isLoading={isLoading}
            data={data?.data}
            isData={!!data?.data?.length}
            count={data?.total_item || 0}
            pagination={filter}
            isExpandable={false}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
              });
            }}
            onColumnsChange={(columns) => {
              setFilterColumns(columns);
            }}
          >
            {data?.data?.map((val) => {
              return (
                <TableRow
                  columns={columns}
                  filterColumns={filterColumns}
                  isExpandable={false}
                  item={val}
                />
              );
            })}
          </Table>
        </Card>
      </div>
      <AddUsersModal
        isOpen={isOpenModal}
        onGet={() => setFilter({ ...filter })}
        onHide={() => setIsOpenModal(false)}
        data={data}
        merchantData={merchantData}
      />
      <DeleteModal
        title="Delete User"
        content="Are you sure you want to delete user ?"
        isOpen={!!isDeleteOpenModal}
        onDoneClick={() => onDelete()}
        onCancelClick={() => {
          setIsDeleteOpenModal(null);
          setDeleteLoading(false);
        }}
        isButtonLoading={deleteLoading}
      />
      <ResetPassword
        isOpen={resetPassword}
        onCloseResetPassword={() => setResetPassword(null)}
      />
    </>
  );
};

export default SupportUser;
