import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Modal } from "reactstrap";
import {
  Bank_Available,
  Bank_Transaction_Add,
} from "../../../../redux/slices/bankTransactionSlice";
import { Fragment } from "react";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useForm } from "react-hook-form";
import ControlledInput from "../../Forms/Controller/ControlledInput";
import ControlledDropDown from "../../Forms/Controller/ControlledDropDown";
import { responseToaster } from "../../../../helperFunctions";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const methodOptions = [
  { value: "UPI", label: "UPI" },
  { value: "IMPS", label: "IMPS" },
];

const modeOptions = [
  { value: "CR", label: "CR" },
  { value: "DR", label: "DR" },
];

const ManualBankEntryModel = ({
  isBalSheet = false,
  DRList,
  isManualBankEntryModel,
  onCloseModal,
  onGet,
}) => {
  const dispatch = useDispatch();

  const { availableBankState } = useSelector((state) => state.bankTransaction);
  const initialValues = {};

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const onClose = () => {
    onCloseModal();
    reset();
  };

  const availableBankOptions = () => {
    const availableBankOption = availableBankState?.bankList
      ?.slice()
      ?.sort((a, b) => a?.account_name?.localeCompare(b?.account_name))
      ?.map((val) => ({
        value: val?.account_id,
        label: `${val?.label || val?.account_holder_name} - (${val?.account_number}) (${val?.bank_name})`,
      }));
    return availableBankOption;
  };

  const bank_Available = async () => {
    try {
      const payload = {};
      await dispatch(Bank_Available(payload)).unwrap();
    } catch (err) {}
  };

  useEffect(() => {
    bank_Available();
  }, []);

  const onSubmit = async () => {
    try {
      const values = getValues();
      setIsButtonLoading(true);
      const res = await dispatch(Bank_Transaction_Add(values)).unwrap();
      responseToaster(res);
      if (!!res) {
        onClose();
        onGet();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const schema = Yup.object({
    paymentUtr: Yup.string()
      .required("Please enter a UTR"),
    amount: Yup.string().required("Please enter a amount"),
    bankId: Yup.string().required("Please select a bank"),
    methodName: Yup.string().required("Please select a method name"),
    transactionMode: Yup.string().required("Please select a mode"),
    drBankId: Yup.mixed().when("transactionMode", {
      is: (value) => {
        return value === "DR";
      },
      then: () =>
        Yup
          .string()
          .required("Please select DR bank name")
          .typeError("Please select DR bank name"),
      otherwise: (Yup) => Yup.notRequired().optional().nullable(),
    }),
  });

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (availableBankState && !!isBalSheet) {
      setValue("bankId", isBalSheet);
    }
  }, [availableBankState, isBalSheet, isManualBankEntryModel]);

  return (
    <Fragment>
      <Modal
        isOpen={!!isManualBankEntryModel}
        toggle={() => onClose()}
        className="update-manual-bank-modal"
        centered={true}
      >
        <div className="modal-header">
          <h4 className="modal-title mt-0">
            Add Manual Bank Transaction Entry
          </h4>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body">
            <ControlledInput
              name="paymentUtr"
              label="UTR"
              placeholder="Enter UTR"
              control={control}
              errors={errors}
            />
            <ControlledInput
              name="amount"
              type="number"
              label="Amount"
              placeholder="Enter amount"
              control={control}
              errors={errors}
            />
            <ControlledDropDown
              name="bankId"
              label="Bank"
              placeholder="Select Bank"
              isDisabled={!!isBalSheet}
              isDefaultOption={false}
              options={availableBankOptions()}
              control={control}
              errors={errors}
            />
            <ControlledDropDown
              name="methodName"
              label="Method Name"
              placeholder="Select Method Name"
              isDefaultOption={false}
              options={methodOptions}
              control={control}
              errors={errors}
            />
            <ControlledDropDown
              name="transactionMode"
              label="Mode"
              placeholder="Select Mode"
              isDefaultOption={false}
              options={modeOptions}
              control={control}
              errors={errors}
            />
            {watch("transactionMode") === "DR" ? (
              <>
                <ControlledDropDown
                  name="drBankId"
                  label="DR Bank Name"
                  placeholder="Select DR Bank Name"
                  isDefaultOption={false}
                  options={DRList}
                  control={control}
                  errors={errors}
                />
              </>
            ) : null}
          </div>
          <div className="modal-footer z-0">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              isButtonLoading={isButtonLoading}
            >
              Submit
            </CustomeButton>
          </div>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default ManualBankEntryModel;
