import React, { useState } from 'react'
import { Button, Card, CardHeader } from 'reactstrap';
import User from './User';
import SMS from './Sms';
import { useEffect } from 'react';
import { getLocalDataa } from '../../../helperFunctions';
import { useRole } from '../../../redux/slices/authSlice';

const SmsSync = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const role = useRole("Sms Sync");
  const tabsData = [
    ...(role.includes("sms-list") ? [{ title: "SMS Logs", component: SMS }] : []),
    ...(role.includes("sms-user-list") ? [{ title: "Users", component: User }] : []),
  ];

  const Component = tabsData?.[currentTab]?.component;
  useEffect(() => {
    setCurrentTab(typeof getLocalDataa("SmsTab") !== 'object' && role.includes("sms-user-list") ? getLocalDataa("SmsTab") : currentTab || 0)
  }, [getLocalDataa("SmsTab")])

  if (!role.includes("sms-sync-list")) {
    return;
  }

  return (
    <div>
      <Card>
        <CardHeader className="flex-column  align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <div>
              <h4 className="main-title">
                <b>SMS Sync</b>
              </h4>
            </div>
            <div
              className="d-flex my-xl-auto right-content align-items-end button-space"
              style={{ flexWrap: "wrap" }}
            >
              {tabsData?.map((tab, index) => {
                return (
                  <Button
                    className={
                      currentTab === index ? "btn-primary" : "filterbtn"
                    }
                    color={currentTab === index ? "btn-primary" : ""}
                    onClick={() => {
                      setCurrentTab(index);
                      localStorage.removeItem("SmsTab")
                      // reset();
                      // setIsLoading(false);
                      // setFilter({ ...TRA_FILTER });
                    }}
                  >
                    {tab.title}
                  </Button>
                );
              })}
            </div>
          </div>
        </CardHeader>
        <Component />
      </Card>

    </div>
  )
}

export default SmsSync