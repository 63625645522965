import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import ToggleSwitch from "../../components/Custom/Forms/ToggleSwitch/ToggleSwitch";
import { responseToaster } from "../../helperFunctions";
import { pgUpdateAutoLogin, pgUpdateStatus } from "../../redux/slices/pgSlice";
import { useRole, useUser } from "../../redux/slices/authSlice";
import { getPgIdFromPgName } from "./Pg";

const PgStatus = (props) => {
  const {
    data,
    pgData,
    setPgData,
    columns,
    setupdateStatusIsLoading,
    setBankStatusIsLoading,
    updateStatusIsLoading,
    bankStatusIsLoading,
  } = props;

  const dispatch = useDispatch();
  const locations = useLocation()?.pathname?.split("/");
  const params = { pgName: locations?.at(-1), pgType: locations?.at(-2) };
  const isPayin = params?.pgType === "payin";
  const isPayout = params?.pgType === "payout";
  const isUpiPay = (params?.pgName === "UPIPAY" && params?.pgType === "payin") || (params?.pgName === "BANKPAY" && params?.pgType === "payin");
  const payinRole = useRole("PG Deposit Bank");
  const payoutRole = useRole("PG Withdrawal Bank");
  const { user } = useUser();

  const onUpdateBankStatus = async (payload) => {
    try {
      setBankStatusIsLoading(payload?.pk);
      const res = await dispatch(pgUpdateStatus({ params, payload })).unwrap();
      const index = pgData?.data?.findIndex(
        (item) => item?.account_id === payload?.pk
      );
      if (index > -1) {
        const newData = [...pgData.data];
        newData[index] = { ...newData[index], is_active: payload?.value };
        setPgData({ ...pgData, data: newData });
      }
      responseToaster(res);
      setBankStatusIsLoading(false);
    } catch (err) {
      setBankStatusIsLoading(false);
    }
  };

  const onUpdateAutoLogin = async (payload) => {
    try {
      setupdateStatusIsLoading(payload?.pk);
      const res = await dispatch(
        pgUpdateAutoLogin({ params, payload })
      ).unwrap();

      const index = pgData?.data?.findIndex(
        (item) => item?.account_id === payload?.pk
      );
      if (index > -1) {
        const newData = [...pgData.data];
        newData[index] = { ...newData[index], is_auto_login: payload?.value };
        setPgData({ ...pgData, data: newData });
      }
      responseToaster(res);
      setupdateStatusIsLoading(false);
    } catch (err) {
      setupdateStatusIsLoading(false);
    }
  };

  return (
    <>
      {isUpiPay || isPayin || isPayout ? (
        <>
          <span>Bank Status : </span>
          <ToggleSwitch
            isLoading={bankStatusIsLoading === data?.account_id}
            disabled={
              !!(bankStatusIsLoading || updateStatusIsLoading) ||
              !columns?.includes("is_active") ||
              (isPayin && !payinRole?.includes("pg-deposit-bank-update")) ||
              (isPayout && !payoutRole?.includes("pg-withdrawal-bank-update"))
            }
            className={`${!!(bankStatusIsLoading || updateStatusIsLoading) ||
              !columns?.includes("is_active")
              ? "disabled"
              : ""
              }`}
            value={data?.is_active ? 1 : 0}
            onChange={async (value) => {
              const payload = {
                value: value,
                pk: data?.account_id,
                pg_id: getPgIdFromPgName(user?.SiderBar?.PG, params?.pgName)
              };
              await onUpdateBankStatus(payload);
            }}
            style={{
              marginBottom: 5,
            }}
          />
        </>
      ) : null}
      {isUpiPay ? (
        <>
          <span>Auto Login : </span>
          <ToggleSwitch
            isLoading={updateStatusIsLoading === data?.account_id}
            disabled={
              !!(bankStatusIsLoading || updateStatusIsLoading) ||
              (isPayin && !payinRole?.includes("pg-deposit-bank-update")) ||
              (isPayout && !payoutRole?.includes("pg-withdrawal-bank-update"))
            }
            className={`${!!(bankStatusIsLoading || updateStatusIsLoading) ? "disabled" : ""
              }`}
            value={data?.is_auto_login ? 1 : 0}
            onChange={async (value) => {
              const payload = {
                value: value,
                pk: data?.account_id,
                pg_id: getPgIdFromPgName(user?.SiderBar?.PG, params?.pgName)
              };
              await onUpdateAutoLogin(payload);
            }}
          />
        </>
      ) : null}
    </>
  );
};

export default PgStatus;
