import React, { useEffect } from "react";
import { useParams } from "react-router";
import {
  Merchant_Pay_Out_Available_Payment_Get,
  Merchant_Pay_Out_Get,
  useMerchantPayOutAvailablePayment,
  useMerchantPayOut,
  Merchant_Pay_Out_Status_Update,
  Merchant_Pay_Out_Meta_Add,
  Merchant_Pay_Out_Delete,
  setMerchantPayout,
  Merchant_Pay_Out_InUse_Status_Update,
} from "../../../redux/slices/merchantSlice";
import { useDispatch } from "react-redux";
import { Fragment } from "react";
import { useState } from "react";
import { Button, Card } from "reactstrap";
import { Table, TableRow } from "../../../components/Table";
import { FILTER } from "../../../constant";
import DeleteModal from "../../../components/Custom/Modals/DeleteModal/DeleteModal";
import ToggleSwitch from "../../../components/Custom/Forms/ToggleSwitch/ToggleSwitch";
import { responseToaster, sortByKey } from "../../../helperFunctions";
import MerchantPayOutAmount from "./MerchantPayOutAmount";
import MerchantMinMaxChange from "./MerchantMinMaxChange";
import { useAutoRefreshTime, useRole } from "../../../redux/slices/authSlice";

const MerchantPayOut = () => {
  const [filter] = useState(FILTER);
  const [deleteData, setDeleteData] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [statusInUseLoading, setStatusInUseLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBankLoading, setIsBankLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const [updateDetails, setUpdateDetails] = useState({});

  const params = useParams();
  const dispatch = useDispatch();
  const role = useRole("Merchant");
  const autoRefreshTime = useAutoRefreshTime();

  const data = useMerchantPayOut();
  const merchantAvailablePayment = useMerchantPayOutAvailablePayment();

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [autoFilterColumns, setAutoFilterColumns] = useState([]);
  const [manualFilterColumns, setManualFilterColumns] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const onGetMerchantPayOut = async (loading = false) => {
    try {
      if (loading) {
        setIsLoading(true);
      }
      await dispatch(Merchant_Pay_Out_Get({ merchantId: params?.id })).unwrap();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onGetMerchantAvailablePayment = async (loading = false) => {
    try {
      if (loading) {
        setIsBankLoading(true);
      }
      await dispatch(
        Merchant_Pay_Out_Available_Payment_Get({
          merchantId: params?.id,
        })
      ).unwrap();
      setIsBankLoading(false);
    } catch (err) {
      setIsBankLoading(false);
    }
  };

  const onDelete = async (payload) => {
    try {
      setIsButtonLoading(true);
      const res = await dispatch(Merchant_Pay_Out_Delete(payload)).unwrap();
      if (res) {
        responseToaster(res);
      }
      onGetMerchantPayOut(true);
      onGetMerchantAvailablePayment(true);
      setDeleteData(false);
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const onUpdateStatus = async (payload) => {
    try {
      setStatusLoading(payload?.id);
      const res = await dispatch(
        Merchant_Pay_Out_Status_Update(payload)
      ).unwrap();

      const index = data?.data?.findIndex((item) => item?.id === payload?.id);
      if (index > -1) {
        const newData = [...data.data];
        newData[index] = { ...newData[index], isActive: payload?.value };
        dispatch(setMerchantPayout({ ...data, data: newData }));
      }

      responseToaster(res);
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
    }
  };
  const onUpdateInUseStatus = async (payload) => {
    try {
      setStatusInUseLoading(payload?.id);
      const res = await dispatch(
        Merchant_Pay_Out_InUse_Status_Update(payload)
      ).unwrap();

      const index = data?.data?.findIndex((item) => item?.id === payload?.id);
      if (index > -1) {
        const newData = [...data.data];
        newData[index] = { ...newData[index], inUse: payload?.inUse };
        dispatch(setMerchantPayout({ ...data, data: newData }));
      }

      responseToaster(res);
      setStatusInUseLoading(false);
    } catch (err) {
      setStatusInUseLoading(false);
    }
  };

  const onAddMeta = async (payload) => {
    try {
      setIsBankLoading(true);
      const res = await dispatch(Merchant_Pay_Out_Meta_Add(payload)).unwrap();
      responseToaster(res);
      onGetMerchantAvailablePayment(true);
      onGetMerchantPayOut(true);
    } catch (err) {
      setIsBankLoading(false);
    }
  };

  useEffect(() => {
    const newAutoColumns = availablePayMetaColumns?.map((item) => item?.name);
    const newManualColumns = payMetaColumns?.map((item) => item?.name);
    setAutoFilterColumns(newAutoColumns);
    setManualFilterColumns(newManualColumns);
  }, []);

  useEffect(() => {
    if (role.includes("merchant-payout")) {
      onGetMerchantPayOut(true);
      onGetMerchantAvailablePayment(true);
    }
  }, [filter]);

  const payMetaColumns = [
    {
      title: "DATE",
      name: "initiated_at",
      Cell: (data) => {
        return (
          <>
            <div className="cell-order">
              <div className="order-singal-div">
                <div>
                  <span>Create : </span>
                  {data?.createdAt || ""}
                </div>
              </div>
              <div className="order-singal-div">
                <div>
                  <span>Update : </span>
                  {data?.updatedAt || ""}
                </div>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "ID",
      name: "id",
    },
    {
      title: "PG",
      name: "pgName",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <span>
                {data?.pgName || ""}
                {data?.acId ? ` (${data?.acId})` : ""}
              </span>
              {data?.pgLabel ? (
                <div className="order-singal-div">
                  <p>
                    <span> PG Label : </span>
                    {data?.pgLabel}
                  </p>
                </div>
              ) : null}
              {data?.bankName ? (
                <div className="order-singal-div">
                  <p>
                    <span> Bank name : </span>
                    {data?.bankName}
                  </p>
                </div>
              ) : null}
              {data?.acNumber ? (
                <div className="order-singal-div">
                  <p>
                    <span> A/C No : </span>
                    {data?.acNumber}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "MIN AMOUNT",
      name: "minAmt",
      Cell: (data) => {
        return (
          <>
            <MerchantMinMaxChange
              updateDetails={updateDetails}
              setUpdateDetails={setUpdateDetails}
              data={data}
              onGetMerchantPayOut={() => {
                onGetMerchantPayOut(true);
              }}
              keyInput="minAmt"
              setIsLoading={setIsLoading}
            />
          </>
        );
      },
    },
    {
      title: "MAX AMOUNT",
      name: "maxAmt",
      Cell: (data) => {
        return (
          <>
            <MerchantMinMaxChange
              updateDetails={updateDetails}
              setUpdateDetails={setUpdateDetails}
              data={data}
              onGetMerchantPayOut={() => {
                onGetMerchantPayOut(true);
              }}
              keyInput="maxAmt"
              setIsLoading={setIsLoading}
            />
          </>
        );
      },
    },
    {
      title: "BALANCE",
      name: "present_balance",
      Cell: (data) => {
        return (
          <>
            <MerchantPayOutAmount
              updateDetails={updateDetails}
              setUpdateDetails={setUpdateDetails}
              data={data}
              onGetMerchantPayOut={() => {
                onGetMerchantPayOut(true);
              }}
              setIsLoading={setIsLoading}
            />
          </>
        );
      },
    },
    {
      title: "In Use",
      name: "inUse",
      Cell: (data) => {
        return (
          <>
            <ToggleSwitch
              isLoading={statusInUseLoading === data?.id}
              value={data?.inUse ? 1 : 0}
              className={`${statusInUseLoading || statusLoading ? "disabled" : ""
                }`}
              disabled={!role.includes("merchant-payout-update")}
              onChange={async (value) => {
                try {
                  const payload = {
                    inUse: value,
                    pk: data?.pgId,
                    pgName: data?.pgName,
                    merchantId: data?.merchantId,
                    id: data?.id,
                  };

                  const res = await onUpdateInUseStatus(payload);
                  if (res) {
                    responseToaster(res);
                  }
                } catch (err) { }
              }}
            />
          </>
        );
      },
    },
    {
      title: "ACTIVE",
      name: "active",
      Cell: (data) => {
        return (
          <>
            <ToggleSwitch
              isLoading={statusLoading === data?.id}
              value={data?.isActive ? 1 : 0}
              disabled={!role.includes("merchant-payout-update")}
              className={`${statusLoading || statusInUseLoading ? "disabled" : ""
                }`}
              onChange={async (value) => {
                try {
                  const payload = {
                    value: value,
                    pk: data?.pgId,
                    pgName: data?.pgName,
                    merchantId: data?.merchantId,
                    id: data?.id,
                  };
                  const res = await onUpdateStatus(payload);
                  if (res) {
                    responseToaster(res);
                  }
                } catch (err) { }
              }}
            />
          </>
        );
      },
    },

    {
      title: "ACTION",
      name: "action",
      Cell: (data) => {
        return (
          <>
            <h6 className="mb-0">
              <Button
                color="danger"
                className="btn-smallsize"
                disabled={!role.includes("merchant-payout-delete")}
                onClick={() => {
                  setDeleteData({
                    merchantId: params?.id,
                    pgName: data?.pgName,
                    id: data?.id,
                    pgId: data?.pgId,
                  });
                }}
              >
                <i className="fa-solid fa-trash"></i>
              </Button>
            </h6>
          </>
        );
      },
    },
  ];

  const availablePayMetaColumns = [
    {
      title: "MERCHANT PG ID",
      name: "merchantId",
    },
    {
      title: "PG NAME",
      name: "pgName",
    },
    {
      title: "ACCOUNT ID",
      name: "accountId",
    },
    {
      title: "LABEL",
      name: "pgLabel",
    },
    {
      title: "ACTION",
      name: "action",
      Cell: (row) => {
        return (
          <Fragment>
            <Button
              color="primary"
              disabled={!role.includes("merchant-payout-add")}
              onClick={async () => {
                const payload = {
                  merchantId: params?.id,
                  pgName: row?.pgName,
                  pgId: row?.accountId,
                };
                await onAddMeta(payload);
              }}
            >
              Add Bank
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const prepareFilterButtons = () => {
    const buttons = ["ALL"];
    data?.data?.forEach((item) => {
      if (!buttons?.includes(item?.pgName) && item?.pgName) {
        buttons.push(item?.pgName);
      }
    });
    return buttons;
  };

  const prepareData = () => {
    if (selectedFilter === "ALL") {
      const newData = data?.data || [];
      return sortByKey(newData, "isActive", "DESC");
    }
    const newData = data?.data?.filter(
      (item) => item?.pgName === selectedFilter
    );
    return sortByKey(newData, "isActive", "DESC");
  };

  useEffect(() => {
    if (role.includes("merchant-payout")) {
      const IntervalClick = setInterval(() => {
        if (refresh) {
          onGetMerchantPayOut(true);
          onGetMerchantAvailablePayment(false);
        }
      }, autoRefreshTime);
      return () => clearInterval(IntervalClick);
    }
  }, [refresh, filter]);

  if (!role.includes("merchant-payout")) {
    return;
  }

  return (
    <>
      <Card
        className={`merchant-payintable ${isLoading && refresh ? "opacity-25" : ""
          }`}
      >
        <div>
          <div className="d-flex align-items-center px-2 py-1 justify-content-between">
            <div className="d-flex align-items-center">
              <h5 className="tab-table-heading me-1">Pay Bank</h5>
            </div>
            <div className="d-flex align-items-center justify-content-center tab-button button-space">
              <span className=" aurorefresh-btn">
                <Button
                  type="button"
                  className={`${refresh ? "" : "filterbtn"} gap btn-smallsize`}
                  color={refresh ? "primary" : ""}
                  onClick={() => setRefresh(!refresh)}
                  title="Auto refresh"
                >
                  {refresh ? (
                    <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                  ) : (
                    <i className="fa-solid fa-arrows-rotate"></i>
                  )}
                  Auto Refresh
                </Button>
              </span>
              {prepareFilterButtons()?.map((item) => {
                return (
                  <Button
                    color={
                      selectedFilter === item ? "primary btn-smallsize" : ""
                    }
                    className={
                      selectedFilter === item ? "" : "filterbtn btn-smallsize"
                    }
                    onClick={() => {
                      setSelectedFilter(item);
                    }}
                  >
                    {item}
                  </Button>
                );
              })}
            </div>
          </div>
          <Table
            columns={payMetaColumns}
            isLoading={isLoading && !refresh}
            data={prepareData()}
            isData={!!prepareData()?.length}
            filterColumns={manualFilterColumns}
            onColumnsChange={(columns) => {
              setManualFilterColumns(columns);
            }}
            isExpandable={false}
          >
            {prepareData()?.map((item) => {
              return (
                <TableRow
                  columns={payMetaColumns}
                  item={item}
                  filterColumns={manualFilterColumns}
                  isExpandable={false}
                />
              );
            })}
          </Table>
        </div>
      </Card>
      <Card>
        <div>
          <div className="d-flex align-items-center">
            <h5 className="tab-table-heading m-1">Availble Pay Bank</h5>
          </div>
          <Table
            columns={availablePayMetaColumns}
            isLoading={isBankLoading && !refresh}
            data={merchantAvailablePayment?.data || []}
            isData={!!merchantAvailablePayment?.data?.length}
            filterColumns={autoFilterColumns}
            onColumnsChange={(columns) => {
              setAutoFilterColumns(columns);
            }}
            isExpandable={false}
          >
            {merchantAvailablePayment?.data?.map((item) => {
              return (
                <TableRow
                  columns={availablePayMetaColumns}
                  item={item}
                  filterColumns={autoFilterColumns}
                  isExpandable={false}
                />
              );
            })}
          </Table>
          <DeleteModal
            isButtonLoading={isButtonLoading}
            title="Delete Bank!"
            content="Are you sure you want to delete withdrawal bank ?"
            isOpen={!!deleteData}
            onCancelClick={() => {
              setDeleteData(false);
            }}
            onDoneClick={async () => {
              await onDelete({
                ...deleteData,
              });
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default MerchantPayOut;
