import React, { useMemo, useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownToggle,
  Form,
  Dropdown,
  CardBody,
} from "reactstrap";
import { Bank_Available } from "../../../redux/slices/bankTransactionSlice";
import {
  Bank_BalSheet_Get,
  Payment_Update,
  BalSheet_Update,
  Used_Mark,
  BankTransactionModeUpdate,
} from "../../../redux/slices/bankBalSheetSlice";
import UpdateTransactionModal from "../../../components/Custom/Modals/UpdateTransactionModal/UpdateTransactionModal";
import ManualBankEntryModel from "../../../components/Custom/Modals/ManualBankEntryModel/ManualBankEntryModel";
import UpdatePaymentModal from "../../../components/Custom/Modals/UpdatePaymentModal/UpdatePaymentModal";
import MarkAsUsedModal from "../../../components/Custom/Modals/MarkAsUsedModal/MarkAsUsedModal";
import MergeUTRModal from "../../../components/Custom/Modals/MergeUTRModal/MergeUTRModal";
import { Table, TableRow } from "../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  DROPDOWN_ALL_VALUE,
  showToaster,
  TRA_FILTER,
  TRA_PAGINATION_OPTIONS,
} from "../../../constant";
import { useForm } from "react-hook-form";
import "./BankTransaction.scss";
import { Switch } from "@mui/material";
import { IoFilterOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import {
  dateFormatter,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import GenerateReportModal from "../../../components/Custom/Modals/GenerateReportModal/GenerateReportModal";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import UpdateBankTransactionModal from "../../../components/Custom/Modals/UpdateBankTransactionModal/UpdateBankTransactionModal";
import { useAutoRefreshTime, useRole } from "../../../redux/slices/authSlice";
import BackIcon from "../../../components/Custom/Elements/BackIcon/BackIcon";

const searchDataOptions = [
  { value: "amount", label: "Amount" },
  // { value: "acNumber", label: "Account Number" },
  { value: "UTR", label: "UTR" },
  // { value: "upiId", label: "UPI Id" },
];

const IsGetOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

const AutoFlowOptions = [
  { value: 1, label: "TRUE" },
  { value: 0, label: "FALSE" },
];

const BankBalSheet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { bankBalSheetState } = useSelector((state) => state.bankBalSheet);
  const { availableBankState } = useSelector((state) => state.bankTransaction);

  const [refresh, setRefresh] = useState(false);
  const [isManualBankEntryModel, setIsManualBankEntryModel] = useState(false);
  const [isUpdateBankTransactionOpen, setIsUpdateBankTransactionOpen] =
    useState(false);
  const [filter, setFilter] = useState({ ...TRA_FILTER, isToast: false });
  const [filterColumns, setFilterColumns] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const role = useRole("Bank Transaction");
  const { bankId } = useParams();
  const autoRefreshTime = useAutoRefreshTime();


  const initialValues = {
    isget: DROPDOWN_ALL_VALUE,
    isAutoFlow: DROPDOWN_ALL_VALUE,
    filter: searchDataOptions?.[0]?.value,
    search: "",
    // lower_amount: "",
    // upper_amount: "",
    date: [null, null],
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [isGenerateReportModal, setIsGenerateReportModal] = useState(false);

  const onCloseGenerateRepostModal = () => {
    setIsGenerateReportModal(!isGenerateReportModal);
  };

  const handleTransactionMode = async (id) => {
    try {
      setIsButtonLoading(true);
      const res = await dispatch(
        BankTransactionModeUpdate({
          id,
        })
      ).unwrap();
      responseToaster(res);
      if (res) {
        setFilter({ ...filter, isToast: false });
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const columns = [
    {
      title: "DATE",
      name: "createdAt",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <div>
                  {/* <span>Create : </span> */}
                  {data?.createdAtIst || ""}
                </div>
              </div>
              {/* <div className="order-singal-div">
                <div>
                  <span>Transaction Date : </span>
                  {data?.bankTxnDate || ""}
                </div>
              </div> */}
            </div>
          </>
        );
      },
    },
    {
      title: "ACCOUNT",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <div>
                  {/* <span>Name : </span> */}
                  {data?.withBankDetail?.account_holder_name || ""}
                </div>
              </div>
              <div className="order-singal-div">
                <div>
                  {/* <span>A/C No : </span> */}
                  {data?.withBankDetail?.account_number || ""}
                </div>
              </div>
              <div className="order-singal-div">
                <div>
                  {/* <span>UTR : </span> */}
                  {data?.withBankDetail?.bank_name || ""}
                </div>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "PAYMENT UTR",
      Cell: (data) => (
        <>
          <span
            style={
              {
                // color: "var(--chatlook-darkblue--)",
              }
            }
            className="cursor-pointer"
          // onClick={() => {
          //   dispatch(
          //     setTransactionFilter({
          //       filter: "bankRrn",
          //       search: data?.UTR,
          //     })
          //   );
          //   navigate("/transaction");
          // }}
          >
            {data?.utr || ""}
          </span>
        </>
      ),
    },
    {
      title: "IS GET",
      name: "isGet",
      Cell: (data) => {
        return (
          <>
            <span>{!!data?.isGet ? "Yes" : "No"}</span>
          </>
        );
      },
    },
    {
      title: "DESC",
      name: "description",
      type: "ellipsis",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <div>{data?.description || ""}</div>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "MODE",
      name: "transactionMode",
    },
    {
      title: "AMOUNT",
      name: "amount",
      Cell: (data) => {
        return <span>{seperator(data?.amount)}</span>;
      },
    },
    {
      title: "TRANSFER BANK",
      name: "transactionDate",
    },
    {
      title: "ACTION",
      name: "action",
      Cell: (data) => {
        return (
          <>
            <div className="d-flex align-items-center gap-1 justify-content-between">
              {role.includes("bank-transaction-update") ? <Button
                color="primary"
                onClick={() => {
                  setIsUpdateBankTransactionOpen({
                    utr: data?.utr || "",
                    amount: data?.amount,
                    id: data?.id,
                    uniqeHash: data?.uniqeHash,
                  });
                }}
              >
                Update
              </Button> : null}
              {data?.transactionMode === "DR" && role.includes("bank-transaction-mode-update") ? (
                <>
                  <Button
                    color="primary"
                    disabled={isButtonLoading}
                    onClick={() => handleTransactionMode(data?.id)}
                  >
                    Mark As CR
                  </Button>
                </>
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const onCloseModal = () => {
    setIsManualBankEntryModel(!isManualBankEntryModel);
  };
  const data = useMemo(() => {
    return bankBalSheetState?.data?.length ? bankBalSheetState.data : [];
  }, [bankBalSheetState?.data]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...TRA_FILTER,
      isFilter: true,
      isToast: true,
    });
  };

  const preparePayload = (filter) => {
    const {
      isFilter,
      page_no,
      limit,
      filter: dropdown,
      search,
      date,
      ...rest
    } = filter;
    const payload = isFilter
      ? {
        filter_data: {
          [dropdown]: search,
          startDate: dateFormatter(date?.[0], "start"),
          endDate: dateFormatter(date?.[1], "end", date?.[0]),
          bankId,
          ...rest,
        },
        page_no,
        limit,
      }
      : {
        page_no,
        limit,
        filter_data: {
          startDate: dateFormatter(new Date(), "start"),
          endDate: dateFormatter(new Date(), "end"),
          bankId,
        },
      };

    return payload;
  };

  const get_Bank_Bal_Sheet = async (loading = false) => {
    try {
      if (loading) {
        setIsLoading(true);
      }

      const payload = preparePayload(filter);
      const res = await dispatch(Bank_BalSheet_Get(payload)).unwrap();
      if (filter?.isToast) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("bankTransaction-list")) {
      get_Bank_Bal_Sheet(true);
    }
  }, [filter]);

  useEffect(() => {
    if (role.includes("bankTransaction-list")) {
      const IntervalClick = setInterval(() => {
        if (refresh) {
          get_Bank_Bal_Sheet(true);
        }
      }, autoRefreshTime);
      return () => clearInterval(IntervalClick);
    }
  }, [refresh, filter]);

  useEffect(() => {
    const newColumns = columns
      ?.filter((item) => item?.isOpen !== false)
      ?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const { control, setValue, handleSubmit, getValues, reset } = useForm({
    defaultValues: initialValues,
  });

  const getBanklistOptions = () => {
    const opttions = availableBankState?.bankList
      ?.slice()
      ?.sort((a, b) => a?.bank_name?.localeCompare(b?.bank_name))
      ?.map((val) => ({
        value: val?.account_number,
        label: `${val?.label || val?.account_holder_name} - (${val?.account_number}) (${val?.bank_name})`,
      }));
    return opttions;
  };

  const getDRBanklistOptions = () => {
    const opttions = bankBalSheetState?.dr_active_bank
      ?.slice()
      ?.sort((a, b) => a?.bank_name?.localeCompare(b?.bank_name))
      ?.map((val) => ({
        value: val?.dr_bank_id,
        label: `${val?.account_holder_name} - (${val?.account_number}) (${val?.bank_name})`,
      }));
    return opttions;
  };

  const bank_Available = async () => {
    try {
      const payload = {};
      await dispatch(Bank_Available(payload)).unwrap();
    } catch (err) { }
  };

  const onCloseUpdateBankTransaction = () => {
    setIsUpdateBankTransactionOpen(false);
  };

  useEffect(() => {
    bank_Available();
  }, []);

  useEffect(() => {
    if (availableBankState) {
      setValue(
        "bankName",
        availableBankState?.bankList?.find(
          (item) => item?.account_id === bankId
        )?.account_number
      );
    }
  }, [availableBankState]);

  if (!role.includes("bankTransaction-list")) {
    return;
  }

  return (
    <>
      <div className={`${isLoading && refresh ? "opacity-25" : ""}`}>
        <Card>
          <CardHeader className="align-items-start">
            <div className="d-flex align-items-center w-100 justify-content-between">
              <div>
                <h4 className="d-flex align-items-center w-100 justify-content-between gap-1 main-title pb-1">
                  <BackIcon />
                  <b>Bank Transaction</b>
                </h4>
                <b>Live Balance: ₹ {bankBalSheetState?.livebal || 0}</b>
              </div>
              <div className="d-flex" style={{ gap: "10px" }}>
                <span className=" aurorefresh-btn">
                  <Button
                    type="button"
                    className={`${refresh ? "" : "filterbtn"} gap`}
                    color={refresh ? "primary" : ""}
                    onClick={() => setRefresh(!refresh)}
                    title="Auto refresh"
                  >
                    {refresh ? (
                      <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                    ) : (
                      <i className="fa-solid fa-arrows-rotate"></i>
                    )}
                    Auto Refresh
                  </Button>
                </span>
                {/* {bankBalSheetState?.bankTranReport ? ( */}
                <Button
                  type="button"
                  color="primary"
                  onClick={() => onCloseGenerateRepostModal()}
                  title="Generate Report"
                >
                  Generate Report
                </Button>
                {/* ) : null} */}

                {/* {bankBalSheetState?.manualBankEntry ? ( */}
                {role.includes("bank-transaction-add") ?
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => setIsManualBankEntryModel(true)}
                    title="Add Manual Bank Entry"
                  >
                    Add Manual Bank Entry
                  </Button> : null}
                {/* ) : null} */}
                <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                  <DropdownToggle
                    className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                    id="page-header-user-dropdown"
                    tag="button"
                    title="Column Filter"
                  >
                    <IoFilterOutline fill="#7367F0" />
                  </DropdownToggle>
                  <DropdownMenu
                    className="z-3"
                    style={{
                      whiteSpace: "nowrap",
                      padding: 20,
                      transform: "translate(-20px, 22px)",
                    }}
                  >
                    <li className="z-3 text-dark">
                      <span>
                        <i className="fa-solid fa-filter"></i> Filter Column
                      </span>
                    </li>
                    <div className="border-bottom border-light mt-2">
                      {columns?.map((column) => {
                        return (
                          <div className="d-flex justify-content-between">
                            <p>{column?.title}</p>
                            <Switch
                              checked={filterColumns?.includes(column?.name)}
                              size="small"
                              onChange={(e) => {
                                const newData = [...filterColumns];
                                const index = newData?.indexOf(column?.name);
                                if (index > -1) {
                                  newData.splice(index, 1);
                                } else {
                                  newData.push(column?.name);
                                }
                                setFilterColumns(newData);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <Form
              className="banktransaction-main-div"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div
                className="d-flex mt-1 right-content align-items-end button-space "
                style={{ flexWrap: "wrap" }}
              >
                <ControlledDropDown
                  name="filter"
                  label="Filter"
                  isDefaultOption={false}
                  options={searchDataOptions}
                  control={control}
                />
                <ControlledInput
                  placeholder="Enter Search Value"
                  name="search"
                  label="Search"
                  control={control}
                />
                <ControlledDropDown
                  label="Is Get"
                  id="type"
                  name="isget"
                  isDefaultOption={DROPDOWN_ALL_VALUE}
                  options={IsGetOptions}
                  control={control}
                />
                <ControlledDropDown
                  label="Auto Flow"
                  id="type"
                  name="isAutoFlow"
                  isDefaultOption={DROPDOWN_ALL_VALUE}
                  options={AutoFlowOptions}
                  control={control}
                />
                <ControlledDropDown
                  label="Bank"
                  id="type"
                  name="bankName"
                  isDisabled
                  // isDefaultOption={DROPDOWN_ALL_VALUE}
                  options={getBanklistOptions()}
                  control={control}
                />
                <ControlledDatePicker
                  name="date"
                  placeholder="Select Date"
                  label="Date"
                  maxDate={new Date()}
                  selectsRange
                  control={control}
                />

                <Button type="submit" color="primary">
                  Apply
                </Button>
                <Button
                  type="button"
                  color="danger "
                  onClick={() => {
                    reset();
                    setFilter({ ...TRA_FILTER, isToast: false });
                  }}
                >
                  Clear
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Card>
          <Table
            columns={columns}
            filterColumns={filterColumns}
            isLoading={isLoading && !refresh}
            data={data}
            isData={!!data?.length}
            count={bankBalSheetState?.total_item || 0}
            pagination={filter}
            isExpandable={false}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
                isToast: false,
              });
            }}
            onColumnsChange={(columns) => {
              setFilterColumns(columns);
            }}
            paginationOptions={TRA_PAGINATION_OPTIONS}
          >
            {data?.map((item) => {
              return (
                <TableRow
                  tdClassName="position-relative btncell-height"
                  item={item}
                  columns={columns}
                  isExpandable={false}
                  filterColumns={filterColumns}
                />
              );
            })}
          </Table>
        </Card>
      </div>
      <ManualBankEntryModel
        isBalSheet={bankId}
        DRList={getDRBanklistOptions()}
        isManualBankEntryModel={isManualBankEntryModel}
        onCloseModal={() => onCloseModal()}
        onGet={() => setFilter({ ...filter, isToast: false })}
      />
      <GenerateReportModal
        isGenerateReportModal={isGenerateReportModal}
        onCloseGenerateRepostModal={onCloseGenerateRepostModal}
        payload={preparePayload(filter)}
      />
      <UpdateBankTransactionModal
        isOpen={isUpdateBankTransactionOpen}
        onCloseUpdateBankTransaction={onCloseUpdateBankTransaction}
        onGet={() => setFilter({ ...filter, isToast: false })}
      />
    </>
  );
};

export default BankBalSheet;
