import React, { useState } from "react";
import {
  get_merchant_list,
  get_payout_ticket_summary,
} from "../../../redux/slices/payinPayoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
} from "reactstrap";
import { IoFilterOutline } from "react-icons/io5";
import { Switch } from "@mui/material";
import { Table, TableRow } from "../../../components/Table";
import {
  dateFormatter,
  onFixed,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import { Merchant_Pay_In_Available_Payment_Get } from "../../../redux/slices/merchantSlice";
import { Filter_Data_Transaction, PaymentMetaLabelListGet } from "../../../redux/slices/transactionSlice";
import Export from "../../../components/Custom/Elements/Export/Export";
import { DROPDOWN_ALL_VALUE } from "../../../constant";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import { useRole } from "../../../redux/slices/authSlice";

const PayoutTicketSummary = () => {
  const dispatch = useDispatch();
  const { getPayoutTicketSummaryLoading, getPayoutTicketSummaryState } = useSelector(
    (state) => state.payinPayout
  );

  const { transactionFilterState, paymentMetaLabelListGetData } = useSelector((state) => state.transaction);

  const role = useRole("Payout Ticket Summary");

  const ticketSizeOptions = [
    { value: "500", label: "500" },
    { value: "1000", label: "1000" },
    { value: "5000", label: "5000" },
    { value: "15000", label: "15000" },
    { value: "50000", label: "50000" },
    { value: "100000", label: "100000" },
  ];
  const prepareMerchantOptions = () => {
    const options = transactionFilterState?.cilent_data
      ?.slice()
      ?.sort((a, b) => a?.merchantName?.localeCompare(b?.merchantName))
      ?.map((val) => {
        const clientDataOption = {
          value: val?.merchantId,
          label: val?.merchantName,
        };
        return clientDataOption;
      });
    return options;
  };

  const getFilterTransactions = async () => {
    try {
      await dispatch(Filter_Data_Transaction()).unwrap();
    } catch (err) { }
  };

  const initialValues = {
    date: [null, null],
    isFilter: false,
    merchantId: DROPDOWN_ALL_VALUE,
    pgName: DROPDOWN_ALL_VALUE,
    bankId: DROPDOWN_ALL_VALUE,
    tiketSize: ticketSizeOptions?.[0]?.value, // required field issue
  };

  const [filter, setFilter] = useState(initialValues);
  const [filterColumns, setFilterColumns] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const columns = [
    {
      title: "TXN RANGE",
      name: "txnRange",
    },
    {
      title: "PG NAME",
      name: "pgName",
    },
    {
      title: "BANK NAME",
      name: "bankName",
    },

    {
      title: "SUCCESS TXN",
      name: "success_txn_count",
      Cell: (data) => {
        return (
          <>
            <span>{seperator(data?.success_txn_count, false)}</span>
          </>
        );
      },
    },
    {
      title: "initialized TXN",
      name: "initialized_txn_count",
      Cell: (data) => {
        return (
          <>
            <span>{seperator(data?.initialized_txn_count, false)}</span>
          </>
        );
      },
    },
    {
      title: "pending TXN",
      name: "pending_txn_count",
      Cell: (data) => {
        return (
          <>
            <span>{seperator(data?.pending_txn_count, false)}</span>
          </>
        );
      },
    },
    {
      title: "failed TXN",
      name: "failed_txn_count",
      Cell: (data) => {
        return (
          <>
            <span>{seperator(data?.failed_txn_count, false)}</span>
          </>
        );
      },
    },
    {
      title: "TOTAL TXN",
      name: "totalTxn",
      Cell: (data) => {
        return (
          <>
            <span>{seperator(data?.totalTxn, false)}</span>
          </>
        );
      },
    },
    {
      title: "TOTAL AMT",
      name: "totalPaymentAmt",
      Cell: (data) => {
        return (
          <>
            <span>{seperator(data?.totalPaymentAmt)}</span>
          </>
        );
      },
    },
    {
      title: "SUCCESS AMT",
      name: "success_payment_amount",
      Cell: (data) => {
        return (
          <>
            <span>{seperator(data?.success_payment_amount)}</span>
          </>
        );
      },
    },
    {
      title: "initialized AMT",
      name: "initialized_payment_amount",
      Cell: (data) => {
        return (
          <>
            <span>{seperator(data?.initialized_payment_amount)}</span>
          </>
        );
      },
    },
    {
      title: "pending AMT",
      name: "pending_payment_amount",
      Cell: (data) => {
        return (
          <>
            <span>{seperator(data?.pending_payment_amount)}</span>
          </>
        );
      },
    },
    {
      title: "Failed AMT",
      name: "failed_payment_amount",
      Cell: (data) => {
        return (
          <>
            <span>{seperator(data?.failed_payment_amount)}</span>
          </>
        );
      },
    },
    {
      title: "SUCCESS RATE",
      name: "success_txn_per",
      Cell: (data) => {
        return (
          <div className="themecolor">
            <span>{onFixed(data?.success_txn_per)}%</span>
          </div>
        );
      },
    },
    {
      title: "initialized RATE",
      name: "initialized_txn_per",
      Cell: (data) => {
        return (
          <div className="themecolor">
            <span>{onFixed(data?.initialized_txn_per)}%</span>
          </div>
        );
      },
    },
    {
      title: "pending RATE",
      name: "pending_txn_per",
      Cell: (data) => {
        return (
          <div className="themecolor">
            <span>{onFixed(data?.pending_txn_per)}%</span>
          </div>
        );
      },
    },
    {
      title: "failed RATE",
      name: "failed_txn_per",
      Cell: (data) => {
        return (
          <div className="themecolor">
            <span>{onFixed(data?.failed_txn_per)}%</span>
          </div>
        );
      },
    },

    /* {
      title: "PHONEPE ",
      name: "total_PhonePe_txn",
      Cell: (data) => (
        <>
          <div
            className={`${data?.isOpen ? "cell-order-white" : "cell-order"} `}
          >
            <span className="bg-textsuccess"> {data?.phonepay_success_count || "0"} / </span>
            <span className="bg-textpending"> {data?.phonepay_pending_count || "0"} </span>
          </div>
        </>
      ),
    },
    {
      title: "PAYTM",
      name: "total_PayTM_txn",
      Cell: (data) => (
        <>
          <div
            className={`${data?.isOpen ? "cell-order-white" : "cell-order"} `}
          >
            <span className="bg-textsuccess"> {data?.paytm_success_count || "0"} / </span>
            <span className="bg-textpending"> {data?.paytm_pending_count || "0"} </span>
          </div>
        </>
      ),
    },
    {
      title: "GOOGLEPAY",
      name: "total_GooglePay_txn",
      Cell: (data) => (
        <>
          <div
            className={`${data?.isOpen ? "cell-order-white" : "cell-order"} `}
          >
            <span className="bg-textsuccess"> {data?.googlepay_success_count || "0"} / </span>
            <span className="bg-textpending"> {data?.googlepay_pending_count || "0"} </span>
          </div>
        </>
      ),
    },
    {
      title: "OTHER",
      name: "success_other_txn",
      Cell: (data) => (
        <>
          <div
            className={`${data?.isOpen ? "cell-order-white" : "cell-order"} `}
          >
            <span className="bg-textsuccess"> {data?.other_success_count || "0"} / </span>
            <span className="bg-textpending"> {data?.other_pending_count || "0"} </span>
          </div>
        </>
      ),
    }, */

  ];

  const preparepgDataOptions = () => {
    const options = transactionFilterState?.pgpayout_data
      ?.slice()
      ?.sort((a, b) => a?.pg_name?.localeCompare(b?.pg_name))
      ?.map((val) => {
        const pgDataOption = { value: val?.pg_id, label: val?.pg_name };

        return pgDataOption;
      });
    return options;
  };

  const getpgAccDataOptions = () => {
    const options = paymentMetaLabelListGetData?.data
      ?.slice()
      ?.sort((a, b) => a?.note?.localeCompare(b?.note))
      ?.map((val) => {
        const pgAccDataOption = { value: val?.acId, label: val?.note };
        return pgAccDataOption;
      });
    return options;
  };

  const Get_ticket_summary = async () => {
    try {
      const { isFilter, date, merchantId, tiketSize, pgName, ...rest } = filter;
      const pgNamee = transactionFilterState?.pgpayout_data?.find((data) => { return data?.pg_id == pgName })

      const payload = {
        startDate: dateFormatter(isFilter ? date?.[0] : new Date(), "start"),
        endDate: dateFormatter(
          isFilter ? date?.[1] : new Date(),
          "end",
          date?.[0]
        ),
        tiketSize,
        filter_data: {
          merchantId,
          pgName: pgNamee?.pg_name || "All",
          ...rest,
        },
      };
      const res = await dispatch(get_payout_ticket_summary(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
    } catch (err) { }
  };

  useEffect(() => {
    getFilterTransactions();
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("payoutTicketSummary-list")) {
      Get_ticket_summary();
    }
  }, [filter]);

  const Get_merchant_list = async () => {
    try {
      await dispatch(get_merchant_list()).unwrap();
    } catch (err) { }
  };

  const onGetAvailablePayment = async () => {
    try {
      await dispatch(Merchant_Pay_In_Available_Payment_Get()).unwrap();
    } catch (err) { }
  };

  useEffect(() => {
    Get_merchant_list();
    // onGetAvailablePayment();
  }, []);

  const prepareCsvProps = () => {
    const filename = "View Statement.csv";
    const data = getPayoutTicketSummaryState?.summary || [];
    
    const headers =
      columns?.map((val) => ({
        label: val?.title,
        key: val?.name,
      })) || [];
    return {
      filename,
      data,
      headers,
    };
  };

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      isFilter: true,
    });
  };

  const { control, getValues, handleSubmit, reset, watch } = useForm({
    defaultValues: initialValues,
  });

  const PaymentMetaLabelList = async () => {
    try {
      const pgName = transactionFilterState?.pgpayout_data?.find((data) => { return data?.pg_id == watch("pgName") })
      let payload = {
        pgType: "payin",
        pgName: pgName?.pg_name ? pgName?.pg_name : "All",
        pg_id: watch("pgName") || "all",
      };
      await dispatch(PaymentMetaLabelListGet(payload)).unwrap();
    } catch (e) { }
  };

  useEffect(() => {
    if (watch("pgName")) {
      PaymentMetaLabelList();
    }
  }, [watch("pgName")]);

  if (!role.includes("payoutTicketSummary-list")) {
    return;
  }

  return (
    <>
      <div className="support-log-main-div">
        <Card>
          <CardHeader className="flex-column  align-items-start">
            <div className="d-flex align-items-center w-100 justify-content-between">
              <div>
                <h4 className="main-title">
                  <b>Payout Ticket Summary</b>
                </h4>
              </div>

              <div className="d-flex">
                <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                  <DropdownToggle
                    className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                    id="page-header-user-dropdown"
                    tag="button"
                  >
                    <IoFilterOutline fill="#7367F0" />
                  </DropdownToggle>
                  <DropdownMenu
                    className="z-3"
                    style={{
                      whiteSpace: "nowrap",
                      padding: 20,
                      transform: "translate(-20px, 22px)",
                    }}
                  >
                    <li className="z-3 text-dark">
                      <span>
                        <i className="fa-solid fa-filter"></i> Filter Column
                      </span>
                    </li>
                    <div className="border-bottom border-light mt-2">
                      {columns?.map((column) => {
                        return (
                          <div className="d-flex justify-content-between">
                            <p>{column?.title}</p>
                            <Switch
                              checked={filterColumns?.includes(column?.name)}
                              size="small"
                              onChange={(e) => {
                                const newData = [...filterColumns];
                                const index = newData?.indexOf(column?.name);
                                if (index > -1) {
                                  newData.splice(index, 1);
                                } else {
                                  newData.push(column?.name);
                                }
                                setFilterColumns(newData);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <Form
              className="d-flex my-xl-auto right-content align-items-end button-space"
              style={{ flexWrap: "wrap" }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <ControlledDropDown
                name="tiketSize"
                label="Payout Ticket Size"
                isDefaultOption={false}
                options={ticketSizeOptions}
                control={control}
              />

              <ControlledDropDown
                name="merchantId"
                label="Merchant"
                id="merchant_id"
                isDefaultOption={DROPDOWN_ALL_VALUE}
                options={prepareMerchantOptions()}
                control={control}
              />
              <ControlledDropDown
                label="PG"
                name="pgName"
                isDefaultOption={DROPDOWN_ALL_VALUE}
                options={preparepgDataOptions()}
                control={control}
              />
              <ControlledDropDown
                label="PG Account"
                name="bankId"
                isDefaultOption={DROPDOWN_ALL_VALUE}
                options={getpgAccDataOptions()}
                control={control}
              />
              <ControlledDatePicker
                name="date"
                maxDate={new Date()}
                placeholder="Select Date"
                label="Date"
                selectsRange
                control={control}
              />
              <Button type="submit" color="primary">
                Apply
              </Button>
              <Button
                type="button"
                color="danger"
                onClick={() => {
                  setFilter({ ...initialValues });
                  reset();
                }}
              >
                Clear
              </Button>
              {!getPayoutTicketSummaryLoading ? <Export
                {...prepareCsvProps()}
                className="btn btn-primary rounded"
              >
                Generate Report
              </Export> : null}
            </Form>
          </CardBody>
        </Card>
        <Card>
          <Table
            columns={columns}
            isLoading={getPayoutTicketSummaryLoading}
            data={getPayoutTicketSummaryState?.summary || []}
            isData={!!getPayoutTicketSummaryState?.summary?.length}
            filterColumns={filterColumns}
            isExpandable={false}
          >
            {getPayoutTicketSummaryState?.summary?.map((item) => {
              return (
                <TableRow
                  columns={columns}
                  item={item}
                  filterColumns={filterColumns}
                  isExpandable={false}
                />
              );
            })}
          </Table>
        </Card>
      </div>
    </>
  );
};

export default PayoutTicketSummary;
