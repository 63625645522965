import React from "react";
import { Label } from "reactstrap";
import Select from "react-select";
import {
  DROPDOWN_ALL_LABEL,
  DROPDOWN_ALL_VALUE,
  DROPDOWN_SEARCH_LABEL,
  DROPDOWN_SEARCH_VALUE,
  DROPDOWN_SELECT_LABEL,
  DROPDOWN_SELECT_VALUE,
} from "../../../../constant";

const defaultOptionTypes = {
  [DROPDOWN_ALL_VALUE]: {
    value: DROPDOWN_ALL_VALUE,
    label: DROPDOWN_ALL_LABEL,
  },
  Select: {
    value: DROPDOWN_SELECT_VALUE,
    label: DROPDOWN_SELECT_LABEL,
  },
  [DROPDOWN_SEARCH_VALUE]: {
    value: DROPDOWN_SEARCH_VALUE,
    label: DROPDOWN_SEARCH_LABEL,
  },
};

const DropDown = (props) => {
  const {
    defaultValue,
    options = [],
    name,
    className = "",
    inputClassName = "",
    label,
    value,
    styles = {},
    isMulti,
    onChange,
    errors,
    width,
    placeholder,
    isDisabled = false,
    isDefaultOption = 'Select',
    isClearable = false,
    err,
    ...rest
  } = props;

  const message = errors?.[name]?.message ? errors?.[name]?.message : "" || err;

  const style = {
    control: (provided) => ({
      ...provided,
      width: "width",
      minHeight: "30px",
      height: "35px",
      borderRadius: "6px",
      borderColor: "#c9d5e1",
      fontSize: 14,
    }),
    valueContainer: (provided) => ({
      ...provided,
      overflowX: "scroll",
      flexWrap: "nowrap",
      marginRight: "0px",
      width: className == "fixed-dropdown" ? "155px" : "",
      overflowX: className == "fixed-dropdown" ? "scroll" : "",
    }),
    multiValue: (provided) => ({
      ...provided,
      minWidth: "unset",
    }),
    menu: (style) => ({
      ...style,
      zIndex: 99999,
      borderRadius: "10px",
      width: "fit-content",
    }),
    input: (style) => ({
      ...style,
      fontSize: 14,
    }),
    singleValue: (style) => ({
      ...style,
      fontSize: 14,
      color: "#6e6b7b",
    }),
    placeholder: (style) => ({
      ...style,
      fontSize: 14,
    }),
    option: (style) => ({
      ...style,
      padding: "2px 5px",
      fontSize: 13,
      whiteSpace: "nowrap",
      width: "100%",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "0 5px",
    }),
    container: (style) => ({
      ...style,
      marginRight: 0,
    }),
    ...styles,
  };

  const prepareDefaultOptions = () => {
    return (
      defaultOptionTypes?.[isDefaultOption] ||
      defaultOptionTypes?.Select
    );
  };

  const prepareOptions = () => {
    return !!isDefaultOption ? [prepareDefaultOptions(), ...options] : options;
  };

  const perpareValue = () => {
    return isMulti
      ? prepareOptions()?.filter((option) => value?.includes(option?.value))
      : prepareOptions()?.find((option) => option?.value === value) || "";
  };

  return (
    <div
      className={`${className} select-div`}
      onClick={(e) => e.stopPropagation()}
    >
      {label ? <Label>{label}</Label> : null}
      <Select
        name={name}
        isDisabled={isDisabled}
        styles={style}
        onChange={(e, option) => {
          onChange?.(e, option);
        }}
        className={`${inputClassName} w-100`}
        options={prepareOptions()}
        isMulti={isMulti}
        isClearable={isClearable}
        value={perpareValue()}
        // defaultValue={options?.[0]?.label}
        placeholder={placeholder}
        {...rest}
      />
      {message ? (
        <span style={{ color: "red", fontSize: 12 }}>{message}</span>
      ) : null}
    </div>
  );
};

export default DropDown;