import React, { useEffect, useState } from "react";
import {
  payment_banklimit_data_get,
  payment_banklimit_get,
  usePaymentBankLimit,
} from "../../../redux/slices/payinPayoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "reactstrap";
import { Table, TableRow } from "../../../components/Table";
import { seperator, sortByTodataDeposit, onFixed } from "../../../helperFunctions";
import PayInBankAmount from "./PayInBankAmount";
import { ProgressBar } from "../../../components/Custom/Elements/ProgressBar/ProgressBar";
import { TRA_FILTER, TRA_PAGINATION_OPTIONS } from "../../../constant";
import { useAutoRefreshTime, useRole } from "../../../redux/slices/authSlice";

const PayInBankLimit = ({ refresh, filter, setFilter }) => {
  const dispatch = useDispatch();
  const [updateDetails, setUpdateDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const paymentBankLimitState = usePaymentBankLimit();
  const autoRefreshTime = useAutoRefreshTime();

  const { getpaymentBankLimitLoading } = useSelector(
    (state) => state.payinPayout
  );
  const [filterPaymentBankLimitColumns, setFiltePaymentBankLimitrColumns] =
    useState([]);
  const role = useRole("Bank Limit");

  const paymentBankLimitColumns = [
    {
      title: "LABEL",
      name: "pg_label",
      Cell: (data) => {
        return (
          <>
            <span>
              {data?.pg_label} ({data?.pg_id})
            </span>
          </>
        );
      },
    },
    {
      title: "PG",
      name: "activemeta",
    },
    {
      title: "AC NUMBER",
      name: "account_number",
    },
    {
      title: "BANK NAME",
      name: "bank_name",
    },
    {
      title: "DAILY LIMIT",
      name: "daily_limit",
      Cell: (data) => {
        return (
          <>
            {
              role.includes("update-deposit-bank-limit") ?
                <PayInBankAmount
                  updateDetails={updateDetails}
                  setUpdateDetails={setUpdateDetails}
                  data={data}
                  PaymentBanklimitGet={() => {
                    PaymentBanklimitGet(true);
                  }}
                  setIsLoading={setIsLoading}
                /> : <span>
                  {data?.daily_limit}
                </span>
            }

          </>
        );
      },
    },
    {
      title: "CURRENT TURNOVER",
      name: "current_turnover",
      Cell: (data) => {
        const per = (+data?.current_turnover / +data?.daily_limit) * 100 || 0;
        return (
          <>
            <span style={{ color: per > 79 ? "red" : "" }}>
              {seperator(data?.current_turnover)}
            </span>
          </>
        );
      },
    },
    {
      title: "PENDING LIMIT",
      name: "pending_limit",
      Cell: (data) => {
        return (
          <>
            <span
              style={{
                color: +data?.pending_limit < 0 ? "#ff5757" : "black",
              }}
            >
              {seperator(data?.pending_limit)}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "LIMIT(%)",
    //   name: "pending_limit",
    //   Cell: (data) => {
    //     const pendingLimit =
    //       (+data?.daily_limit || 0) - (+data?.current_turnover || 0);
    //     const todata =
    //       (+data?.current_turnover / +data?.daily_limit) * 100 || 0;
    //     const progressPer =
    //       (data?.daily_limit < data?.current_turnover ||
    //         data?.daily_limit == data?.current_turnover)
    //         ? 0
    //         : +todata >= 100
    //           ? 100
    //           : todata < 0
    //             ? 0
    //             : todata;

    //     return (
    //       <>
    //         <div className="position-relative"  style={{width:"170px"}}>
    //           <Box sx={{ flexGrow: 1 }}>
    //             <LinearProgress
    //               sx={{
    //                 height: 20,
    //                 borderRadius: 1,
    //                 backgroundColor:
    //                   (data?.daily_limit < data?.current_turnover ||
    //                     data?.daily_limit == data?.current_turnover)
    //                     ? "#ff5757"
    //                     : "#5195d8ed",
    //               }}
    //               variant="determinate"
    //               value={progressPer}
    //             />
    //           </Box>
    //           <div
    //             style={{ fontSize: "12px" }}
    //             className="progress-position text-center"
    //           >
    //             <span style={{ color: "white" }}>
    //               {(data?.daily_limit < data?.current_turnover ||
    //                 data?.daily_limit == data?.current_turnover)
    //                 ? 100
    //                 : todata < 0
    //                   ? 100
    //                   : onFixed(progressPer)}
    //               %
    //             </span>
    //           </div>
    //         </div>
    //         <div style={{ fontSize: "12px" }}>
    //           {seperator(pendingLimit, false)} pending of{" "}
    //           {seperator(data?.daily_limit, false)}
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      title: "LIMIT(%)",
      name: "pending_limit",
      Cell: (data) => {
        const pendingLimit =
          (+data?.daily_limit || 0) - (+data?.current_turnover || 0);
        const todata =
          (+data?.current_turnover / +data?.daily_limit) * 100 || 0;

        return (
          <div className="position-relative">
            <ProgressBar
              done={todata}
              pendingLimit={pendingLimit}
              dailyLimit={data?.daily_limit}
            />
          </div>
        );
      },
    },
  ];

  const PaymentBanklimitGet = async () => {
    try {
      setIsLoading(true);

      const { isFilter, isActive, ...rest } = filter;
      const payload = {
        filter_data: {
          isActive: isActive,
        },
        ...rest,
      };
      await dispatch(payment_banklimit_get(payload)).unwrap();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("deposit-bankLimit-list")) {
      PaymentBanklimitGet();
    }
  }, [filter]);

  useEffect(() => {
    const newColumns = paymentBankLimitColumns?.map((item) => item?.name);
    setFiltePaymentBankLimitrColumns(newColumns);
  }, []);

  const PayoutBanklimitDataGet = async () => {
    try {
      await dispatch(payment_banklimit_data_get()).unwrap();
    } catch (err) { }
  };

  useEffect(() => {
    PayoutBanklimitDataGet();
  }, []);

  useEffect(() => {
    if (role.includes("deposit-bankLimit-list")) {
      const IntervalClick = setInterval(() => {
        if (refresh) {
          PaymentBanklimitGet(true);
        }
      }, autoRefreshTime);
      return () => clearInterval(IntervalClick);
    }
  }, [refresh, filter]);

  if (!role.includes("deposit-bankLimit-list")) {
    return;
  }

  return (
    <div className={`${isLoading && refresh ? "opacity-25" : ""}`}>
      <Card>
        <Table
          columns={paymentBankLimitColumns}
          isLoading={(getpaymentBankLimitLoading || isLoading) && !refresh}
          data={paymentBankLimitState?.data || []}
          isData={!!paymentBankLimitState?.data?.length}
          filterColumns={filterPaymentBankLimitColumns}
          count={paymentBankLimitState?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFiltePaymentBankLimitrColumns(columns);
          }}
          paginationOptions={TRA_PAGINATION_OPTIONS}
        >
          {sortByTodataDeposit(paymentBankLimitState?.data || [])?.map(
            (item) => {
              return (
                <TableRow
                  columns={paymentBankLimitColumns}
                  item={item}
                  filterColumns={filterPaymentBankLimitColumns}
                  isExpandable={false}
                />
              );
            }
          )}
        </Table>
      </Card>
    </div>
  );
};

export default PayInBankLimit;
