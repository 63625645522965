import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { view_Statement } from "../../../../redux/slices/merchantSlice";
import { Button, Modal } from "reactstrap";
import { Table, TableRow } from "../../../Table";
import Export from "../../Elements/Export/Export";
import { seperator } from "../../../../helperFunctions";

const ViewStatement = (props) => {
  const { id, onCloseViewStatement, viewStatement } = props;
  const { viewStatementState, viewStatementStateLoading } = useSelector(
    (state) => state.merchant
  );

  const [filterColumns, setFilterColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({
    page_no: 1,
    limit: 10,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const { limit, page_no } = filter;

    setTableData(
      viewStatementState?.data?.length
        ? viewStatementState?.data?.slice(
            limit * (page_no - 1),
            limit * (page_no - 1) + limit
          )
        : []
    );
  }, [viewStatementState, filter]);

  const hasUnsettledBalance = tableData?.some?.(
    (item) => item?.unsettled_balance !== undefined
  );

  const columns = [
    {
      title: "DATE",
      name: "due_date",
    },
    {
      title: "OPEN BALANCE",
      name: "initial_balance",
      Cell: (data) => {
        return <>{`${seperator(data?.initial_balance)}`}</>;
      },
    },
    {
      title: "DEPOSIT",
      name: "deposit",
      Cell: (data) => {
        return <>{`${seperator(data?.deposit)}`}</>;
      },
    },
    hasUnsettledBalance && {
      title: "UNSETTLED BALANCE",
      name: "unsettled_balance",
      Cell: (data) => {
        return <>{`${seperator(data?.unsettled_balance)}`}</>;
      },
    },
    {
      title: "WITHDRAWAL",
      name: "withdrawal",
      Cell: (data) => {
        return <>{`${seperator(data?.withdrawal)}`}</>;
      },
    },
    {
      title: "REFUND",
      name: "repayment",
      Cell: (data) => {
        return <>{`${seperator(data?.repayment)}`}</>;
      },
    },
    {
      title: "CLOSING BALANCE",
      name: "ending_balance",
      Cell: (data) => {
        return <>{`${seperator(data?.ending_balance)}`}</>;
      },
    },
  ].filter(Boolean);

  const Statement_View = async (payload) => {
    try {
      await dispatch(view_Statement({ ...payload, merchantId: id })).unwrap();
    } catch (err) {}
  };

  const prepareCsvProps = () => {
    const filename = "View Statement.csv";
    const data = viewStatementState?.data || [];
    const headers =
      columns?.map((val) => ({
        label: val?.title,
        key: val?.name,
      })) || [];

    return {
      filename,
      data,
      headers,
    };
  };

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, [tableData]);

  useEffect(() => {
    if (viewStatement) {
      Statement_View(filter);
    }
  }, [viewStatement]);

  return (
    <Modal
      className="add-manual-payout-modal modal-xl"
      isOpen={viewStatement}
      toggle={onCloseViewStatement}
      centered={true}
    >
      <div className="modal-header ">
        <h3 className="modal-title mt-0">Merchant View Statement</h3>
        <i
          onClick={() => {
            onCloseViewStatement();
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
        <Table
          columns={columns}
          isLoading={viewStatementStateLoading}
          data={tableData || []}
          isData={!!viewStatementState?.data?.length}
          isExpandable={false}
          filterColumns={filterColumns}
          count={viewStatementState?.data?.length || 0}
          pagination={filter}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {tableData?.map((item) => {
            return (
              <TableRow
                columns={columns}
                item={item}
                filterColumns={filterColumns}
                isExpandable={false}
              />
            );
          })}
        </Table>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button className="graybutton" onClick={() => onCloseViewStatement()}>
            Cancel
          </Button>
          <Export {...prepareCsvProps()} className="submitbutton rounded">
            Generate Report
          </Export>
        </div>
      </div>
    </Modal>
  );
};

export default ViewStatement;
