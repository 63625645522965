import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Card, CardHeader, Form } from "reactstrap";
import InputField from "../../../components/Custom/Forms/InputField/InputField";
import DropDown from "../../../components/Custom/Forms/DropDown/DropDown";
import { useFormik } from "formik";
import {
  currentTransactionGet,
  ssTransactionUpdate,
} from "../../../redux/slices/ssTransactionSlice";
import { Table, TableRow } from "../../../components/Table";
import CopyToClipboard from "react-copy-to-clipboard";
import BackIcon from "../../../components/Custom/Elements/BackIcon/BackIcon";
import { responseToaster, seperator } from "../../../helperFunctions";
import { useRole } from "../../../redux/slices/authSlice";

const options = [
  {
    value: "Transaction Success",
    label: "Transaction Success",
  },
  {
    value: "UTR Mismatch",
    label: "UTR Mismatch",
  },
  {
    value: "Value differ in both",
    label: "Value differ in both",
  },
  {
    value: "Not belongs to us",
    label: "Not belongs to us",
  },
  {
    value: "Fake Screenshot",
    label: "Fake Screenshot",
  },
  {
    value: "Already Approve",
    label: "Already Approve",
  },
  {
    value: "Requested amount and screenshot amount mismatched",
    label: "Requested amount and screenshot amount mismatched",
  },
  {
    value: "Amount not received",
    label: "Amount not received",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const SsTransactionDetails = () => {
  const role = useRole("SS Deposit");
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [filterColumns, setFilterColumns] = useState([]);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    paymentAmt: data?.paymentAmt,
    utr: "",
    amount: "",
    remark: "",
  };

  const onSubmit = async (values) => {
    try {
      const payload = {
        ...values,
        txnId: params?.id,
        action: values?.remark === "Transaction Success" ? "SUCCESS" : "FAILED",
      };
      const res = await dispatch(ssTransactionUpdate(payload)).unwrap();
      responseToaster(res);
      resetForm();
    } catch (err) {}
  };

  const { values, handleSubmit, resetForm, setValues } = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
  });

  const onGetCurrentSsTransaction = async () => {
    try {
      setIsLoading(true);
      const payload = {
        txnId: params?.id,
      };
      const res = await dispatch(currentTransactionGet(payload)).unwrap();
      setData(res?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("ssTransaction-list")) {
      onGetCurrentSsTransaction();
    }
  }, []);

  const columns = [
    {
      title: "Transaction ID",
      name: "transaction_id",
    },
    {
      title: "Customer",
      name: "customer_name",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Name : </span>
                  {data?.customer_name || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Mail : </span>
                  {data?.customer_email || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> No. : </span>
                  {data?.customer_mobile || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "Amount",
      name: "payment_amount",
      Cell: (data) => <>{`${seperator(data?.payment_amount)}`}</>,
    },
    {
      title: "Status",
      name: "payment_status",
    },
    {
      title: "Temp UTR",
      name: "temp_bank_utr",
      Cell: (data) => {
        return (
          <div className="cell-order">
            <div className="order-singal-div d-flex gap-1 align-items-center">
              {data?.temp_bank_utr ? (
                <>
                  <p>{data?.temp_bank_utr || ""}</p>
                  <div>
                    <CopyToClipboard text={data?.temp_bank_utr}>
                      <i class="fa-solid fa-copy cursor-pointer"></i>
                    </CopyToClipboard>
                  </div>
                </>
              ) : (
                "-"
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: "UTR",
      name: "bank_rrn",
    },
  ];

  const prepareData = () => (data ? [data] : []);
  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-8 my-50 colpadding ">
          <Card className="tras-sumheight">
            <CardHeader style={{ display: "unset" }}>
              <div className="d-flex align-items-center button-space">
                <BackIcon
                  className="btn-smallsize"
                  onClick={() => {
                    navigate("/ss-transaction");
                  }}
                />
                <h4 className="main-title">
                  <b>Transaction Detail</b>
                </h4>
              </div>
              <div className="mt-50">
                <Table
                  minHeight={100}
                  isLoading={isLoading}
                  columns={columns}
                  data={prepareData()}
                  isData={!!prepareData()?.length}
                  isExpandable={false}
                  filterColumns={filterColumns}
                  onColumnsChange={(columns) => {
                    setFilterColumns(columns);
                  }}
                >
                  {prepareData()?.map((item) => {
                    return (
                      <TableRow
                        isExpandable={false}
                        columns={columns}
                        item={item}
                        filterColumns={filterColumns}
                      ></TableRow>
                    );
                  })}
                </Table>
              </div>

              {role.includes("ss-transaction-action") ? (
                <Form onSubmit={handleSubmit} className="pt-2">
                  <InputField
                    type="number"
                    name="utr"
                    label="UTR"
                    placeholder="Enter UTR"
                    value={values?.utr}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        utr: e.target.value,
                      });
                    }}
                  />
                  <InputField
                    name="amount"
                    type="number"
                    placeholder="Enter Amount"
                    label="Amount"
                    value={values?.amount}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        amount: e.target.value,
                      });
                    }}
                  />
                  <DropDown
                    label="Remark"
                    id="remark"
                    name="remark"
                    menuPlacement="top"
                    placeholder="Select remark"
                    options={options}
                    value={values?.remark}
                    onChange={(selectedOption) => {
                      setValues({
                        ...values,
                        remark: selectedOption.value,
                      });
                    }}
                  />{" "}
                  <Button type="submit" color="primary" className="mt-50">
                    Apply
                  </Button>
                </Form>
              ) : null}
            </CardHeader>
          </Card>
        </div>
        <div className="col-lg-4 my-50">
          <Card className="tras-sumheight">
            <CardHeader style={{ display: "unset" }}>
              <div className="trans-imgwidth">
                <img src={data?.screenshot} alt="" className="img-fluid" />
              </div>
            </CardHeader>
          </Card>
        </div>
      </div>
    </>
  );
};

export default SsTransactionDetails;
