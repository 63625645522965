import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Modal, Row } from "reactstrap";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import { responseToaster } from "../../../helperFunctions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { passwordReset } from "../../../redux/slices/smsSlice";

const ResetPassword = (props) => {
  const { onCloseUserResetPassword, userResetPassword } = props;
  const dispatch = useDispatch();

  const [isButtonLoading, setIsLoadingResetPassword] = useState(false);
  const [isCopy, setIsCopy] = useState(false);


  const onResetPass = async () => {
    try {
      setIsLoadingResetPassword(true)
      const res = await dispatch(passwordReset({ id: userResetPassword?.id })).unwrap();
      setIsCopy({ ...res?.data, userName: userResetPassword?.user_name });
      responseToaster(res);
      setIsLoadingResetPassword(false)

    } catch (err) {
      setIsLoadingResetPassword(false)

    }
  };

  const onClose = () => {
    onCloseUserResetPassword();
    setIsCopy(false);
  };
  return (
    <Modal isOpen={userResetPassword} toggle={onClose} centered={true}>
      {isCopy?.password ? (
        <>
          <div>
            <div className="modal-header">
              <h3 className="modal-title mt-0">Reset Password!</h3>
              <i
                onClick={() => {
                  onClose();
                }}
                className="fa-solid fa-xmark"
              ></i>
            </div>
            <div className="modal-body py-4">
              <p className="mb-50">
                <span>Username :- </span>
                <span className="bold">{isCopy?.userName}</span>
              </p>
              <p>
                <span>Password :- </span>
                <span className="bold">{isCopy?.password}</span>
              </p>
            </div>
            <div className="modal-footer d-flex align-items-center justify-content-end">
              <CopyToClipboard text={`Username :${isCopy?.userName} \nPassword :${isCopy?.password}`}>
                <Button className="submitbutton" onClick={() => onClose()}>
                  Copy & Close
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="modal-header">
            <h3 className="modal-title mt-0">Reset Password !</h3>
            <i
              onClick={() => {
                onClose();
              }}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <div className="modal-body">
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <p> Are you sure you want to reset user password ?</p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <div className="d-flex justify-content-end button-space">
              <Button className="graybutton" onClick={() => onClose()}>
                Cancel
              </Button>
              <CustomeButton
                onClick={onResetPass}
                className="submitbutton"
                isButtonLoading={isButtonLoading}
              >
                Submit
              </CustomeButton>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ResetPassword;
