import { Button, Card, CardHeader } from "reactstrap";
import { Table, TableRow } from "../../../components/Table";
import React, { useEffect, useState } from "react";
import { FILTER } from "../../../constant";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../helperFunctions";
import AddUserModal from "./AddRolesModal";
import RolesDeleteModal from "./RoleDeleteModal";
import { rolessGet, useRoles } from "../../../redux/slices/rolesSlice";
import { useRole } from "../../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";

const Roles = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState(FILTER);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteOpenModal, setIsDeleteOpenModal] = useState(false);
  const role = useRole("Role");

  const data = useRoles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    {
      title: "name",
      name: "name",
    },
    {
      title: "guard name",
      name: "guard_name",
    },
    {
      title: "DATE",
      name: "created_at_ist",
    },
    {
      title: "ACTIONS",
      name: "actions",
      Cell: (data) => {
        return (
          <>
            <div className="button-space d-flex">
              {role.includes("role-create") ? (
                <Button
                  title="Edit"
                  color="primary"
                  className="btn-smallsize"
                  onClick={() => {
                    // setIsOpenModal(data);
                    navigate(`/updateRole/${data.id}`)
                  }}
                >
                  Edit
                </Button>
              ) : null}
              {role.includes("role-delete") ? (
                <Button
                  title="Delete Role"
                  color="danger"
                  className="btn-smallsize"
                  onClick={() => setIsDeleteOpenModal(data?.id)}
                >
                  Delete Role
                </Button>
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

  const onGetData = async () => {
    try {
      setIsLoading(true);
      const { isFilter, ...payload } = filter;
      const res = await dispatch(rolessGet(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (role.includes("role-list")) {
      onGetData();
    }
  }, [filter]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  return (
    <div className="merchant-main-div">
      <Card>
        <CardHeader className="flex-column align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h4 className="main-title">
              <b>Roles</b>
            </h4>

            {role.includes("role-create") ? (
              <div
                className="d-flex align-items-center justify-content-around"
                style={{ gap: "10px" }}
              >
                <Button
                  title="Add Role"
                  color="primary"
                  onClick={() => navigate("/addRole")}
                  // onClick={() => setIsOpenModal(true)}
                >
                  Add Role
                </Button>
              </div>
            ) : null}
          </div>
        </CardHeader>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={isLoading}
          data={data?.data}
          isData={!!data?.data?.length}
          count={data?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {data?.data?.map((val) => {
            return (
              <TableRow
                columns={columns}
                filterColumns={filterColumns}
                isExpandable={false}
                item={val}
              />
            );
          })}
        </Table>
      </Card>

      <AddUserModal
        isOpen={isOpenModal}
        onGet={() => setFilter({ ...filter })}
        onHide={() => setIsOpenModal(false)}
      />
      <RolesDeleteModal
        isOpen={isDeleteOpenModal}
        onGet={() => setFilter({ ...filter })}
        onHide={() => setIsDeleteOpenModal(false)}
      />
    </div>
  );
};

export default Roles;
