import { useEffect, useState, Fragment, useRef } from "react";

// UI IMPORT
import { Button, Card, CardHeader, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import { BsThreeDotsVertical } from "react-icons/bs";
import { MdBlock, MdDelete } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { MdOutlineClear } from "react-icons/md";
import { PiHandPointingFill } from "react-icons/pi";
import { IoChevronBack } from "react-icons/io5";
import { TbDeviceMobileCog } from "react-icons/tb";

// THIRD - PARTY IMPORT
import SendNotificationButton from "./SendNotificationButton";
import { useForm } from "react-hook-form";
import { statusOptions1 } from "./mock";
import BankAsignModal from "./BankAsignModal";
import { useParams, useNavigate, useLocation } from "react-router";
import Avatar from "../../../components/Custom/Elements/Avatar/Avatar";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import { Table, TableRow } from "../../../components/Table";
import { nickNameDelete, updateNickNameAdd, userDetailsGet, userDeviceBlock, useUserDetailsData } from "../../../redux/slices/smsSlice";
import { useDispatch } from "react-redux";
import { responseToaster } from "../../../helperFunctions";
import DeleteModal from "../../../components/Custom/Modals/DeleteModal/DeleteModal";
import BackIcon from "../../../components/Custom/Elements/BackIcon/BackIcon";
import { useRole } from "../../../redux/slices/authSlice";

const UserDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterColumns, setFilterColumns] = useState([]);
  const role = useRole("Sms Sync");
  const userDetailsData = useUserDetailsData();

  const unUsedBanks = userDetailsData?.unusebank;

  const [asignBankModal, setAsignBankModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const [updateNickName, setUpdateNickName] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false)
  const [currentData, setCurrentData] = useState({});
  const [isOpen, setIsOpen] = useState("");
  const [isOpenConfirmation, setisOpenConfirmation] = useState(false);

  const location = useLocation();

  const nickID = location?.state?.nickID || "";
  // const nickName = location?.state?.nickName || "";

  const { control, getValues, setValue } = useForm({
    defaultValues: {
      // nickName: nickName || "",
    },
  });
  const handleClearState = () => {
    navigate(location.pathname);
  };

  const onBlockUserDevice = async (nickNameId, is_block) => {
    try {
      const payload = {
        nickNameId: nickNameId,
        isBlock: is_block,
      };
      const res = await dispatch(userDeviceBlock(payload)).unwrap();
      responseToaster(res);
      onGetUserDetails();
    } catch (err) {
    }
  };

  const onDeleteNickUser = async () => {
    try {
      setIsUserLoading(true)
      const res = await dispatch(nickNameDelete({ nickNameId: deleteID })).unwrap();
      setisOpenConfirmation(false);
      onGetUserDetails();
      responseToaster(res);
      setIsUserLoading(false)
    } catch (err) {
      setIsUserLoading(false)
    }
  };

  const toggleDropdown = (id) => {
    setIsOpen(id);
  };

  const columns = [
    {
      title: "#",
      Cell: (data) => (
        <>
          <div className="flex gap-2 relative">
            <div onClick={() => toggleDropdown(data?.id)}>
              <BsThreeDotsVertical className="cursor-pointer" size={18} />
            </div>

            {isOpen === data?.id && (
              <div className="absolute top-2">
                <Dropdown
                  isOpen={isOpen === data?.id}
                  toggle={() => toggleDropdown("")}
                >
                  <DropdownToggle
                    className=" header-item waves-effect border-0 bg-transparent "
                    id="page-header-user-dropdown"
                    tag="button"
                  >
                  </DropdownToggle>
                  <DropdownMenu
                    className="z-3"
                    style={{
                      whiteSpace: "nowrap",
                      padding: 5,
                      gap: 2,
                    }}
                  >
                    <div className="gap-2">
                      {data?.device_version ? (
                        <div className="d-flex align-items-center gap-50 py-1">
                          <TbDeviceMobileCog /> {data?.device_version}
                        </div>
                      ) : null}
                      {role.includes("sms-user-bank-add") ? <div
                        className="cursor-pointer d-flex align-items-center gap-50"
                        onClick={() => {
                          setAsignBankModal(true);
                          setCurrentData(data);
                          toggleDropdown("");
                        }}
                      >
                        <PiHandPointingFill /> Assign Banks
                      </div> : null}
                      {role.includes("block-sms-user") ? <div
                        className="cursor-pointer d-flex align-items-center gap-50 py-1"
                        onClick={() => {
                          toggleDropdown("");
                          onBlockUserDevice(
                            data?.id,
                            data?.is_block === 0 ? 1 : 0
                          );
                        }}
                      >
                        <MdBlock />
                        {data?.is_block
                          ? "UnBlock Device ID"
                          : "Block Device ID"}
                      </div> : null}
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
          </div>
        </>
      ),
    },
    {
      title: "Nickname",
      name: "nick_name",
      Cell: (data) => {
        return (
          <>
            {updateNickName === data?.id || nickID === data?.id && role.includes("update-sms-user") ? (
              <form
                className="d-flex flex-column gap-50"
                onSubmit={(e) => {
                  e.preventDefault();
                  onAddUpdateNickName(data?.id)
                }}
              >
                <ControlledInput
                  name="nickName"
                  placeholder="Enter Nickname"
                  control={control}
                />
                <div className="d-flex gap-50">
                  <Button type="submit" className="small submitbutton">
                    <FaCheck />
                  </Button>
                  <Button
                    variant="Transaparent"
                    className="small graybutton"
                    onClick={() => {
                      setUpdateNickName(null);
                      handleClearState();
                    }}
                  >
                    <MdOutlineClear />
                  </Button>
                </div>
              </form>
            ) : (
              <span
                className={`${role.includes("update-sms-user") ? "text-primary cursor-pointer" : ""} `}
                onClick={() => {
                  if (role.includes("update-sms-user")) {
                    setUpdateNickName(data?.id);
                    setValue(
                      "nickName",
                      data?.nick_name ? data?.nick_name : ""
                    );
                  }
                }}
              >
                {data?.nick_name ? data?.nick_name : "Set Nickname"}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Device ID",
      name: "device_id",
    },
    {
      title: "Bank Details",
      Cell: (data) => (
        <>
          <div className="d-flex flex-column">
            {data?.sms_user_bank_details?.length
              ? data?.sms_user_bank_details?.map((item) => (
                <div>
                  <p className="mb-25">Account: {item?.account_number ?? "-"}</p>
                  <p className="mb-25">UPI: {item?.upi_id ?? "-"}</p>
                  <p className="mb-25">Bank: {item?.bank_name ?? "-"}</p>
                  <p className="mb-25">IFSC: {item?.ifsc_code ?? "-"}</p>
                </div>
              ))
              : "-"}
          </div>
        </>
      ),
    },
    {
      title: "Sync Ago",
      name: "sync_ago",
    },
    {
      title: "Registration Date",
      name: "created_at_ist",
      sortableKey: "created_at_ist",
      sortable: true,
    },
    {
      title: "Last Update",
      name: "updated_at_ist",
      sortableKey: "updated_at_ist",
      sortable: true,
    },
    {
      title: "Battery",
      name: "battery_per",
      Cell: (data) => (
        <>
          <span>{data?.battery_per ? `${data?.battery_per}%` : "-"}</span>
        </>
      ),
    },
    {
      title: "Network strength",
      name: "network_strength",
      Cell: (data) => {
        const fillWeek = "#ff4c4c";
        const fillModerate = "#6565ff";
        const fillGood = "#d6b82e";
        const fillExcellent = "#1acf1a";
        const unFillColor = "#bdbdbd";
        const network_strength = data?.network_strength;
        return (
          <div className="flex justify-center">
            {data?.network_type === "mobile" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-signal-high"
              >
                <path
                  d="M2 20h.01"
                  stroke={`${network_strength === "Weak"
                    ? fillWeek
                    : network_strength === "Moderate"
                      ? fillModerate
                      : network_strength === "Good"
                        ? fillGood
                        : network_strength === "Excellent"
                          ? fillExcellent
                          : unFillColor
                    }`}
                />
                <path
                  d="M7 20v-4"
                  stroke={`${network_strength === "Moderate"
                    ? fillModerate
                    : network_strength === "Good"
                      ? fillGood
                      : network_strength === "Excellent"
                        ? fillExcellent
                        : unFillColor
                    }`}
                />
                <path
                  d="M12 20v-8"
                  stroke={`${network_strength === "Good"
                    ? fillGood
                    : network_strength === "Excellent"
                      ? fillExcellent
                      : unFillColor
                    }`}
                />
                <path
                  d="M17 20V8"
                  stroke={`${network_strength === "Excellent"
                    ? fillExcellent
                    : unFillColor
                    }`}
                />
              </svg>
            ) : data?.network_type === "wifi" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="gray"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path
                  d="M12 20h.01"
                  stroke={`${network_strength === "Weak"
                    ? fillWeek
                    : network_strength === "Moderate"
                      ? fillModerate
                      : network_strength === "Good"
                        ? fillGood
                        : network_strength === "Excellent"
                          ? fillExcellent
                          : unFillColor
                    }`}
                />
                <path
                  d="M8.5 16.429a5 5 0 0 1 7 0"
                  stroke={`${network_strength === "Moderate"
                    ? fillModerate
                    : network_strength === "Good"
                      ? fillGood
                      : network_strength === "Excellent"
                        ? fillExcellent
                        : unFillColor
                    }`}
                />
                <path
                  d="M5 12.859a10 10 0 0 1 14 0"
                  stroke={`${network_strength === "Good"
                    ? fillGood
                    : network_strength === "Excellent"
                      ? fillExcellent
                      : unFillColor
                    }`}
                />
                <path
                  d="M2 8.82a15 15 0 0 1 20 0"
                  stroke={`${network_strength === "Excellent"
                    ? fillExcellent
                    : unFillColor
                    }`}
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-signal-high"
              >
                <path
                  d="M2 20h.01"
                  stroke={`${network_strength === "Weak"
                    ? fillWeek
                    : network_strength === "Moderate"
                      ? fillModerate
                      : network_strength === "Good"
                        ? fillGood
                        : network_strength === "Excellent"
                          ? fillExcellent
                          : unFillColor
                    }`}
                />
                <path
                  d="M7 20v-4"
                  stroke={`${network_strength === "Moderate"
                    ? fillModerate
                    : network_strength === "Good"
                      ? fillGood
                      : network_strength === "Excellent"
                        ? fillExcellent
                        : unFillColor
                    }`}
                />
                <path
                  d="M12 20v-8"
                  stroke={`${network_strength === "Good"
                    ? fillGood
                    : network_strength === "Excellent"
                      ? fillExcellent
                      : unFillColor
                    }`}
                />
                <path
                  d="M17 20V8"
                  stroke={`${network_strength === "Excellent"
                    ? fillExcellent
                    : unFillColor
                    }`}
                />
              </svg>
            )}
          </div>
        );
      },
    },
    {
      title: "Status",
      name: "is_active",
      sortableKey: "is_active",
      sortable: true,
      Cell: (data) => {
        const status = statusOptions1.find(
          (item) =>
            (data?.is_block === 1 ? "Blocked" : data?.is_active) === item?.value
        );

        const isBlocked = data?.is_block === 1;

        return (
          <>
            <div className="d-flex align-items-center gap-50">
              {isBlocked ? (
                <MdBlock className="text-red-500" />
              ) : (
                <span className={`small ${status?.color}`}></span>
              )}
              <span>{status?.label} </span>
            </div>
          </>
        );
      },
    },
    {
      title: "Action",
      Cell: (data) => {
        return <div className="d-flex gap-50">
          {role.includes("delete-sms-user") ? <Button title="delete" color="danger" onClick={(e) => {
            toggleDropdown("");
            setisOpenConfirmation(true);
            setDeleteID(data?.id);
            e.stopPropagation();
          }}>

            <MdDelete size={20} />
          </Button> : null}
          <div className="d-flex">
            {data?.is_active === 0 &&
              data?.is_fcm_token !== 0 &&
              data?.is_block !== 1 && role.includes("send-notification") ? (
              <Button title="send notification" color="primary" >
                <SendNotificationButton
                  className="h-8"
                  classNameJustIcon="cursor-pointer bg-chatlook-secondary rounded-md"
                  justIcon
                  size={16}
                  nickNameId={data?.id ?? ""}
                />
              </Button>
            ) : null}
          </div>
        </div>
      }
    },
  ];


  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const onGetUserDetails = async () => {
    try {
      setIsLoading(true)
      const payload = { userId: id };

      const res = await dispatch(userDetailsGet(payload)).unwrap();

      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)

    }
  };

  const onAddUpdateNickName = async (nickNameId) => {
    try {
      const values = getValues();
      const payload = {
        nickNameId: nickNameId,
        nickName: values?.nickName,
      };

      const res = await dispatch(updateNickNameAdd(payload)).unwrap();
      if (nickID && res?.status) {
        navigate("/getSms");
      }
      responseToaster(res);
      setUpdateNickName(null);
      onGetUserDetails();
    } catch (err) { }
  };

  useEffect(() => {
    if (role.includes("sms-user-list")) {
      onGetUserDetails();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTableData(
      userDetailsData?.data?.length ? [...userDetailsData?.data] : []
    );
  }, [userDetailsData]);


  if (!role.includes("sms-user-list")) { return }

  return (
    <>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <BackIcon
            onClick={() => {
              navigate("/getSms");
            }}
          />

          <div className="d-flex gap-50 align-items-center ">
            {userDetailsData?.inActiveUser !== 0 && role.includes("send-notification") ? (
              <SendNotificationButton
                className="h-9 submitbutton"
                userName={"All"}
                isBulk
                isNotIcon
                userId={id}
              />
            ) : null}
            <Avatar
              name={userDetailsData?.userName}
              className="border h-9 w-9 border-primary !font-medium text-ev-primary !rounded-lg"
              sx={{ width: 30, height: 30, fontSize: 14 }}
            />
            <div className="capitalize font-semibold text-secondary">
              {userDetailsData?.userName || ""}
            </div>
          </div>
        </CardHeader>

        <Table
          columns={columns}
          isExpandable={false}
          isLoading={isLoading}
          filterColumns={filterColumns}
          isData={!!tableData?.length}
          data={tableData}
          setData={setTableData}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
        >
          {tableData?.map((item, index) => {
            return (
              <Fragment key={index}>
                <TableRow
                  columns={columns}
                  isExpandable={false}
                  item={item}
                  filterColumns={filterColumns}

                ></TableRow>
              </Fragment>
            );
          })}
        </Table>

      </Card>
      <BankAsignModal
        unUsedBankData={unUsedBanks}
        data={currentData}
        isOpen={asignBankModal}
        onClose={() => {
          setAsignBankModal(false);
          setCurrentData({});
        }}
        getUserData={onGetUserDetails}
      />
      <DeleteModal
        isButtonLoading={isUserLoading}
        isOpen={!!isOpenConfirmation}
        title="Delete User"
        content="Are you sure you want to delete this user?"
        onCancelClick={() => {
          setisOpenConfirmation(false);
        }}
        onDoneClick={() => {
          onDeleteNickUser();
        }}
      />
    </>
  );
};

export default UserDetails;
