import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import { BankTransactionUpdate } from "../../../../redux/slices/bankBalSheetSlice";
import { responseToaster } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ControlledInput from "../../Forms/Controller/ControlledInput";

const UpdateBankTransactionModal = ({
  isOpen,
  onCloseUpdateBankTransaction,
  onGet,
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const schema = Yup.object({
    paymentUtr: Yup.string()
      .required("Please enter a UTR"),
    amount: Yup.string().required("Please enter a amount"),
  });

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onClose = () => {
    reset();
    onCloseUpdateBankTransaction();
  };

  const dispatch = useDispatch();
  const UpdateBankTransaction = async () => {
    try {
      const values = getValues();
      setIsButtonLoading(true);
      const res = await dispatch(
        BankTransactionUpdate({
          ...values,
          id: isOpen?.id,
          uniqeHash: isOpen?.uniqeHash,
        })
      ).unwrap();
      responseToaster(res);
      if (res) {
        onClose();
        onGet();
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  useEffect(() => {
    setValue("paymentUtr", isOpen?.utr);
    setValue("amount", isOpen?.amount);
  }, [isOpen]);

  return (
    <Modal
      isOpen={!!isOpen}
      toggle={() => onClose()}
      className="update-manual-bank-modal"
      centered={true}
    >
      <Form onSubmit={handleSubmit(UpdateBankTransaction)}>
        <div className="modal-header">
          <h3 className="modal-title mt-0">Update Manual Bank Transaction</h3>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body w-100">
          <ControlledInput
            name="paymentUtr"
            label="UTR"
            placeholder="Enter Bank UTR"
            control={control}
            errors={errors}
          />
          <ControlledInput
            name="amount"
            type="number"
            label="Amount"
            placeholder="Enter amount"
            control={control}
            errors={errors}
          />
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button className="graybutton" onClick={() => onClose()}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              className="submitbutton"
              isButtonLoading={isButtonLoading}
            >
              Update
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default UpdateBankTransactionModal;
