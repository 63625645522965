import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { Button } from "reactstrap";
import { responseToaster } from "../../helperFunctions";
import { useLocation } from "react-router-dom";
import DropDown from "../../components/Custom/Forms/DropDown/DropDown";
import { getPgIdFromPgName } from "./Pg";
import { useUser } from "../../redux/slices/authSlice";
import { pgUpdateSeamLess } from "../../redux/slices/pgSlice";

const SeamLess = (props) => {
    const { item, onGet, columns, setIsLoading } = props;

    const dispatch = useDispatch();
    const locations = useLocation()?.pathname?.split("/");

    const params = { pgName: locations?.at(-1), pgType: locations?.at(-2) };
    const { user } = useUser();
    const [isInput, setIsInput] = useState(false);
    const [value, setValue] = useState("");
    const onUpdate = async () => {
        try {
            if (value === null) {
                return
            }
            setIsLoading(true);
            const payload = {
                payload: { pk: item?.account_id, value: value, pg_id: getPgIdFromPgName(user?.SiderBar?.PG, params?.pgName) },
                params,
            };
            const resMin = await dispatch(pgUpdateSeamLess(payload)).unwrap();
            responseToaster(resMin);
            onGet();
        } catch (err) {
            setIsLoading(false);
        }
    };
    const prepareOptions = () => {
        const option = [{
            value: true,
            label: "Yes",
        },
        {
            value: false,
            label: "No",
        }];
        return option;
    };

    return (
        <>
            <div
                className={`${item?.isOpen ? "cell-order-white" : "cell-order"}`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="order-singal-div">
                    <span>Is Seamless : </span>
                    {isInput ? (
                        <div className="gap-1">
                            <DropDown
                                options={prepareOptions()}
                                value={value}
                                isDefaultOption={false}
                                className="fixed-dropdown"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onChange={(e) => {
                                    setValue(e.value)
                                }}
                            />
                            <div className="d-flex gap-1 justify-content-end" style={{ marginTop: "10px" }}>

                                <Button color="primary" onClick={onUpdate}>
                                    <i className="fa-solid fa-check"></i>
                                </Button>
                                <Button
                                    color="danger"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setIsInput(false);
                                    }}
                                >
                                    <i className="fa-solid fa-xmark"></i>
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <span
                            className="cursor-pointer"
                            style={{
                                color: columns?.includes("is_seamless") ? "var(--chatlook-darkblue--)" : "",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (columns?.includes("is_seamless")) {
                                    setIsInput(true);
                                    setValue(item?.is_seamless);
                                }
                            }}
                        >
                            <div className="order-singal-div">
                                <div style={{ color: "var(--chatlook-darkblue--)" }}>{item?.is_seamless === true ? "Yes" : item?.is_seamless === false ? "No" : "Not Set"}</div>
                            </div>
                        </span>
                    )}
                </div>
            </div>
        </>
    );
};

export default SeamLess;
